import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {GrCopy} from "react-icons/gr";
import {Organisation_Receiver} from "../../../context/organisation";
import API from "../../../shared/API"
import ReturnHeadingBar from "../../../shared/components/ReturnHeadingBar";
import Loader from "../../../shared/components/Loader";
import Confirm from "../../../shared/components/Confirm";

const AssetPage = ({organisation}) => {
  let {assetId} = useParams();

  const [asset, setAsset]       = useState({});
  const [feed, setFeed]         = useState({
    alias: "",
  });
  const [feeds, setFeeds]       = useState([]);
  const [rmtp, setRMTP]         = useState({
    asset: "", rtmp: "", streamkey: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [feedBeingDeleted, setFeedBeingDeleted] = useState({});

  useEffect(() => {
    getData();
  }, [assetId]);
  useEffect(() => {
    getFeeds();
    return () => {
      setFeeds([]);
    };
  }, [asset]);
  useEffect(() => {
    if (feed.name !== ""){
      setDisabled(false);
    }
  }, [feed]);

  const getData    = () => {
    API.org.feeds.getAsset(organisation.data._id, assetId).then(res => {
      setAsset(res);
    }).catch(() => {

    });
  }
  const getFeeds   = () => {
    API.org.feeds.getAssetFeeds(organisation.data._id, asset._id).then((res) => {
      setFeeds(res);
    });
  };
  const getRMTP    = (feed) => {
    API.org.feeds.getAssetFeedRtmp(organisation.data._id, asset._id, feed).then((res) => {
      setRMTP({asset: feed, ...res});
    });
  };
  const handleDeleteFeed = () => {
    API.org.feeds.deleteAssetFeed(organisation.data._id, asset._id, feedBeingDeleted._id).then(() => {
      getData();
      setFeedBeingDeleted({});
    })
  }
  const AddNewFeed = () => {
    if (feed.alias.trim() === ""){
      setDisabled(true);
    } else if (!disabled){
      API.org.feeds.createAssetFeed(organisation.data._id, asset._id, feed.alias).then(() => {
        getData();
      });
    }
  };

  if (asset._id === undefined){
    return (
      <Loader>Loading Video Asset...</Loader>
    )
  }

  return (
    <div>
      <ReturnHeadingBar title={asset.name} linkTo={`${organisation._urlPrefix}/video_feeds`}/>
      {feedBeingDeleted._id !== undefined && <Confirm open isMiddle title={`Delete feed ${feedBeingDeleted.alias}?`} message={"Please confirm that you like to delete the selected feed for this asset"}
        onCancel={() => {
          setFeedBeingDeleted({});
        }} onConfirm={handleDeleteFeed}/>}
      <div className="w-full p-4">
        <div className="flex mb-4 justify-between">
          <input
            type={"text"}
            name={"alias"}
            value={feed.alias}
            onChange={(e) => setFeed({...feed, alias: e.target.value})}
            placeholder={"New feed name"}
            autoComplete="off"
            className={`px-2 text-md bg-black placeholder-gray-600  w-full shadow-sm border ${disabled ? "border-red-400" : "border-gray-600"} rounded-l-md`}
          />
          <button
            className={`px-4 py-2  text-white w-auto flex-nowrap rounded-r-lg ${disabled ? "bg-red-400" : "bg-green-600"}`}
            onClick={() => {
              AddNewFeed();
            }}
          >Add
          </button>
        </div>
        <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
          <thead>
          <tr className="bg-gray-900 flex flex-col flex-nowrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
            <th className=" px-4 py-3 text-left text-xs font-medium text-gray-200 uppercase">Feed</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-200 uppercase">RTMP Links</th>
            <th className="text-center px-4 py-3 text-xs font-medium text-gray-200 uppercase">Status</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-200 uppercase ">{""}</th>
          </tr>
          </thead>
          <tbody>{feeds.map((f) => {
            return (<tr key={f._id} className="w-full">
              <td className={`border-b border-black p-2`}>{f.alias}</td>
              <td className={`border-b border-black text-sm overflow-hidden truncate`}>
                {/*<div className="flex gap-2 items-center">*/}
                {/*  <span className="text-yellow-700">HLS View: </span>{f.streamFeeds.hls.substring(0, 40) + "..."}*/}
                {/*  <button className="flex m-2 bg-gray-700 p-2 rounded-xl " onClick={() => {*/}
                {/*    navigator.clipboard.writeText(f.streamFeeds.hls);*/}
                {/*  }}><GrCopy className="mr-2 "/> Copy*/}
                {/*  </button>*/}
                {/*</div>*/}
                <div className="flex gap-2 items-center border-t border-gray-900">
                  {rmtp.asset === f._id ? (
                    <div>
                      <div>
                        <span><span className="text-yellow-700">[Primary] RMTP (Ultra Low Latency): </span>{rmtp.rtmp}</span>
                        <br/>
                        <span><span className="text-yellow-700">[Secondary] RMTP (Buffered):</span>{" "}{rmtp.rtmp_relay}</span>
                        <br/>
                        <span><span className="text-yellow-700 truncate">Key:</span> [HIDDEN]</span>
                      </div>
                      <div className="flex gap-2">
                        <button className="flex m-2 bg-gray-700 p-2 rounded-xl " onClick={() => {
                          navigator.clipboard.writeText(rmtp.rtmp);
                          alert("Copied to clipboard!")
                        }}>
                          <GrCopy className="mr-2 "/> Copy RMTP (Primary)
                        </button>
                        <button className="flex m-2 bg-gray-700 p-2 rounded-xl " onClick={() => {
                          navigator.clipboard.writeText(rmtp.rtmp_relay);
                          alert("Copied to clipboard!")
                        }}>
                          <GrCopy className="mr-2 "/> Copy RMTP (Secondary)
                        </button>
                        <button className="flex m-2 bg-gray-700 p-2 rounded-xl " onClick={() => {
                          navigator.clipboard.writeText(rmtp.streamKey);
                          alert("Copied to clipboard!")
                        }}>
                          <GrCopy className="mr-2 "/> Copy Key
                        </button>
                        <button className="flex m-2 bg-gray-700 p-2 rounded-xl " onClick={() => {
                          navigator.clipboard.writeText(rmtp.rtmp + "/" + rmtp.streamKey);
                          // alert("Copied to clipboard!")
                        }}>
                          <GrCopy className="mr-2 "/> (Primary + Key) Combined
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button className="flex m-2 bg-gray-700 p-2 rounded-xl text-sm " onClick={() => getRMTP(f._id)}>Get RMTP</button>
                  )}
                </div>
              </td>
              <td className={`border-b border-black p-2 ${f.isLive ? "bg-green-900" : "bg-blue-900"}`}>{f.isLive ? "Live" : "Offline"}</td>
              <td className={`border-b border-black`}>
                <button className="flex m-2 bg-red-900 p-2 rounded-xl text-sm" onClick={() => setFeedBeingDeleted(f)}>Delete Feed</button>
              </td>
            </tr>);
          })}
          </tbody>
        </table>
      </div>
    </div>

  );
}
export default Organisation_Receiver(AssetPage, (organisation) => {
  return {organisation};
});