import React from "react";
import Loader from "./Loader";
export default function StatBox({ label, value, loading }) {
  return (
    <dl className="col-span-1">
      <div className="px-4 py-5 bg-gray-900 border border-gray-700 shadow rounded-lg overflow-hidden ">
        <dt className="text-sm font-medium text-amber-500 ">{label}</dt>
        <dd className="mt-1 text-2xl font-semibold text-white">
          {loading ? <Loader /> : value}
        </dd>
      </div>
    </dl>
  );
}
