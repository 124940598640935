import React, {useEffect, useState} from "react";
import Input from "../../../../shared/components/input";

export default function AssetForm({ editAsset = {}, onCancel, onDelete, onSubmit }) {
  const [asset, setAsset] = useState({
    name: "",
  });

  useEffect(() => {
    if(editAsset._id !== undefined){
      setAsset(editAsset)
    } else {
      setAsset({
        name: "",
      })
    }
  }, [editAsset]);

  return (
    <div className="feed-form text-xl p-2 flex flex-col gap-2">
      <Input
        label={"Asset Name"}
        type={"text"}
        field={"name"}
        value={asset.name}
        onChange={(e) => setAsset({ ...asset, name: e.target.value })}
        placeholder="DJI Drone 1"
      />
      <div className="p-3 flex gap-4">
        {asset._id !== undefined && <>
          <button onClick={() => {
            onCancel();
            setAsset({name: ""});
          }} className="py-2 px-4 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600">
            Cancel
          </button>
          <button onClick={() => {
            onDelete();
          }} className="py-2 px-4 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600">
            Delete
          </button>
        </>}

        <button onClick={() => {
          onSubmit(asset);
          setAsset({name: ""});
        }} className="py-2 px-4 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600">
          {asset._id !== undefined ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
}
