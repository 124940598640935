import React, {useState, useEffect} from "react";

const Dashboard = ({history}) => {

  return (
    <div className="p-2">
      SMS Dashboard
    </div>
  );
}
export default Dashboard;