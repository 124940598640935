import React, {useState, useEffect} from "react";
import {SearchIcon, PencilIcon} from "@heroicons/react/outline";
import dayjs from "dayjs";
import Toast from "react-hot-toast";
import {Organisation_Receiver} from "../../../context/organisation";
import AlertMessage from "../../../shared/components/AlertMessage";
import API from "../../../shared/API";
import Loader from "../../../shared/components/Loader";
import AssetForm from "./_components/assetForm";

const VideoFeeds = ({organisation, history}) => {
    const [loading, setLoading] = useState(true);
    const [assets, setAssets] = useState([]);
    const [error, setError] = useState(null);
    const [assetToEdit, setAssetToEdit] = useState({});

    useEffect(() => {
        getAssets();
    }, [organisation]);

    const goToAsset  = asset => {
        history.push(`${organisation._urlPrefix}/video_feeds/${asset._id}`);
    }
    const getAssets  = () => {
        API.org.feeds.getAssets(organisation.data._id).then((res) => {
            setAssets(res);
            setLoading(false);
        }).catch(err => {
          setError(err.response.data.message);
        });
    };
    const submitForm = (data) => {
        if(assetToEdit._id !== undefined){
            API.org.feeds.updateAsset(organisation.data._id, assetToEdit._id, data).then(() => {
                Toast.success("Successfully updated streaming asset");
                getAssets();
                setAssetToEdit({})
            });
        } else {
            if (data.name){
                API.org.feeds.createAsset(organisation.data._id, data.name).then(() => {
                    Toast.success("Successfully created streaming asset");
                    getAssets();
                });
            }
        }
    };
    const handleDeleteAsset = () => {
        if(window.confirm("Are you sure you want to delete this asset?")){
            API.org.feeds.deleteAsset(organisation.data._id, assetToEdit._id).then(() => {
                Toast.success("Successfully deleted streaming asset");
                getAssets();
                setAssetToEdit({})
            });
        }
    }

    if(error !== null){
      return (
        <AlertMessage.Error>{error}</AlertMessage.Error>
      );
    }
    if (loading){
        return (
            <Loader>Loading Video Assets...</Loader>
        )
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-2  p-2">
            <div className="flex-col flex col-span-1 md:col-span-1 border rounded-lg border-gray-700 ">
                <div className="text-center text-xl p-2 border-b border-gray-900">{assetToEdit._id !== undefined ? "Modify" : "Add"} Asset</div>
                <AssetForm editAsset={assetToEdit} onCancel={() => setAssetToEdit({})} onDelete={handleDeleteAsset} onSubmit={submitForm}/>
            </div>
            <div className="flex-col flex col-span-1 md:col-span-3 border rounded-lg border-gray-700 ">
                <table className="w-full border-separate overflow-hidden">
                    <thead>
                    <tr className="flex flex-col flex-nowrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                        <th className="text-sm font-medium text-gray-400 uppercase text-left sm:text-center px-2 py-2 bg-black">Asset Name</th>
                        <th className="text-sm font-medium text-gray-400 uppercase text-left sm:text-center px-2 py-2 bg-black">Status</th>
                        <th className="text-sm font-medium text-gray-400 uppercase text-left sm:text-center px-2 py-2 bg-black">Last Online At</th>
                        <th className="text-sm font-medium text-gray-400 uppercase text-center sm:text-center px-2 py-2 bg-black">{""}</th>
                    </tr>
                    </thead>
                    <tbody>{assets.length === 0 && (
                        <tr>
                            <td colSpan={3} className={"p-4"}>You currently don't have any assets, add one to begin using Video Feeds.</td>
                        </tr>
                    )}{assets.map((a) => {
                        return (
                            <tr key={a._id} className={"hover:bg-gray-900"}>
                                <td className="text-left px-2 py-4 border-b border-gray-700">{a.name}</td>
                                <td className={`text-left px-2 py-4 border-b border-gray-700 ${a.isLive ? "bg-green-900" : "bg-blue-900"}`}>{a.isLive ? "Live" : "Offline"}</td>
                                <td className={`text-left px-2 py-4 border-b border-gray-700`}>{a.lastSteamedAt !== undefined ? dayjs(a.lastSteamedAt).format("DD-MM-YYYY hh:mma") : "[N/A]"}</td>
                                <td className="text-center px-2 py-4 border-b border-gray-700">
                                    <button className="p-1 float-right" onClick={() => goToAsset(a)}>
                                        <SearchIcon className="h-6 text-gray-600 hover:text-blue-700"/>
                                    </button>
                                    <button className="p-1 float-right" onClick={() => setAssetToEdit(a)}>
                                        <PencilIcon className="h-6 text-gray-600 hover:text-blue-700"/>
                                    </button>
                                </td>
                            </tr>
                        );
                    })}</tbody>
                </table>
            </div>
        </div>
    );
}
export default Organisation_Receiver(VideoFeeds, (a) => {
    return {organisation: a};
});