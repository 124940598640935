import React from "react";
import { ArrowCircleDownIcon } from "@heroicons/react/outline";

const FlowDownArrow = () => {
  return (
    <div className={" p-2 "}>
      <ArrowCircleDownIcon className={"h-10 mx-auto text-blue-300 opacity-40"} />
    </div>
  );
};
export default FlowDownArrow;
