import React, { useEffect, useState } from "react";
import {Organisation_Receiver} from "../../../../context/organisation";
import Modal from "../../../../shared/components/Modal";
import API from "../../../../shared/API"
import Select from "../../../../shared/components/Select";
import Toast from "react-hot-toast";
import {XIcon} from "@heroicons/react/outline";

const PhoneNumberAssignment = ({isV2 = false, organisation, phoneNumbers, onClose, onDataReload}) => {
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");

  useEffect(() => {
    API.sms.getAvailablePhoneNumbers(isV2).then(res => {
      setAvailableNumbers(res);
    })
  }, [isV2, phoneNumbers]);

  const handleRemovePhoneNumber = (a) => {
    API.management.sms.organisations.phoneNumbers.unassign(organisation.data._id, a._id).then(() => {
      Toast.success("Phone number successfully unassigned");
      try {
        onDataReload();
      } catch(e){
        Toast.error("Failed to reload data, you may need to refresh the page manually to see recent changes");
      }
    }).catch(err => {
      Toast.error(err.response.data.message);
    })
  }
  const handleAddPhoneNumber = () => {
    API.management.sms.organisations.phoneNumbers.assign(organisation.data._id, selectedNumber).then(() => {
      Toast.success("Assigned phone number successfully");
      try {
        onDataReload();
      } catch(e){
        Toast.error("Failed to reload data, you may need to refresh the page manually to see recent changes");
      }
      setSelectedNumber("");
    }).catch(err => {
      Toast.error(err.response.data.message);
    })
  };

  return (
    <Modal title={"Phone Number Assignments"} subTitle={">> " + organisation.data.name + " <<"} onClose={onClose}>
      <div className="py-2 flex gap-4 text-white">
        <Select
          options={availableNumbers.filter(item => phoneNumbers.filter(a => a._id === item._id).length === 0).map(item => {
            return {
              text: `${item.gateway.title} | ${item.title} (${item.phoneNumber}) [${item.isShared ? 'Shared' : 'Dedicated'}]`,
              value: item._id
            }
          })}
          name={"phoneSelection"}
          onChange={e => setSelectedNumber(e.target.value)}
          value={selectedNumber}
        />
        <button className={"p-2 bg-green-600 rounded border border-green-200 disabled:bg-gray-700 disabled:border-green-700"} onClick={handleAddPhoneNumber} disabled={selectedNumber === ""}>Assign</button>
      </div>
      <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border border-gray-700">
        <thead>
        <tr className="rounded-md bg-gray-900">
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Title</th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Phone Number</th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Type</th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"></th>
        </tr>
        </thead>
        <tbody>{phoneNumbers.map(item => {
          return (<tr key={item._id} className={"items-center divide-x-2 divide-gray-700 hover:bg-gray-900"}>
            <td className={"px-2 py-2 text-md text-gray-200"}>{item.gateway.title} | {item.title}</td>
            <td className={"px-2 py-2 text-md text-gray-200"}>{item.phoneNumber} {item._isSender ? <span className={"ml-2 px-3 py-1 rounded bg-green-800 text-white text-sm"}>Sending As</span> : ""}</td>
            <td className={"px-2 py-2 text-md text-gray-200"}>{item.isShared ? "Shared" : "Dedicated"}</td>
            <td className={"px-2 py-2 text-md text-gray-200"}>
              <XIcon
                className="m-auto h-6 text-red-500 cursor-pointer hover:text-white"
                onClick={() => handleRemovePhoneNumber(item)}
              />
            </td>
          </tr>);
        })}</tbody>
      </table>
      <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
        <button className="px-4 py-2 bg-blue-400 rounded-lg" onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
}
export default Organisation_Receiver(PhoneNumberAssignment, (organisation) => {
  return {organisation};
});