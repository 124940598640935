import React, {useState, useEffect} from "react";
import {Transition, Dialog} from "@headlessui/react";
import API from "../../../../shared/API";
import {Organisation_Receiver} from "../../../../context/organisation";
import Loader from "../../../../shared/components/Loader";
import Select from "../../../../shared/components/Select";

const SendPushNotificationForm = ({recipients, onRecipientRemove, organisation}) => {
  const [isSending, setIsSending]             = useState(false);
  const [isSent, setIsSent]                   = useState(false);
  const [isSentDisplay, setIsSentDisplay]     = useState(false);
  const [errors, setErrors]                   = useState({});
  const [title, setTitle]                     = useState("");
  const [message, setMessage]                 = useState("");
  const [sound, setSound]                     = useState("");
  const [submissionError, setSubmissionError] = useState(null);

  useEffect(() => {
    if (!isSent) return;

    setIsSentDisplay(true);
    const a = setTimeout(() => {
      setIsSentDisplay(false)
    }, 5000);
    return () => {
      clearTimeout(a);
    }
  }, [isSent])

  const handleSendingNotification = () => {
    let validationErrors = {};
    if (recipients.length === 0){
      validationErrors.recipients = "You must selected at least one recipient.";
    }
    if (title.trim().length === 0){
      validationErrors.title = "You must enter a title.";
    }
    if (message.trim().length === 0){
      validationErrors.message = "You must enter a message.";
    }

    setErrors(validationErrors);
    setSubmissionError(null);
    if (Object.keys(validationErrors).length === 0){
      setIsSending(true);
      API.org.push_notifications.sendNotification(organisation.data._id, {
        recipients,
        title,
        message,
        sound
      }).then(() => {
        setIsSent(true);
        setTitle("");
        setMessage("");
        setSound("");
        onRecipientRemove(recipients);
      }).catch(err => {
        if (err.response.data.message !== undefined){
          if (err.response.data.message === "recipient_errors"){
            let msg = "Some errors were found with the selected recipients, error details are:- \n\n";
            for (let index in err.response.data.recipientErrors){
              const singleError = err.response.data.recipientErrors[index];
              msg +=
                "> [" +
                singleError.recipient.name +
                "] " +
                singleError.error +
                "\n";
            }
            setSubmissionError(msg);
            return;
          }
          setSubmissionError(err.response.data.message);
        } else {
          setSubmissionError("There was an unexpected error while processing your request, it is likely that your SMS message has not been sent. Please try again in a few moments or contact SarStuff support for assistance.");
        }
      }).finally(() => {
        setIsSending(false);
      })
    }
  }

  const renderSendingDialog = () => {
    return (
      <Transition
        show={isSending}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            // do nothing
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="text-center inline-block w-full max-w-md p-6 my-8 text-white overflow-hidden align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <h1>Please Wait...</h1>
              <Loader>
                Attempting to send your push notification to Google and Apple servers for the selected recipients.
              </Loader>
              <button>{""}</button>
              {/* put in a blank button to get around the must contain focusable item limit */}
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className={"p-2"}>
      {renderSendingDialog()}
      {submissionError && (
        <div className={"mb-2 p-2 bg-red-800 border border-red-500"}>
          {submissionError.split("\n").map((text, index) => {
            return (
              <div key={index}>
                {index !== 0 && <br/>}
                {text}
              </div>
            );
          })}
        </div>
      )}
      {isSentDisplay && (
        <div className={"mb-2 p-2 bg-green-800 border border-green-500"}>
          Notification has been sent for delivery by Google and Apple servers.
        </div>
      )}
      <div className="bg-gray-800 p-2">
        <h4 className={"clear-both text-blue-400"}>Recipients ({recipients.length})</h4>
        <div className="flex p-2 gap-2 flex-wrap">
          {recipients.map((r, index) => {
            return (
              <div key={index} className="px-2 py-1 text-sm text-black bg-gray-400 rounded-lg">
                {r.name} <span onClick={() => onRecipientRemove(r)} className="pl-2 cursor-pointer hover:text-red-600">X</span>
              </div>
            );
          })}
          {errors.recipients && (
            <p className="text-red-500 p-1">{errors.recipients}</p>
          )}
        </div>
      </div>

      <div className="bg-gray-800 p-2 h-full">
        <h4 className="text-blue-400">Title</h4>
        <input
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={`bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm sm:text-sm  ${
            errors.title ? "border-red-400" : "border-gray-600"
          } rounded-md`}
        />
        {errors.title && <p className="text-red-500 p-1">{errors.title}</p>}
      </div>
      <div className="bg-gray-800 p-2 h-full">
        <h4 className="text-blue-400">Message</h4>
        <textarea
          name="message"
          value={message}
          rows={3}
          onChange={(e) => setMessage(e.target.value)}
          className={`bg-gray-900 mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm sm:text-sm  ${
            errors.message ? "border-red-400" : "border-gray-600"
          } rounded-md`}
        />
        {errors.message && <p className="text-red-500 p-1">{errors.message}</p>}
      </div>
      <div className="bg-gray-800 p-2 h-full">
        <Select
          label="Sound"
          field={"sound"}
          options={[
            {key: "sound_01", text: "sound_01", value: "sound_01.wav"},
            {key: "default", text: "default", value: "default"},
            {key: "silent", text: "[Silent]", value: ""}
          ]}
          value={sound}
          onChange={(e) => setSound(e.target.value)}
        />
      </div>

      <div className="flex bg-gray-800 py-4 px-2 h-full align-middle justify-end border-t border-gray-600">
        <button onClick={() => handleSendingNotification()} className={`m-2 py-1 px-3 bg-green-500 rounded-lg text-center`}>Send</button>
      </div>
    </div>
  );
}
export default Organisation_Receiver(SendPushNotificationForm, organisation => {
  return {organisation};
});