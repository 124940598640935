import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import toast from "react-hot-toast";
import { Organisation_Receiver } from "../../../context/organisation";
import CheckboxSlider from "../../../shared/components/CheckboxSlider";
import API from "../../../shared/API";
import Loader from "../../../shared/components/Loader";
import AlertMessage from "../../../shared/components/AlertMessage";

const returnTabButtonStyle = (selected) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return classNames(
    "w-full py-2.5 text-lg leading-5 font-medium  rounded-lg",
    selected
      ? "text-gray-100 bg-green-900 bg-opacity-50"
      : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
  );
};

const ZelloSettings = ({ organisation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError]             = useState(null);
  const [setPassword, setSetPassword] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [zello, setZello] = useState({
    enabled: false,
    network_endpoint: "",
    api_key: "",
    username: "",
    password: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    API.org.sarmap.settings.zello.getSettings(organisation.data._id).then((res) => {
      setIsLoading(false);
      setZello(res);
    }).catch((err) => {
      if (err.isAxiosError){
        setLoadingError(err.response.data.message);
        return;
      }
      setLoadingError("There was an unexpected error while retrieving data from server.");
    });
  };
  const handleSubmitZello = () => {
    let data = zello;
    if (!setPassword) {
      data.password = undefined;
    }
    API.org.sarmap.settings.zello.setSettings(organisation.data._id, data).then(() => {
      if(data.loginResults !== undefined){
        if(data.loginResults.loginTried){
          if(data.loginResults.errorMessage !== ""){
            toast.error("Zello login failed: " + data.loginResults.errorMessage);
            return;
          }
          if(data.loginResults.success){
            toast.success("Zello settings updated, Login was successful");
            setNeedsUpdate(false);
            setSetPassword(false);
            getData();
            return;
          }
        }
      }

      toast.success("Zello settings updated");
      setNeedsUpdate(false);
      setSetPassword(false);
      getData();
    }).catch((err) => {
      toast.error(`Error: Zello settings not updated. ${err}`);
    });
  };

  if (loadingError){
    return (
      <AlertMessage.Error>{loadingError}</AlertMessage.Error>
    );
  }
  if(isLoading){
    return (
      <Loader>Querying SarMAP server...</Loader>
    );
  }

  return (
    <div className="border-t border-gray-800 ">
      <CheckboxSlider
        title="Enable Zello Integration"
        isChecked={zello.enabled}
        onChange={() => {
          setZello({
            ...zello,
            enabled: !zello.enabled,
          });
          setNeedsUpdate(true);
        }}
      />
      {zello.enabled && (
        <div className=" w-full ">
          <div className="p-2">
            <label>
              Zello Network Name ( [your network name].zellowork.com )
            </label>
            <input
              name="network_endpoint"
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              value={zello.network_endpoint}
              onChange={(e) => {
                setZello({
                  ...zello,
                  network_endpoint: e.target.value,
                });
                setNeedsUpdate(true);
              }}
            />
          </div>
          <div className="p-2">
            <label>API Key</label>
            <input
              name="api_key"
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              value={zello.api_key}
              onChange={(e) => {
                setZello({
                  ...zello,
                  api_key: e.target.value,
                });
                setNeedsUpdate(true);
              }}
            />
          </div>
          <div className="p-2">
            <div className="italic text-sm p-2 text-orange">
              Notice: A "Administrator" user account with "Web Console Access: Full" needs to be created/provided below in addition to the API key above.
              We recommend this being a dedicated account as password changes will break the integration.
            </div>
            <label>Username</label>
            <input
              name="username"
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              value={zello.username}
              onChange={(e) => {
                setZello({
                  ...zello,
                  username: e.target.value,
                });
                setNeedsUpdate(true);
              }}
            />
          </div>
          <div className="p-2">
            <label>Password</label>
            <CheckboxSlider
              title="Set/update password"
              isChecked={setPassword}
              onChange={() => setSetPassword(!setPassword)}
            />
            {setPassword && (
              <input
                name="password"
                className={
                  "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
                }
                value={zello.password}
                onChange={(e) => {
                  setZello({
                    ...zello,
                    password: e.target.value,
                  });
                  setNeedsUpdate(true);
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="flex justify-between p-2">
        {needsUpdate ? (
          <button
            onClick={() => {
              setNeedsUpdate(false);
              getData();
            }}
            className={`p-2 rounded  bg-red-700`}
          >
            Cancel
          </button>
        ) : (
          <div></div>
        )}
        <button
          disabled={!needsUpdate}
          onClick={handleSubmitZello}
          className={`p-2 rounded  ${
            needsUpdate
              ? "bg-green-700"
              : "bg-gray-700 cursor-not-allowed"
          }`}
        >
          Update
        </button>
      </div>
    </div>
  );
};
const QuartixSettings = ({ organisation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError]             = useState(null);
  const [quartix, setQuartix] = useState({
    enabled: false,
    customerId: "",
    application: "",
    username: "",
    password: "",
  });
  const [setPassword, setSetPassword] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    API.org.sarmap.settings.quartix.getSettings(organisation.data._id).then((res) => {
      setIsLoading(false);
      setQuartix(res);
    }).catch((err) => {
      if (err.isAxiosError){
        setLoadingError(err.response.data.message);
        return;
      }
      setLoadingError("There was an unexpected error while retrieving data from server.");
    });
  };
  const handleSubmitQuartix = () => {
    let data = quartix;
    if (!setPassword) {
      data.password = undefined;
    }
    API.org.sarmap.settings.quartix.setSettings(organisation.data._id, data).then(() => {
      toast.success("Quartix settings updated");
      setNeedsUpdate(false);
      setSetPassword(false);
      getData();
    }).catch((err) => {
      toast.error(`Error: Quartix settings not updated. ${err}`);
    });
  };

  if (loadingError){
    return (
      <AlertMessage.Error>{loadingError}</AlertMessage.Error>
    );
  }
  if(isLoading){
    return (
      <Loader>Querying SarMAP server...</Loader>
    );
  }

  return (
    <div className="border-t border-gray-800 ">
      <CheckboxSlider
        title="Enable Quartix Integration"
        isChecked={quartix.enabled}
        onChange={() => {
          setQuartix({
            ...quartix,
            enabled: !quartix.enabled,
          });
          setNeedsUpdate(true);
        }}
      />
      {quartix.enabled && (
        <div className=" w-full ">
          <div className="p-2">
            <label>Customer/Subscriber ID</label>
            <input
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              name="customerId"
              value={quartix.customerId}
              onChange={(e) => {
                setQuartix({
                  ...quartix,
                  customerId: e.target.value,
                });
                setNeedsUpdate(true);
              }}
            />
          </div>
          <div className="p-2">
            <label>Application</label>
            <input
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              name="application"
              value={quartix.application}
              onChange={(e) => {
                setQuartix({
                  ...quartix,
                  application: e.target.value,
                });
                setNeedsUpdate(true);
              }}
            />
          </div>
          <div className="p-2">
            <label>Username</label>
            <input
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              name="username"
              value={quartix.username}
              onChange={(e) => {
                setQuartix({
                  ...quartix,
                  username: e.target.value,
                });
                setNeedsUpdate(true);
              }}
            />
          </div>
          <div className="p-2">
            <CheckboxSlider
              title="Set/update password"
              isChecked={setPassword}
              onChange={() => setSetPassword(!setPassword)}
            />
            {setPassword && (
              <input
                name="password"
                className={
                  "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
                }
                value={quartix.password}
                onChange={(e) => {
                  setQuartix({
                    ...quartix,
                    password: e.target.value,
                  });
                  setNeedsUpdate(true);
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="flex justify-between p-2">
        {needsUpdate ? (
          <button
            onClick={() => {
              setNeedsUpdate(false);
              getData();
            }}
            className={`p-2 rounded  bg-red-700`}
          >
            Cancel
          </button>
        ) : (
          <div></div>
        )}
        <button
          disabled={!needsUpdate}
          onClick={handleSubmitQuartix}
          className={`p-2 rounded  ${
            needsUpdate
              ? "bg-green-700"
              : "bg-gray-700 cursor-not-allowed"
          }`}
        >
          Update
        </button>
      </div>
    </div>
  );
};

const Settings = ({ organisation }) => {
  return (
    <div className="w-full p-2">
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
          <Tab className={({ selected }) => returnTabButtonStyle(selected)}>Zello</Tab>
          <Tab className={({ selected }) => returnTabButtonStyle(selected)}>Quartix</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <ZelloSettings organisation={organisation} />
          </Tab.Panel>
          <Tab.Panel>
            <QuartixSettings organisation={organisation} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Organisation_Receiver(Settings, (organisation) => {
  return { organisation };
});