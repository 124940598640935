import React, {useState, useEffect} from "react";
import API from "../../API";
import Select from "../Select";

const OrganisationType = ({onChange, labelClassName = "", title = "Organisation Type", value = "", isDisabled = false, validationIssue = ""}) => {
  const [orgTypes, setOrgTypes] = useState([]);

  useEffect(() => {
    API.settings.all().then((res) => {
      setOrgTypes(res.organisationTypes.map((o, i) => {
        return {key: i, text: o, value: o};
      }).toSorted((a, b) => {
        if(a.text > b.text) return 1;
        if(a.text < b.text) return -1;
        return 0;
      }));
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <div className="flex flex-col gap-2 w-full">
      <label htmlFor="name" className={labelClassName}>{title}</label>
      <Select
        placeholder={"Pick One"}
        name={"type"}
        options={orgTypes}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={isDisabled}
      />
      {validationIssue && <p style={{color: "red"}}>{validationIssue}</p>}
    </div>
  );
};
export default OrganisationType;