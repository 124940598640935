

const Info = ({className = "", children}) => {
  return (
    <div className={`m-2 p-2 bg-gray-900 border-blue-800  border text-white rounded ${className}`}>{children}</div>
  );
};
const Error = ({className = "", children}) => {
  return (
    <div className={`m-2 p-2 bg-red-800 border border-white text-white rounded ${className}`}>{children}</div>
  );
};

const AlertMessage = {
  Info,
  Error
}
export default AlertMessage;