import React, { useState, useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { XIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import API from "../../../../shared/API";
import CentralHeader from "../../../../shared/components/CentralHeader";
import Select from "../../../../shared/components/Select";
import OrganisationType from "../../../../shared/components/formFields/organisationType";

const initialFormValues = {
  name: "",
  phone: "",
  email: "",
  type: "",
  country: "GB",
  counties: [],
  gridType: "",
  orgType: "",
};

const CreateOrg = ({ history }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({ ...initialFormValues });
  const [errors, setErrors] = useState({});
  const [gridTypes, setGridTypes] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    validate(data);
  }, [data]);

  const getData = () => {
    API.settings
      .all()
      .then((res) => {
        setGridTypes(
          res.gridTypes.map((g, i) => {
            return { key: i, text: g, value: g };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const removeCounty = (county) => {
    if (county) {
      let countiesList = data.counties.filter((item) => {
        return item !== county;
      });

      setData({
        ...data,
        counties: countiesList,
      });
    }
  };
  const selectRegion = (val) => {
    // setCounty(val);
    let countiesList = data.counties;
    if (val) {
      countiesList.push(val);
      setData({
        ...data,
        counties: countiesList,
      });
    }
    setData({
      ...data,
      counties: countiesList,
    });
  };

  const validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't be empty";
    if (!data.type) errors.type = "Can't be empty";
    if (!data.gridType) errors.gridType = "Can't be empty";
    if (!data.country) errors.country = "Can't be empty";
    if (data.counties.length === 0) errors.county = "Can't be empty";

    setErrors(errors);
    return errors;
  };
  const submitOrg = () => {
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      API.org
        .addFromWizard(data)
        .then(() => {
          toast.success("Successfully created the new organisation.", {
            duration: 5000,
          });
          history.push("/organisations");
        })
        .catch((err) => {
          let errorMessage =
            "There was an unexpected error while adding new organisation";
          toast.error(errorMessage, {
            duration: 5000,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  return (
    <div>
      <CentralHeader />
      <div className="p-2 w-full flex flex-col sm:flex-row gap-4">
        <div className="w-full sm:w-2/3">
          <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
            Create Organisation
          </div>

          <div className="px-4 pb-2 pt-2 w-full border border-gray-700  rounded-b-xl flex flex-col">
            {isSubmitting && (
              <div className={"p-2 bg-gray-700"}>
                Creating new organisation, please wait...
              </div>
            )}
            <div className="p-2 flex gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="name">Organisation Name</label>
                <input
                  name="name"
                  id="name"
                  placeholder="org name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
              </div>
              <OrganisationType
                onChange={(val) => setData({ ...data, type: val })}
                value={data.type}
                disabled={isSubmitting}
                validationIssue={errors.type}
              />
            </div>
            <div className="p-2 flex gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="name">Phone</label>
                <input
                  name="phone"
                  id="phone"
                  placeholder="org phone"
                  value={data.phone}
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                  className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="name">Email</label>
                <input
                  name="email"
                  id="email"
                  placeholder="org email"
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              </div>
            </div>
            <div className="p-2 flex gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="county">Country</label>
                <CountryDropdown
                  value={data.country}
                  // whitelist={[
                  //   "GB",
                  //   "ES",
                  //   "IE",
                  //   "FR",
                  //   "IM",
                  //   "MA",
                  //   "MT",
                  //   "PT",
                  //   "TR",
                  //   "US",
                  // ]}
                  onChange={(val) => setData({ ...data, country: val })}
                  classes="p-2 bg-gray-900 rounded-xl text-white w-full border border-gray-700"
                  valueType="short"
                  disabled={isSubmitting}
                />
                {errors.country && (
                  <p style={{ color: "red" }}>{errors.country}</p>
                )}
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="county">Counties</label>
                <RegionDropdown
                  country={data.country}
                  value={data.county}
                  onChange={(val) => selectRegion(val)}
                  classes="p-2 bg-gray-900 rounded-xl text-white w-full border border-gray-700"
                  countryValueType="short"
                  disabled={isSubmitting}
                />
                {errors.county && (
                  <p style={{ color: "red" }}>{errors.county}</p>
                )}
                <div className="p-2 flex flex-wrap gap-2">
                  {data.counties &&
                    data.counties.map((c, i) => {
                      return (
                        <div
                          className={
                            "p-2 text-sm bg-gray-700 text-white flex justify-between rounded-xl  mt-2"
                          }
                          key={i}
                        >
                          {c}{" "}
                          <sup>
                            <XIcon
                              className="h-4 ml-2 text-red-500 cursor-pointer hover:text-white"
                              onClick={() => removeCounty(c)}
                            />
                          </sup>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="name">Grid Type</label>
                <Select
                  placeholder={"Pick One"}
                  name={"gridType"}
                  options={gridTypes ? gridTypes : []}
                  value={data.gridType}
                  onChange={(e) =>
                    setData({ ...data, gridType: e.target.value })
                  }
                  disabled={isSubmitting}
                />
                {errors.gridType && (
                  <p style={{ color: "red" }}>{errors.gridType}</p>
                )}
              </div>
            </div>
            <div className="p-2 flex gap-2 w-full">
              {/*<div className="mt-1 sm:mt-0 sm:col-span-2 w-full ">*/}
              {/*<div className="flex flex-col gap-2 w-full">*/}
              {/*  <label htmlFor="name">Sarstuff Test User Account</label>*/}
              {/*  <div className=" flex  shadow-sm ">*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      name="sarstuffUser"*/}
              {/*      autoComplete="off"*/}
              {/*      id="sarstuffUser"*/}
              {/*      value={data.sarstuffUser}*/}
              {/*      onChange={(e) =>*/}
              {/*        setData({ ...data, sarstuffUser: e.target.value })*/}
              {/*      }*/}
              {/*      className="flex-1 p-2 bg-gray-900 block w-full  min-w-0 rounded-none rounded-l-md sm:text-sm border border-gray-700 "*/}
              {/*    />*/}
              {/*    <span className="inline-flex items-center px-3 rounded-r-md border border-r-0 border-gray-700 bg-gray-700 text-gray-300 sm:text-sm">*/}
              {/*      @sarstuff.co.uk*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
            <div className="flex w-full border-t border-gray-700 mt-8 p-4">
              <button
                onClick={submitOrg}
                disabled={Object.keys(errors).length > 0}
                className={`${
                  Object.keys(errors).length > 0
                    ? "cursor-not-allowed bg-green-900 bg-opacity-40 text-gray-700 border-green-800 "
                    : "bg-green-700 hover:bg-opacity-80 border-green-300 text-white "
                } rounded-xl border  p-2 w-full`}
              >
                Create Organisation
              </button>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/3">
          <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
            Org Details
          </div>
          <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateOrg;
