import React, {useState, useEffect} from "react";
import StatBox from "../../../shared/components/StatBox";
import API from "../../../shared/API";
import AlertMessage from "../../../shared/components/AlertMessage";

const LocateMe_Stats = ({organisation}) => {
  const [hideStats, setHideStats]       = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading]           = useState(true);
  const [stats, setStats]               = useState({});

  useEffect(() => {
    API.org.dashboard.locateme_stats(organisation.data._id).then(res => {
      if (res === false){
        setHideStats(true);
        return;
      }
      setStats(res);
      setLoading(false);
    }).catch(err => {
      setLoadingError(true);
    })
  }, [organisation]);

  return (
    <div className={"mt-2"}>
      <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
        LocateMe Stats
      </div>

      <div className="px-2 pb-2 pt-1 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
        {loadingError && <AlertMessage.Error>Unable to query LocateMe at this time, please try again later.</AlertMessage.Error>}
        {hideStats && <AlertMessage.Info>Your organisation does not have LocateMe, no stats to display.</AlertMessage.Info>}
        {!hideStats && !loadingError && <div className="pt-2 grid grid-cols-4 text-center gap-2">
          <StatBox label="[Sent] Total" value={stats.sent_total} loading={loading}/>
          <StatBox label="[Sent] Last Year" value={stats.sent_lastYear} loading={loading}/>
          <StatBox label="[Sent] This Year" value={stats.sent_thisYear} loading={loading}/>
          <StatBox label="[Sent] This Month" value={stats.sent_thisMonth} loading={loading}/>
          <StatBox label="[Hits] Total" value={stats.hit_total} loading={loading}/>
          <StatBox label="[Hits] Last Year" value={stats.hit_lastYear} loading={loading}/>
          <StatBox label="[Hits] This Year" value={stats.hit_thisYear} loading={loading}/>
          <StatBox label="[Hits] This Month" value={stats.hit_thisMonth} loading={loading}/>
        </div>}


      </div>
    </div>
  );
}
export default LocateMe_Stats;