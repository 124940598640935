import React, { useEffect, useState } from "react";

const FileUploadSelector = ({onFileSelected, className = "", maxSize = 5, isMulti = false}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {

        onFileSelected(selectedFiles);
    }, [selectedFiles]);

    const onFileSelect = e => {
        e.preventDefault();
        const theList = Object.keys(e.currentTarget.files).map(a => e.currentTarget.files[a]);

        if(isMulti){
            setSelectedFiles(selectedFiles.concat(theList));
        } else {
            setSelectedFiles([theList[0]]);
        }
        setIsHovered(false);
    }
    const onFileDrop = e => {
        e.preventDefault();
        const theList = Object.keys(e.dataTransfer.files).map(a => e.dataTransfer.files[a]);

        if(isMulti){
            setSelectedFiles(selectedFiles.concat(theList));
        } else {
            setSelectedFiles([theList[0]]);
        }
        setIsHovered(false);

        e.dataTransfer.clearData();
    };

    const handleDragLeave = e => {
        e.preventDefault();
        setIsHovered(false);
    };
    const handleDragOver = e => {
        e.preventDefault();
        setIsHovered(true);
    };

    return (
        <div className={`min-w-full ${className}`}>
            <label className={`flex flex-col items-center py-12 px-6 rounded-md border-2 border-dashed rounded-md ${isHovered  ? "border-green-400" : "border-gray-400"}`}
                   onDrop={onFileDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
                <p className="text-xl">Drag and drop file{isMulti && "(s)"}</p>
                <p className={"my-2"}>or</p>
                <div className={`bg-white px-4 h-9 inline-flex items-center rounded border border-gray-200 shadow-sm font-medium text-gray-700 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}>
                    Select File{isMulti && "(s)"}
                </div>
                <p className="text-xs text-gray-600 mt-4" >Maximum file size: {maxSize}MB</p>
                <input type="file" multiple={isMulti} onChange={onFileSelect} className="hidden" />
            </label>

            {/*{selectedFiles.length >= 1 && <div>*/}
            {/*    the selected files t4at34a 4t3aty4r3y 4g43satyg 45a3ty643a*/}

            {/*    {selectedFiles.map((a, i) => {*/}
            {/*        return (*/}
            {/*            <p key={i}>{a.name}</p>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</div>}*/}
        </div>
    );
}
export default FileUploadSelector;