import React from 'react';

const UserGroups = ({user}) => {
    if(user._id === undefined){
        return null;
    }

    return (
        <div className="w-full">
            <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
                Security Groups
            </div>
            <div className="flex gap-3 flex-wrap border border-gray-700 bg-gray-900 px-2 py-4 rounded-b-xl">
                {user.groups.length === 0 && (
                    <p>There are currently no security groups assigned to this user to display.</p>
                )}
                {user.groups.map((g) => {
                    return (
                        <span key={g._id} className="flex justify-between border border-gray-700 bg-gray-800 text-gray-300 px-4 py-2 rounded-xl whitespace-nowrap ">{g.name}</span>
                    );
                })}
            </div>
        </div>
    );
}
export default UserGroups;