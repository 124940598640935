import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import CreateOrganisation from "./create";
import ManageOrganisation from "./manage_organisation";

const OrganisationsRoute = () => {
  return (
    <Switch>
      <Route exact path="/organisations" component={Dashboard} />
      <Route exact path="/organisations/create" component={CreateOrganisation} />
      <Route path="/organisations/manage/:organisation_id" component={ManageOrganisation} />
    </Switch>
  );
};
export default OrganisationsRoute;
