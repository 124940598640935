import React from "react";

const Select = ({
  label,
  field,
  value,
  onChange,
  options = [],
  error,
  disabled,
}) => {
  // console.log("Error", label, error);
  return (
    <div className="flex flex-col w-full ">
      <label
        htmlFor={field}
        className={` block text-md font-medium font-mono  ${
          error ? "text-red-400" : "text-gray-300"
        }`}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        name={field}
        value={value}
        onChange={onChange}
        autoComplete="off"
        className={`text-white bg-gray-900 text-lg block w-full py-1.5 px-3 border border-gray-600  rounded-md shadow-sm focus:outline-none  ${
          error ? "border-red-400" : "border-gray-600"
        }`}
      >
        <option value="">{"--"}</option>
        {options.length &&
          options.map((o, i) => {
            return (
              <option key={i} value={o.value}>
                {o.text}
              </option>
            );
          })}
      </select>
    </div>
  );
};
export default Select;
