import React, {useState} from "react";
import {Dialog, Switch} from "@headlessui/react";
import PasswordChangeFields from "../../../../shared/components/PasswordChangeFields";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";
import {GrCopy} from "react-icons/gr";

function classNames(...classes){
    return classes.filter(Boolean).join(" ");
}

const ResetUserPasswordPopup = ({organisation, user, onClose}) => {
    const [isAutoPassword, setAutoPassword]             = useState(true);
    const [newPassword, setNewPassword]                 = useState(null);
    const [validationIssues, setValidationIssues]       = useState({});
    const [isSubmitting, setIsSubmitting]               = useState(false);
    const [isSuccessful, setIsSuccessful]               = useState(false);
    const [autoCreatedPassword, setAutoCreatedPassword] = useState("");
    const [submitError, setSubmitError]                 = useState("");

    const handleSubmit = () => {
        if (!isAutoPassword){
            if (newPassword === null){
                setValidationIssues({
                    password: "Password must meet all the requirements below.",
                });
                return;
            }
        }
        setValidationIssues({});
        setIsSubmitting(true);
        setSubmitError("");
        API.org.users.changePassword(organisation.data._id, user._id, isAutoPassword ? {isAutoPassword: true} : {password: newPassword}).then((res) => {
            setIsSuccessful(true);
            setAutoPassword(true);
            setNewPassword(null);
            if (res.password !== undefined){
                setAutoCreatedPassword(res.password);
            }
        }).catch((err) => {
            setSubmitError(err.response.data.message);
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    return (
        <Dialog
            open={true}
            onClose={() => {
                // Do nothing
            }}
            className="fixed z-10 inset-0 overflow-y-auto"
        >
            <div className="text-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
                <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-4 my-2 overflow-hidden text-left align-middle transition-all transform bg-gray-900 shadow-xl rounded-2xl">
                    <div className="text-white text-2xl border-b border-gray-700 p-2">
                        <Dialog.Title>Reset Password</Dialog.Title>
                    </div>
                    <div className="text-md p-2">
                        {submitError && <div style={{color: "red"}}>{submitError}</div>}
                        {isSubmitting ? (
                            <div className={"text-white"}>
                                <Loader>Changing password, please wait...</Loader>
                            </div>
                        ) : (isSuccessful ? (
                                <div className="p-2 bg-green-600 text-white text-center">
                                    {user.fullName}'s password has successfully been changed, please ensure you inform them of there new password.
                                    {autoCreatedPassword !== "" && (<React.Fragment><br />System Generated Password: <span style={{
                                        fontWeight: "bold"
                                    }}>{autoCreatedPassword}  <button className="m-2" onClick={() => {
                                        navigator.clipboard.writeText(autoCreatedPassword);
                                    }}><GrCopy className="mr-2 "/></button></span></React.Fragment>)}
                                </div>
                            ) : (
                                <div className=" max-w-3xl mx-auto">
                                    <Switch.Group as="div" className="py-4 w-full sm:grid sm:grid-cols-3 sm:gap-4 flex">
                                        <Switch.Label as="dt" className="text-md whitespace-nowrap font-medium text-gray-300" passive>Automatically generate new password?</Switch.Label>
                                        <dd className="mt-1 flex  text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Switch
                                                checked={isAutoPassword}
                                                onChange={() => setAutoPassword(!isAutoPassword)}
                                                className={classNames(
                                                    isAutoPassword ? "bg-green-600" : "bg-gray-200",
                                                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-auto"
                                                )}>
                                            <span aria-hidden="true" className={classNames(
                                                isAutoPassword ? "translate-x-5" : "translate-x-0",
                                                "inline-block h-5 w-5 rounded-full bg-black shadow transform ring-0 transition ease-in-out duration-200"
                                            )}/>
                                            </Switch>
                                        </dd>
                                    </Switch.Group>
                                    {!isAutoPassword && (
                                        <div className="mt-2 p-2 border-t border-gray-700">
                                            <p className="text-amber-500">
                                                Please enter a new password below.
                                            </p>
                                            <PasswordChangeFields
                                                onChange={(p) => setNewPassword(p)}
                                                labels
                                                error={validationIssues.password}
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                    <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                        {!isSubmitting && (isSuccessful ? <React.Fragment>
                            <button className="py-2 px-4 bg-green-500 text-white rounded-lg" onClick={() => onClose()}>Close</button>
                        </React.Fragment> : <React.Fragment>
                            <button className="py-2 px-4 bg-gray-500 text-white rounded-lg" onClick={() => onClose()}>Cancel</button>
                            <button className="py-2 px-4 bg-green-500 text-white rounded-lg" onClick={handleSubmit}>Reset Password</button>
                        </React.Fragment>)}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default ResetUserPasswordPopup;