import { Dialog } from "@headlessui/react";
import React, { useEffect, useState, useMemo } from "react";
import { Organisation_Receiver } from "../../../../context/organisation";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";
import {InformationCircleIcon} from "@heroicons/react/outline";

const initState = {};

const RolesPage = ({ organisation }) => {
  const [roles, setRoles] = useState([]);

  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState(initState);

  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);

  const canAddRoles = useMemo(() => {
    if (organisation.data.team_site !== undefined) {
      if (organisation.data.team_site.is_active && organisation.data.team_site.system === "Team4") {
        return false;
      }
    }
    return true;
  }, [organisation.data]);

  useEffect(() => {
    if (mount && organisation.data && organisation.data._id) {
      setMount(false);
      setLoading(true);

      API.org.roles
        .all(organisation.data._id)
        .then((res) => setRoles(res))
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [mount, organisation]);

  const renderModal = () => {
    const handleClose = () => {
      setModal("");
      setModalData({});
      setLoading(false);
      setMount(true);

      organisation._refresh();
    };

    const handleSubmit = () => {
      if (modalData.title) {
        setLoading(true);

        if (modal === "Add") {
          API.org.roles
            .create(organisation.data._id, modalData)
            .then(() => handleClose())
            .catch((e) => {
              console.log(e);
              setLoading(false);
            });
        } else if (modal === "Edit") {
          API.org.roles
            .update(organisation.data._id, modalData.OLDTitle, {
              title: modalData.title,
            })
            .then(() => handleClose())
            .catch((e) => {
              console.log(e);
              setLoading(false);
            });
        } else if (modal === "Delete") {
          API.org.roles
            .delete(organisation.data._id, modalData.title)
            .then(() => handleClose())
            .catch((e) => {
              console.log(e);
              setLoading(false);
            });
        }
      }
    };

    return (
      <Dialog
        open={true}
        onClose={() => {
          // Do nothing
        }}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="text-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="p-4 text-white border-4 border-gray-700 inline-block w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
            <div>
              <Dialog.Title className="text-2xl border-b border-gray-700">
                {modal === "Edit"
                  ? "Edit"
                  : modal === "Add"
                  ? "Create"
                  : "Delete"}{" "}
                User Role
              </Dialog.Title>
              <div className="pt-2">
                {modal === "Edit" || modal === "Add" ? (
                  <div className="flex flex-col w-full">
                    <label className="py-2 text-gray-400">Role Title*</label>
                    <input
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="title"
                      valu={modalData.title ? modalData.title : ""}
                      onChange={(e) =>
                        setModalData({ ...modalData, title: e.target.value })
                      }
                      value={modalData.title}
                    />
                  </div>
                ) : modal === "Delete" ? (
                  <>
                    <p>Are you sure you want to remove this role?</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="py-2 px-4 bg-gray-500 text-white rounded-lg"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className={`py-2 px-4 ${
                    modal === "Delete" ? "bg-red-500" : "bg-green-500"
                  } text-white rounded-lg disabled:opacity-60`}
                  onClick={handleSubmit}
                  disabled={modal !== "Delete" && !modalData.title}
                >
                  {modal === "Edit"
                    ? "Save"
                    : modal === "Add"
                    ? "Create"
                    : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  if(loading){
    return (
      <Loader />
    )
  }
  if(!canAddRoles){
    return (
      <div className="m-2">
        <div className={"bg-blue-400 w-full p-3"}>
          <InformationCircleIcon className={"h-6 inline mr-2"} /> Management of roles are performed within TeamSite.
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      {modal !== "" ? renderModal() : <></>}
      <div className="p-3 border-gray-700">
        <div className="w-full">
          <button
            onClick={() => setModal("Add")}
            className={`bg-green-600 p-2 rounded-md block ml-auto`}
          >
            Add Role
          </button>
        </div>
      </div>
      <div>
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
          <thead>
            <tr className="rounded-md bg-gray-900">
              <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">
            {roles.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
                >
                  <td className="px-2 py-2 text-md text-gray-200">{item}</td>
                  <td className="px-2 py-2 text-md text-gray-200 md:grid md:grid-cols-2 md:gap-4">
                    <button
                      className={`py-2 px-4 bg-blue-500 text-white rounded-lg disabled:opacity-60 block mx-auto`}
                      onClick={() => {
                        setModal("Edit");
                        setModalData({ title: item, OLDTitle: item });
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className={`py-2 px-4 bg-red-500 text-white rounded-lg disabled:opacity-60 mt-4 md:mt-0 block mx-auto`}
                      onClick={() => {
                        setModal("Delete");
                        setModalData({ title: item });
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Organisation_Receiver(RolesPage, (organisation) => {
  return { organisation };
});
