import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Gateways from "./gateways";
import Reports from "./reports";
import CentralHeader from "../../../../shared/components/CentralHeader";

const SMS = () => {
  return (
    <>
      <CentralHeader />
      <div className="block">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            <NavLink
              to={`/sms`}
              isActive={(m, l) => {
                return l.pathname.indexOf("gateways") === -1 && l.pathname.indexOf("reports") === -1;
              }}
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Dashboard</NavLink>
            <NavLink
              to={`/sms/gateways`}
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Gateways</NavLink>
            <NavLink
              to={`/sms/reports`}
              exact
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Reports</NavLink>
          </nav>
        </div>
      </div>
      <Switch>
        <Route exact path="/sms" component={Dashboard} />
        <Route path="/sms/gateways" component={Gateways} />
        <Route exact path="/sms/reports" component={Reports} />
      </Switch>
    </>
  );
}
export default SMS;