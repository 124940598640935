import React from "react";
import {Dialog, Transition} from "@headlessui/react";

const Modal = ({onClose = () => {}, title, subTitle, children}) => {
  return (
    <Transition
      show={true}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        open={true}
        onClose={() => onClose()}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="min-h-screen mx-auto text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
          <span className="inline-block h-screen  align-middle" aria-hidden="true">&#8203;</span>
          <div className="max-w-4xl border-4 border-gray-700 inline-block w-full p-6 my-8 text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
            {title !== undefined && <div className="pb-4 text-white">
              <Dialog.Title className={"text-2xl"}>{title}</Dialog.Title>
              {subTitle !== undefined && <div>{subTitle}</div>}
            </div>}
            {/*{title !== undefined && <div className="py-2 border-b border-gray-700 uppercase text-white">*/}
            {/*  <Dialog.Title className={"flex gap-4 items-center"}>*/}
            {/*    {title}*/}
            {/*  </Dialog.Title>*/}
            {/*</div>}*/}

            {children}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
export default Modal;