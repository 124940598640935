import React from "react";

const Input = ({
  label,
  field,
  type = "text",
  onChange,
  placeholder,
  value,
  maxLength,
  style = "w-full",
  error,
  disabled = false,
}) => {
  return (
    <div className={style}>
      <label
        htmlFor={field}
        className={`block text-lg font-medium font-mono ${
          error ? "text-red-400" : "text-primary"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        name={field}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete="off"
        className={`p-2 text-lg  focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm  ${
          error ? "border-red-400" : "border-gray-600"
        } ${
          disabled
            ? "bg-gray-900 border-0 text-lg text-gray-200"
            : " bg-black border border-gray-600 text-sm text-white"
        } rounded-md`}
      />
    </div>
  );
};
export default Input;
