import React, {useState, useEffect} from "react";
import dayjs from "dayjs";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";

const UserAuditLog = ({userId, organisation}) => {
    const [loading, setLoading] = useState(true);
    const [logs, setLogs]       = useState([]);

    useEffect(() => {
        getData();
    }, [organisation]);
    const getData = () => {
        Promise.all([
            API.org.users.auditLogs.recent(organisation.data._id, userId),
        ]).then((res) => {
            setLogs(res[0]);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    if (loading){
        return (
            <Loader>Loading Recent Logs...</Loader>
        )
    }

    return (
        <table className="min-w-full divide-y divide-x divide-gray-800">
            <thead>
            <tr className="bg-gray-900">
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actioned By</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Application</th>
            </tr>
            </thead>
            <tbody className="bg-black divide-y divide-gray-700 ">{logs.length === 0 && (
                <tr>
                    <td colSpan={5} className={"p-4"}>There are no recent audit logs to display for this user.</td>
                </tr>
            )}{logs.map((l) => {
                let typeColor = "";
                switch (l.type){
                    case "info":
                        typeColor = "bg-blue-500";
                        break;
                    case "warning":
                        typeColor = "bg-orange-500";
                        break;
                    case "alert":
                        typeColor = "bg-red-500";
                        break;
                    default:
                        typeColor = "bg-purple-500";
                        break;
                }
                return (
                    <tr key={l._id} className="text-gray-200 divide-x divide-gray-700">
                        <td className="px-2 py-4 text-xs whitespace-nowrap">{dayjs(l.createdAt).format("DD/MM/YYYY hh:mma")}</td>
                        <td className="px-2 py-4 text-center">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${typeColor} text-white`}>{l.type.toUpperCase()}</span>
                        </td>
                        <td className="px-2 py-4 text-xs tracking-wider">{l.message}</td>
                        <td className="px-2 py-4 text-xs whitespace-nowrap">{(l.actioned_by !== undefined && l.actioned_by !== null) ? l.actioned_by.fullName : "[SYSTEM]"}</td>
                        <td className="px-2 py-4 text-xs whitespace-nowrap">{(l.application !== undefined && l.application !== null) && l.application.name !== undefined ? l.application.name : "[NO APPLICATION]"}</td>
                    </tr>
                );
            })}</tbody>
        </table>
    );
}
export default UserAuditLog;