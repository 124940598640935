import React from "react";
import Context from "./context";

export default class OrganisationProvider extends React.Component {
  state = {
    isFirstLoading: true,
    organisation: {},
  };
  componentDidMount() {
    this.refreshData();
  }
  refreshData = () => {
    this.props
      .loadData()
      .then((res) => {
        this.setState({
          organisation: res,
        });
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {
        this.setState({
          isFirstLoading: false,
        });
      });
  };
  render() {
    return (
      <div className={"p-0 m-0"}>
        <Context.Provider
          value={{
            _refresh: this.refreshData,
            _urlPrefix: this.props.urlPrefix,
            data: this.state.organisation,
          }}
        >
          {this.state.isFirstLoading === false ? this.props.children : ""}
        </Context.Provider>
      </div>
    );
  }
}
