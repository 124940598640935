import React from "react";
import CentralHeader from "../../../shared/components/CentralHeader";
import {Organisation_Receiver} from "../../../context/organisation";

const OrganisationPageHeader = ({ title, subTitle, subIcon, organisation }) => {
    return (
        <CentralHeader
            title={title}
            subTitle={subTitle}
            subIcon={subIcon}
            image={organisation.logoImage}
        />
    );
}
export default Organisation_Receiver(OrganisationPageHeader, (organisation) => {
    return { organisation: organisation.data };
});