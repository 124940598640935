import {useEffect, useState, useCallback} from "react";
import {SupportIcon} from "@heroicons/react/outline";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import AlertMessage from "../../../shared/components/AlertMessage";
import {Organisation_Receiver} from "../../../context/organisation";
import NotifyEmails from "../_components/notify_emails";
import NotifySMS from "../_components/notify_sms";
import API from "../../../shared/API";
import OrganisationList from "./_components/organisation_list";

const SarSYS_Review = ({organisation}) => {
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [pickableRecipients, setPickableRecipients] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const getEmailList = useCallback(() => {
    API.org.sarsys_review.notify_email.getAll(organisation.data._id).then((res) => {
      setEmailRecipients(res);
    }).catch((err) => {
      //TODO
    });
  }, [organisation]);
  const getSmsList = useCallback(() => {
    API.org.sarsys_review.notify_sms.getAll(organisation.data._id).then(res => setRecipients(res)).catch((err) => {
      //TODO
    });
    API.org.sarsys_review.notify_sms.pickable(organisation.data._id).then(res => setPickableRecipients(res)).catch((err) => {
      //TODO
    });
  }, [organisation]);

  useEffect(() => {
    organisation._refresh();
  }, []);
  useEffect(() => {
    getEmailList();
    getSmsList();
  }, [getEmailList, getSmsList]);

  const handleEmailCreate = data => {
    return API.org.sarsys_review.notify_email.create(organisation.data._id, data).finally(() => {
      getEmailList();
    })
  };
  const handleEmailUpdate = (id, data) => {
    return API.org.sarsys_review.notify_email.update(organisation.data._id, id, data).finally(() => {
      getEmailList();
    });
  };
  const handleEmailDelete = id => {
    return API.org.sarsys_review.notify_email.remove(organisation.data._id, id).finally(() => {
      getEmailList();
    });
  };

  const handleSmsCreate = data => {
    return API.org.sarsys_review.notify_sms.create(organisation.data._id, data).finally(() => {
      getSmsList();
    })
  };
  const handleSmsUpdate = (id, data) => {
    return API.org.sarsys_review.notify_sms.update(organisation.data._id, id, data).finally(() => {
      getSmsList();
    })
  };
  const handleSmsDelete = id => {
    return API.org.sarsys_review.notify_sms.remove(organisation.data._id, id).finally(() => {
      getSmsList();
    });
  };

  return (
    <div className="w-full bg-black">
      <OrganisationPageHeader
        title={"SarSYS Review"}
        subTitle={"Settings for SarSYS Review."}
        subIcon={<SupportIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />}
      />
      <div className="flex flex-col sm:flex-row gap-4 mt-4 h-full px-5">
        <div className={"w-full p-2 border border-gray-700 rounded-lg"}>
          <div className="text-center text-xl">[Outbound]<br/><br/> Allowed Organisations</div>
          <div className={"text-center text-sm"}>Organisations you whitelist below are available within SarSYS to send SarSYS Review invites to.</div>
          {!organisation.data._systems.sarsys ? <div className={"mt-4"}>
            <AlertMessage.Info>
              Your organisation currently does not have SarSYS activated. Please contact the SarStuff sales team to begin your trial.
            </AlertMessage.Info>
          </div> : <div className={"mt-4"}>
            <OrganisationList organisation={organisation}/>
          </div>}
        </div>
        <div className={"w-full p-2 border border-gray-700 rounded-lg"}>
          <div className="text-center text-xl">[Inbound]<br/><br/> Notification Recipients</div>
          <div className={"text-center text-sm"}>When your team receives a SarSYS Review invite, the following will receive a notification.</div>

          <hr className={"mt-4"}/>
          <div className="text-center text-xl p-4">Recipients [Email]</div>
          <NotifyEmails recipients={emailRecipients} onCreate={handleEmailCreate} onUpdate={handleEmailUpdate} onDelete={handleEmailDelete}/>

          <hr className={"mt-4"}/>
          <div className="text-center text-xl p-4">Recipients [SMS]</div>
          <NotifySMS recipients={recipients} pickableRecipients={pickableRecipients} onCreate={handleSmsCreate} onUpdate={handleSmsUpdate} onDelete={handleSmsDelete}/>
        </div>
      </div>


    </div>
  );
}
export default Organisation_Receiver(SarSYS_Review, (organisation) => {
  return {organisation};
});