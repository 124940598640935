import React from "react";
import {NavLink} from "react-router-dom";
import {
  ChatAltIcon,
  ExternalLinkIcon,
  HomeIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  ColorSwatchIcon
} from "@heroicons/react/outline";

const SarStuff_Menu = () => {
  const menuItems = [
    {title: "Dashboard", path: "/", icon: HomeIcon, iconColor: "text-gray-400 group-hover:text-gray-500"},
    {title: "Organisations", path: "/organisations", icon: OfficeBuildingIcon, iconColor: "text-green-400 group-hover:text-green-500"},
    {title: "SMS", path: "/sms", icon: ChatAltIcon, iconColor: "text-amber-400 group-hover:text-amber-500"},
    {title: "Telephone", path: "/telephone", icon: PhoneIcon, iconColor: "text-purple-400 group-hover:text-purple-500"},
    {title: "Applications", path: "/applications", icon: ColorSwatchIcon, iconColor: "text-rose-400 group-hover:text-rose-500"},
    // {title: "Old Panel V1", path: "/central_1", icon: ExternalLinkIcon, iconColor: "text-gray-400 group-hover:text-gray-500"},
    // {title: "Old Panel V2", path: "/central_2", icon: ExternalLinkIcon, iconColor: "text-gray-400 group-hover:text-gray-500"},
  ];

  return (
    <>{menuItems.map((item, i) => {
      const TheIcon = item.icon;
      return (
        <NavLink
          key={i}
          to={item.path}
          exact
          activeClassName="bg-gray-800 text-gray-300 rounded-lg"
          className={"text-gray-200 hover:bg-gray-700 group flex items-center px-2 py-2 text-sm font-medium rounded-md"}
        >
          <TheIcon className={`${item.iconColor} mr-3 h-6 w-6`} aria-hidden="true"/>
          {item.title}
        </NavLink>
      );
    })}</>
  );
};
export default SarStuff_Menu;