import React, {useState, useEffect, useMemo} from "react";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";
import CentralHeader from "../../../../shared/components/CentralHeader";

const Dashboard = ({history}) => {
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSmsLoading, setIsSmsLoading] = useState(true);
  const [organisations, setOrganisations] = useState([]);
  const [orgSmsStatus, setOrgSmsStatus] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [typeQuery, setTypeQuery] = useState("");

  const activeOrganisations = useMemo(() => {
    return organisations.filter(item => item.system_settings.is_account_disabled === false);
  }, [organisations]);
  const archivedOrganisations = useMemo(() => {
    return organisations.filter(item => item.system_settings.is_account_disabled === true);
  }, [organisations]);
  const filtered = useMemo(() => {
    const theList = isActive ? activeOrganisations : archivedOrganisations;
    return theList.filter((item) => {
      return item.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1;
    });
  }, [isActive, searchQuery, activeOrganisations, archivedOrganisations]);
  const types = useMemo(() => {
    return filtered.reduce((list, org) => {
      if (list.indexOf(org.type) === -1) return list.concat(org.type)
      return list;
    }, []).sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    })
  }, [filtered]);

  useEffect(() => {
    API.org.all().then(res => {
      setOrganisations(res.toSorted((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        return 0;
      }));
      setIsLoading(false);
    });
    API.org.allSmsStatus().then(res => {
      setOrgSmsStatus(res);
      setIsSmsLoading(false)
    })
  }, []);

  const filterList = (e) => setSearchQuery(e.target.value);
  const filterActive = () => {
    setIsActive(true);
    setTypeQuery("");
  }
  const filterDisabled = () => {
    setIsActive(false);
    setTypeQuery("");
  }

  const goTo = (id) => history.push(`/organisations/manage/${id}`);

  return (
    <div>
      <CentralHeader/>
      <div className="p-2">
        <div className="p-2 flex gap-4 justify-between border border-gray-700 rounded-xl my-2">
          <div className="w-full flex flex-col sm:flex-row gap-4">
            <button onClick={filterActive} className={`${isActive ? "bg-green-600" : "bg-blue-600"} p-2 rounded-md`}>Active ({activeOrganisations.length})</button>
            <button onClick={filterDisabled} className={`${!isActive ? "bg-green-600" : "bg-blue-600"} p-2 rounded-md`}>Archived ({archivedOrganisations.length})</button>
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-4">
            <button
              onClick={() => history.push(`/organisations/create`)}
              className={`bg-green-600 p-2 rounded-md `}
            >
              Create
            </button>
            <input
              autoComplete="off"
              className="bg-gray-800 rounded-md p-2"
              name="filter"
              placeholder="search..."
              value={searchQuery}
              onChange={filterList}
            />
          </div>
        </div>
        <div className="p-2 flex gap-4 justify-between border border-gray-700 rounded-xl my-2">
          <div className="w-full flex flex-col sm:flex-row gap-4">{types.map(type => {
            return (
              <button key={type} onClick={() => setTypeQuery(type)} className={`${typeQuery === type ? "bg-green-600" : "bg-blue-600"} p-2 rounded-md`}>{type}</button>
            );
          })}
            {typeQuery !== "" && <button onClick={() => setTypeQuery("")} className={`bg-red-600 p-2 rounded-md`}>Clear</button>}
          </div>
        </div>
        <div className="rounded-md border border-gray-700">
          {isLoading ? (
            <Loader/>
          ) : (
            <table className="rounded-md min-w-full divide-y divide-x divide-gray-700">
              <thead>
                <tr className="rounded-md bg-gray-900">
                  <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-2 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    SMS Credits
                  </th>
                  <th className="px-2 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Active Users
                  </th>
                  <th className="px-2 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Disabled Users
                  </th>
                  <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Country & Counties
                  </th>
                </tr>
              </thead>
              <tbody className="bg-black divide-y divide-gray-700">
              {filtered.filter(a => {
                if (typeQuery === "") return true;
                return a.type === typeQuery
              }).map((o) => {
                let _sms = (orgSmsStatus[o._id] !== undefined && orgSmsStatus[o._id].balance !== undefined) ? orgSmsStatus[o._id] : {balance: "[UNKNOWN]", isV2: false}

                return (
                  <tr key={o._id} onClick={() => goTo(o._id)} className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900">
                    <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">
                      {o.system_settings.is_account_disabled && <span className={"mr-2 py-1 px-2 rounded bg-red-600 text-white"}>DISABLED</span>}
                      {o.system_settings.hide_account && <span className={"mr-2 py-1 px-2 rounded bg-orange-600 text-white"}>HIDDEN</span>}

                      {o.name}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-200 whitespace-nowrap">
                      {o.type}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-200">
                      {isSmsLoading ? <>Loading...</> : <>
                        {_sms.balance === "[UNKNOWN]" ?
                          <div className={"text-center py-1 px-2 rounded bg-red-600 text-white"}>ERROR</div>
                          :
                          (parseInt(_sms.balance) < 500 ? <div className={"text-center py-1 px-2 rounded bg-orange-600 text-white"}>{_sms.balance}</div> : <div className={"text-center py-1 px-2 rounded bg-green-600 text-white"}>{_sms.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>)}
                      </>}
                    </td>
                    {/*<td className="px-2 py-2 text-sm text-gray-200">*/}
                    {/*  {isSmsLoading ? <>Loading...</> : <>{_sms.balance !== "[UNKNOWN]" && _sms.isV2 ? <div className={"text-center py-1 px-2 rounded bg-green-600 text-white"}>V2</div> : <div className={"text-center py-1 px-2 rounded bg-orange-600 text-white"}>V1</div>}</>}*/}
                    {/*</td>*/}
                    <td className="px-2 py-2 text-sm text-gray-200 whitespace-nowrap">
                      {o._usersActive}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-200 whitespace-nowrap">
                      {o._usersDisabled}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-200 items-center ">
                      <div className="flex flex-wrap">
                        [{o.country}] {o.counties ? o.counties.join(", ") : "--"}
                      </div>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;