import React from "react";
import Select from "./Select";

const Field_DropdownInput = ({ title, name, error, value, options, isDisabled, onChange, labelClassName = "" }) => {
  return (
    <>
      <label htmlFor={name} className={labelClassName}>{title}</label>
      <Select
        placeholder={title}
        name={name}
        id={name}
        options={options}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={isDisabled}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};
export default Field_DropdownInput;