import React, { useState, useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Dialog, Transition } from "@headlessui/react";
import { LocationMarkerIcon, XIcon } from "@heroicons/react/outline";
import { Organisation_Receiver } from "../../context/organisation";
import StatBox from "../../shared/components/StatBox";
import API from "../../shared/API";
import DashboardAuditLogs from "./_components/dashboardAuditLogs";
import OrganisationPageHeader from "./_components/organisationPageHeader";
import SarSYS_Stats from "./_components/sarsys_stats";
import LocateMe_Stats from "./_components/locateme_stats";
import { AuthComponents } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import OrganisationType from "../../shared/components/formFields/organisationType";
import DisplayAndUpdatePhoto from "./_components/DisplayAndUpdatePhoto";

const Dashboard = ({ organisation, history }) => {
  const org = organisation.data;
  const [stats, setStats] = useState({
    active: 0,
    archiveD: 0,
    lockedOut: 0,
    sms_balance: 0,
  });
  const [loading, setLoading] = useState(true);
  const [editOrg, setEditOrg] = useState(false);
  const [data, setData] = useState({
    name: "",
    phone: "",
    calloutPhone: "",
    calloutEmail: "",
    email: "",
    country: "",
    counties: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getData();
    setData({
      name: org.name,
      phone: org.phone,
      calloutPhone: org.calloutPhone,
      calloutEmail: org.calloutEmail,
      email: org.email,
      country: org.country,
      type: org.type,
      counties: org.counties ? org.counties : [],
    });
  }, [org]);

  const getData = () => {
    Promise.all([API.org.dashboard.stats(org._id)])
      .then((res) => {
        setStats(res[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateOrg = () => {
    const errors = {};
    if (!data.name) errors.name = "Can't be empty";
    if (!data.country) errors.country = "Can't be empty";
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      API.org.update(org._id, data).then(() => {
        setEditOrg(false);
        organisation._refresh();
      });
    }
  };

  const handleGetUploadSpec = () => {
    return API.org.logo.getUploadConfig(org._id);
  };
  const handleLogoUpload = (logo) => {
    return API.org.logo.upload(org._id, logo).then(res => {
      organisation._refresh();
      return res;
    });
  };
  const handleGettingLogo = () => {
    return API.org.logo.getPublicLink(org._id);
  }

  const removeCounty = (county) => {
    if (county) {
      let countiesList = data.counties.filter((item) => {
        return item !== county;
      });

      setData({
        ...data,
        counties: countiesList,
      });
    }
  };
  const selectRegion = (val) => {
    // setCounty(val);
    let countiesList = data.counties;
    if (val) {
      countiesList.push(val);
      setData({
        ...data,
        counties: countiesList,
      });
    }
    setData({
      ...data,
      counties: countiesList,
    });
  };
  const EditDialog = () => {
    return (
      <Transition
        show={editOrg}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={editOrg}
          onClose={() => setEditOrg(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-4 my-2 overflow-hidden text-left align-middle transition-all transform bg-gray-900 shadow-xl rounded-2xl">
              <div className="text-white text-2xl border-b border-gray-700 p-2">
                <Dialog.Title>Edit</Dialog.Title>
              </div>
              <div className=" text-md p-2">
                <div className="flex gap-2 w-full">
                  <div className="p-2 flex flex-col w-full ">
                    <label className="py-1 px-2 text-gray-400">Name</label>
                    <input
                      className={`p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700`}
                      name="name"
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      value={data.name}
                    />
                  </div>
                  <AuthComponents.Can scope={"sarstuff:global:admins"}>
                    <OrganisationType
                      labelClassName={"px-2 text-gray-400"}
                      onChange={(val) => setData({ ...data, type: val })}
                      value={data.type}
                      validationIssue={errors.type}
                    />
                  </AuthComponents.Can>
                </div>

                <div className="flex gap-4">
                  <div className="p-2 flex flex-col w-full ">
                    <label className="py-1 px-2 text-gray-400">Phone</label>
                    <input
                      className={`p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700`}
                      name="phone"
                      onChange={(e) =>
                        setData({ ...data, phone: e.target.value })
                      }
                      value={data.phone}
                    />
                  </div>
                  <div className="p-2 flex flex-col w-full ">
                    <label className="py-1 px-2 text-gray-400">
                      Callout Phone
                    </label>
                    <input
                      className={`p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700`}
                      name="calloutPhone"
                      onChange={(e) =>
                        setData({ ...data, calloutPhone: e.target.value })
                      }
                      value={data.calloutPhone}
                    />
                  </div>
                </div>
                <div className="p-2 flex flex-col w-full ">
                  <label className="py-1 px-2 text-gray-400">Email</label>
                  <input
                    className={`p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700`}
                    name="email"
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    value={data.email}
                  />
                </div>
                <div className="p-2 flex flex-col w-full ">
                  <label className="py-1 px-2 text-gray-400">
                    Callout Email
                  </label>
                  <input
                    className={`p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700`}
                    name="calloutEmail"
                    onChange={(e) =>
                      setData({ ...data, calloutEmail: e.target.value })
                    }
                    value={data.calloutEmail}
                  />
                </div>
                <div className="flex gap-4 w-full ">
                  <div className="p-2 flex flex-col w-full ">
                    <label className="py-1 px-2 text-gray-400">Country</label>
                    <CountryDropdown
                      value={data.country}
                      // whitelist={[
                      //   "GB",
                      //   "ES",
                      //   "IE",
                      //   "FR",
                      //   "IM",
                      //   "MA",
                      //   "MT",
                      //   "PT",
                      //   "TR",
                      //   "US",
                      // ]}
                      onChange={(val) => setData({ ...data, country: val })}
                      classes="p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700"
                      valueType="short"
                    />
                    {errors.country && (
                      <p style={{ color: "red" }}>{errors.country}</p>
                    )}
                  </div>
                  <div className="p-2 flex flex-col w-full ">
                    <label className="py-1 px-2 text-gray-400">Counties</label>
                    <RegionDropdown
                      country={org.country}
                      onChange={(val) => selectRegion(val)}
                      classes="p-2 bg-gray-800 rounded-xl text-white w-full border border-gray-700"
                      countryValueType="short"
                    />
                    {errors.county && (
                      <p style={{ color: "red" }}>{errors.county}</p>
                    )}
                    <div className="p-2 flex flex-wrap gap-2">
                      {data.counties &&
                        data.counties.map((c, i) => {
                          return (
                            <div
                              className={
                                "p-2 text-sm bg-gray-700 text-white flex justify-between rounded-xl  mt-2"
                              }
                              key={i}
                            >
                              {c}{" "}
                              <sup>
                                <XIcon
                                  className="h-4 ml-2 text-red-500 cursor-pointer hover:text-white"
                                  onClick={() => removeCounty(c)}
                                />
                              </sup>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-blue-400 rounded-lg"
                  onClick={() => setEditOrg(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded-lg disabled:bg-red-500"
                  onClick={updateOrg}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className=" ">
      {EditDialog()}

      <OrganisationPageHeader
        title={org.name}
        subTitle={"Welcome to your organisations SarStuff Central Dashboard."}
        subIcon={
          <LocationMarkerIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        }
      />
      <div className="p-2 w-full flex flex-col sm:flex-row gap-4">
        <div className="w-full sm:w-1/2">
          <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
            Details
            <button
              onClick={() => setEditOrg(true)}
              className="text-sm bg-gray-800 py-1 px-4 rounded-xl border-gray-700 border hover:bg-gray-900"
            >
              Edit
            </button>
          </div>
          <div className={"border border-gray-700 bg-gray-900"}>
            <DisplayAndUpdatePhoto
              className="w-1/3 mx-auto my-2 border-8 border-white shadow-lg dark:shadow-zinc-600"
              popoverText={"Change Organisation Logo"}
              canUpdate={true}
              onGetUploadSpec={handleGetUploadSpec}
              onGetPhoto={handleGettingLogo}
              onUpload={handleLogoUpload}
            />
          </div>
          <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
            <div className="p-2 flex justify-between text-xl border-b border-gray-700">
              Phone:{" "}
              <span className="text-primary">
                {org.phone ? org.phone : "--"}
              </span>
            </div>
            <div className="p-2 flex justify-between text-xl border-b border-gray-700">
              Callout Phone:{" "}
              <span className="text-primary">
                {" "}
                {org.calloutPhone ? org.calloutPhone : "--"}
              </span>
            </div>
            <div className="p-2 flex justify-between text-xl border-b border-gray-700">
              Email: <span className="text-primary">{org.email}</span>
            </div>
            <div className="p-2 flex justify-between text-xl border-b border-gray-700">
              Callout Email:{" "}
              <span className="text-primary">{org.calloutEmail}</span>
            </div>
            <div className="p-2 flex justify-between text-xl ">
              Type: <span className="text-primary">{org.type}</span>
            </div>
          </div>
          <div className="pt-2 grid grid-cols-4 text-center gap-2">
            <StatBox
              label="SMS Credits"
              value={stats.sms_balance}
              loading={loading}
            />
            <StatBox
              label="Active Users"
              value={stats.active}
              loading={loading}
            />
            <StatBox
              label="Lockouts"
              value={stats.lockedOut}
              loading={loading}
            />
            <StatBox
              label="Archived Users"
              value={stats.archived}
              loading={loading}
            />
          </div>
          <SarSYS_Stats organisation={organisation} />
          <AuthComponents.Can scope={"sarstuff:global:admins"}>
            <LocateMe_Stats organisation={organisation} />
          </AuthComponents.Can>
        </div>
        <div className="w-full sm:w-1/2">
          <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
            Recent Important Audit Logs
          </div>
          <DashboardAuditLogs organisation={organisation} />
        </div>
      </div>
    </div>
  );
};

let OrgHomePage = Organisation_Receiver(Dashboard, (organisation) => {
  return { organisation };
});
export default OrgHomePage;
