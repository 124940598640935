import React from "react";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { NavLink, Route, Switch } from "react-router-dom";
import { Organisation_Receiver } from "../../../context/organisation";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import Settings from "./settings";
import Assets from "./assets";
import AlertMessage from "../../../shared/components/AlertMessage";

const SarMapIndex = ({ organisation }) => {
  return (
    <div>
      <OrganisationPageHeader
        title={"SarMAP"}
        subTitle={
          "Manage your organisations SarMAP trackable assets like dones, cars and mobile phones."
        }
        subIcon={
          <LocationMarkerIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        }
      />

      {!organisation.data._systems.sarmap ? <div className="block">
        <AlertMessage.Error>Opps, your organisation does not have SarMAP. Please contact the SarStuff sales team to begin a trial.</AlertMessage.Error>
      </div> : <>
        <div className="block">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            {[
              {
                title: "Assets",
                path: `${organisation._urlPrefix}/sarmap`,
                isRoot: true,
              },
              {
                title: "Settings",
                path: `${organisation._urlPrefix}/sarmap/settings`,
              },
            ].map((item, index) => {
              return (
                <NavLink
                  key={index}
                  to={item.path}
                  isActive={(m, l) => {
                    return l.pathname === item.path
                      ? true
                      : l.pathname.indexOf(item.path) !== -1 &&
                      item.isRoot === undefined;
                  }}
                  activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
                  className={
                    "border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
                  }
                >
                  {item.title}
                </NavLink>
              );
            })}
          </nav>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path={`${organisation._urlPrefix}/sarmap/settings`}
          component={Settings}
        />
        <Route
          exact
          path={`${organisation._urlPrefix}/sarmap`}
          component={Assets}
        />
      </Switch>
    </>}

    </div>
  );
};
export default Organisation_Receiver(SarMapIndex, (organisation) => {
  return { organisation };
});
