import React, { useEffect, useState, useMemo } from "react";
import { FaArrowDown, FaArrowUp, FaWindowClose } from "react-icons/fa";
import API from "../../../../shared/API";
import Box from "../../../../shared/components/Box";
import Modal from "../../../../shared/components/Modal";
import AlertMessage from "../../../../shared/components/AlertMessage";
import Field_TextInput from "../../../../shared/components/Field_TextInput";
import { Organisation_Receiver } from "../../../../context/organisation";

const moveArrayItem = (array, index, delta) => {
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === array.length) return; // Already at the top or bottom.
  const indexes = [index, newIndex].sort(); // Sort the indexes
  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from the lowest index, two elements, reverting the order
};

const AddContactModal = Organisation_Receiver(
  ({ organisation, onSelect, onClose, excludeIds, isOrderableList }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [teamMembers, setTeamMembers] = useState([]);

    const searchedMembers = useMemo(
      () =>
        teamMembers.filter((member) => {
          if (searchTerm.trim().length <= 1) return false;
          // if (excludeIds.indexOf(member._id) !== -1) return false;

          return member.fullName.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1;
        }),
      [teamMembers, searchTerm]
    );

    useEffect(() => {
      API.org.users.all(organisation.data._id).then((res) => {
        setTeamMembers(res.filter((a) => !a.isDisabled));
      });
    }, [organisation]);

    return (
      <Modal title={"Add Contact"}>
        <div className="flex flex-col gap-2 w-full">
          <Field_TextInput
            labelClassName={"text-gray-400"}
            title={"Search Team Members"}
            placeholder={"eg. Bob Smith"}
            name={"query"}
            value={searchTerm}
            onChange={(a) => setSearchTerm(a)}
          />
        </div>
        {searchedMembers.length === 0 ? (
          <AlertMessage.Info>Enter a search query to locate a team member.</AlertMessage.Info>
        ) : (
          <table className="mt-4 rounded-md min-w-full divide-y divide-x divide-gray-700 border border-gray-700">
            <thead>
              <tr className="rounded-md bg-gray-900">
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-20">Call-Sign</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email & phone</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="bg-black divide-y divide-gray-700">
              {searchedMembers.map((member) => {
                return (
                  <tr key={member._id}>
                    <td className="px-2 py-2 text-md text-gray-200">{member.fullName}</td>
                    <td className="px-2 py-2 text-md text-center text-gray-200">{member.teamId}</td>
                    <td className="px-2 py-2 text-md text-gray-200 items-center">
                      <div className="flex flex-wrap">
                        {member.email}
                        <br />
                        {member.phone}
                      </div>
                    </td>
                    <td>
                      {excludeIds.indexOf(member._id) !== -1 && isOrderableList ? (
                        <button className="px-4 py-2 bg-blue-400 rounded-lg" onClick={() => onSelect(member)}>
                          Select Again
                        </button>
                      ) : (excludeIds.indexOf(member._id) !== -1 ? (
                        <span className="px-4 py-2 bg-green-800 rounded-lg opacity-20 text-white">Already Added</span>
                      ) : (
                        <button className="px-4 py-2 bg-green-400 rounded-lg" onClick={() => onSelect(member)}>
                          Select
                        </button>
                      ))}

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
          <button className="px-4 py-2 bg-blue-400 rounded-lg" onClick={onClose}>
            Close
          </button>
        </div>
      </Modal>
    );
  },
  (organisation) => {
    return { organisation };
  }
);

const ContactList = ({ title, list, onChange, isOrderableList = false }) => {
  const [addContact, setAddContact] = useState(false);

  const handleMoveUp = (index) => {
    moveArrayItem(list, index, -1);
    onChange(list);
  };
  const handleMoveDown = (index) => {
    moveArrayItem(list, index, 1);
    onChange(list);
  };
  const handleRemoveContact = (index) => {
    onChange(list.filter((a, i) => index !== i));
  };
  const handleAddContact = (centralUser) => {
    onChange(
      list.concat([
        {
          centralId: centralUser._id,
          name: centralUser.fullName,
        },
      ])
    );
    setAddContact(false);
  };

  return (
    <>
      {addContact && <AddContactModal isOrderableList={isOrderableList} excludeIds={list.map((a) => a.centralId)} onSelect={handleAddContact} onClose={() => setAddContact(false)} />}
      <Box
        title={title}
        button={{
          text: "Pick Contact",
          colour: "bg-blue-600 border border-blue-400 text-white",
          action: () => setAddContact(true),
        }}
      >
        {list.length === 0 ? (
          <AlertMessage.Info>There are no contacts in list, add one to continue.</AlertMessage.Info>
        ) : (
          <div>
            <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
              <thead>
                <tr className="rounded-md bg-gray-900">
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{""}</th>
                </tr>
              </thead>
              <tbody className="bg-black divide-y divide-gray-700">
                {list.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-2 text-md text-gray-200">{item.name}</td>
                      <td className="p-2 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                        <div className="flex gap-4 text-xl">
                          {isOrderableList && (
                            <>
                              {index !== 0 && (
                                <button onClick={() => handleMoveUp(index)} className="text-green-500 text-xl">
                                  <FaArrowUp />
                                </button>
                              )}
                              {index !== list.length - 1 && (
                                <button onClick={() => handleMoveDown(index)} className="text-green-500 text-xl">
                                  <FaArrowDown />
                                </button>
                              )}
                            </>
                          )}
                          <button onClick={() => handleRemoveContact(index)} className="text-red-500 text-xl">
                            <FaWindowClose />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Box>
    </>
  );
};
export default ContactList;
