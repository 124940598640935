import React, { useEffect, useState } from "react";
import Select from "../../../../shared/components/Select";
import {FaWindowClose} from "react-icons/fa";
import Loader from "../../../../shared/components/Loader";
import API from "../../../../shared/API";
import AlertMessage from "../../../../shared/components/AlertMessage";

const JobTemplates = ({ organisation }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobTypes, setJobTypes] = useState([]);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = () => {
    Promise.all([
      API.org.sarsys_request.jobTemplate.pickable(organisation.data._id),
      API.org.sarsys_request.jobTemplate.getTemplates(organisation.data._id)
    ]).then(res => {
      setJobTypes(res[0].map((a, i) => {
        return {
          key: i,
          text: a,
          value: a
        }
      }))
      setData(res[1]);
    }).finally(() => {
      setLoading(false);
    })
  }
  const removeTemplate = (name) => {
    API.org.sarsys_request.jobTemplate.removeTemplate(organisation.data._id, name).then(() => {
      getTemplates();
    })
  };
  const addTemplate = (name) => {
    API.org.sarsys_request.jobTemplate.addTemplate(organisation.data._id, name).then(() => {
      getTemplates();
    })
  };

  if(loading){
    return (
      <div className="w-full">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <React.Fragment>
        <Select label="" field="template" onChange={e => addTemplate(e.target.value)} value={""} options={jobTypes.filter(a => {
          return data.indexOf(a.value) === -1
        })} />
        <div className="mt-2 border-t border-gray-700 py-2">
          {data.map((d, i) => {
            return (
              <div className="w-full bg-gray-900 p-2 border-b border-gray-700 flex gap-4 text-xl" key={i}>
                {d}
                <button onClick={() => removeTemplate(d)} className="text-red-500 text-xl">
                  <FaWindowClose />
                </button>
              </div>
            );
          })}
          {data.length === 0 && <AlertMessage.Info>Please select job types that you will accept SarSYS Request for.</AlertMessage.Info>}
        </div>
      </React.Fragment>
    </>
  );
}
export default JobTemplates;