import React, {useEffect, useState} from "react";
import prettyBytes from "pretty-bytes";
import {toast} from "react-hot-toast";
import Modal from "../../../shared/components/Modal";
import FileUploader from "../../../shared/components/FileUploader";

const UpdatePhotoModal = ({onGetUploadSpec, onUpload, onClose}) => {
  const [uploadError, setUploadError] = useState(null);
  const [isLoading, setIsUploading] = useState(false);
  const [photoToBeUploaded, setPhotoToBeUploaded] = useState(null);
  const [uploadConfig, setUploadConfig] = useState({
    maxBytes: 0,
    fileTypes: []
  });

  useEffect(() => {
    onGetUploadSpec().then(res => {
      setUploadConfig(res);
    });
  }, [onGetUploadSpec]);

  const handleOnFileChange = file => {
    setUploadError(null);
    setPhotoToBeUploaded(file);
  };
  const handlePhotoUpload = () => {
    if (photoToBeUploaded.name === undefined) {
      setUploadError("You must select an image to upload");
      return;
    } else {
      if (photoToBeUploaded.size > uploadConfig.maxBytes) {
        setUploadError("Image must be less then " + prettyBytes(uploadConfig.maxBytes, {maximumFractionDigits: 0}));
        return;
      }
      const fileTypes = uploadConfig.fileTypes.filter(a => photoToBeUploaded.type.substr(0, a.length) === a)
      if (fileTypes.length === 0) {
        setUploadError("Only images are allowed to be uploaded");
        return;
      }
    }

    setUploadError(null);
    setIsUploading(true);
    let formData = new FormData();
    formData.append("file", photoToBeUploaded);
    onUpload(formData).then(() => {
      toast.success("Successfully updated photo.");
      onClose();
    }).catch((error) => {
      let errorMessage = "Unable to upload photo at this time, please try again later";
      if (error.response !== undefined) {
        errorMessage = error.response.data.message;
      }
      setIsUploading(false);
      setUploadError(errorMessage);
    });
  };

  if (uploadConfig.maxBytes === 0) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose} title={"Upload image"}>
      {uploadError && <div className={"p-2 text-red-600"}>{uploadError}</div>}

      <div className={"pt-2"}>
        <FileUploader className={`${isLoading && "hidden"} text-white`} onFileSelected={file => handleOnFileChange(file[0])} maxSize={uploadConfig.maxBytes} />
        {isLoading && <div className={`text-2xl text-center text-white`}>Uploading, please wait...</div>}
      </div>
      <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
        <button className="rounded-lg bg-red-600 px-4 py-2 text-white disabled:opacity-20" onClick={onClose}>
          Cancel
        </button>
        <button className="rounded-lg bg-blue-600 px-4 py-2 text-white disabled:opacity-20" disabled={isLoading} onClick={handlePhotoUpload}>
          Save
        </button>
      </div>
    </Modal>
  );
};
export default UpdatePhotoModal;