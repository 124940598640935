import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {Switch} from "@headlessui/react";
import UserRoles from "./userRoles";
import UserGroups from "./userGroups";
import UserEditPopup from "./userEditPopup";
import ResetUserPasswordPopup from "./resetUserPasswordPopup";
import DisableUserPopup from "./disableUserPopup";
import EnableUserPopup from "./enableUserPopup";
import RestoreUserPopup from "./restoreUserPopup";
import DeleteUserPopup from "./deleteUserPopup";
import UnlockUserPopup from "./unlockUserPopup";
import API from "../../../../shared/API";
import AppConfig from "../../../../_appConfig";
import Blank from "../../../../assets/images/blank.png";

function classNames(...classes){
  return classes.filter(Boolean).join(" ");
}

const UserProfile = ({getData, user, organisation}) => {
  const [status, setStatus]           = useState(null);
  const [operational, setOperational] = useState(null);
  const [action, setAction]           = useState("");
  const [userPhoto, setUserPhoto]     = useState("no_photo");

  useEffect(() => {
    setStatus(user.onCall);
    setOperational(user.operational);
    API.org.users.getPhoto(user.organisation, user._id).then(res => {
      setUserPhoto(res.profileImage);
    });
  }, [user]);

  const updateStatus = async (data) => {
    await API.org.users.updateStatus(organisation.data._id, user._id, data);
    getData();
  };

  return (
    <div>
      {action === "edit" && <UserEditPopup isEdit={true} user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}
      {action === "password" && <ResetUserPasswordPopup user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}

      {action === "disable" && <DisableUserPopup user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}
      {action === "enable" && <EnableUserPopup user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}

      {action === "restore" && <RestoreUserPopup user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}
      {action === "delete" && <DeleteUserPopup user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}

      {action === "unlock" && <UnlockUserPopup user={user} organisation={organisation} onClose={() => {
        setAction("");
        getData();
      }}/>}

      <div className="p-4 flex gap-4">
        <div className="px-4 pb-2 pt-2 w-72 rounded-xl">
          <img src={userPhoto !== "no_photo" ? AppConfig.api_server + "/.." + userPhoto : Blank} className="border border-gray-700 rounded-xl ring-8 ring-gray-200" alt={`${user.fullName}'s profile picture`}/>
        </div>
        <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-xl justify-around flex flex-col">
          <div className=" p-4 border-b border-gray-700  justify-around flex flex-col gap-2 sm:flex-row ">
            {!user.isDeleted && <button onClick={() => setAction("edit")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Edit User</button>}
            {(!user.isDeleted && !user.isDisabled) && <button onClick={() => setAction("password")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Reset Password</button>}

            {(user.isDisabled && !user.isDeleted) && <button onClick={() => setAction("enable")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Enable User</button>}
            {(!user.isDisabled && !user.isDeleted) && <button onClick={() => setAction("disable")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Disable User</button>}

            {(user.isDisabled && user.isDeleted) && <button onClick={() => setAction("restore")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Restore User</button>}
            {(user.isDisabled && !user.isDeleted) && <button onClick={() => setAction("delete")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Delete User</button>}

            {(!user.isDisabled && user.isLockedOut) && <button onClick={() => setAction("unlock")} className="px-4 py-2 bg-gray-900 text-gray-400 tracking-wider text-lg border border-gray-700 rounded-xl">Unlock Account</button>}
          </div>

          {(user.isDisabled && !user.isDeleted) && <div className={"py-2 px-4 bg-orange-700"}><strong>User Disabled</strong>: This user is disabled/archived, they will be unable to login and request a password reset while in this state.</div>}
          {user.isDeleted && <div className={"py-2 px-4 bg-orange-700"}><strong>Marked for Deletion</strong>: This user will be deleted on {dayjs(user.isDeletedAt).format("DD/MM/YYYY HH:mm")} if you choose not to manually restored them.</div>}
          {user.isLockedOut && <div className={"py-2 px-4 bg-orange-700"}><strong>Account Locked</strong>: This user account is temporary blocked from logging in due to ({user.failed_count}) failed login attempts, lockout expires
            at: {dayjs(user.lockout_date).add(15, "minutes").format("DD/MM/YYYY HH:mm")}</div>}

          <div className="p-2 flex justify-between text-xl border-b border-gray-700">
            Phone: <span>{user.phone}</span>
          </div>
          <div className="p-2 flex justify-between text-xl border-b border-gray-700">
            Phone Secondary: <span>{user.phoneSecondary}</span>
          </div>
          <div className="p-2 flex justify-between text-xl border-b border-gray-700">
            Email: <span>{user.email}</span>
          </div>
          <div className="px-2 flex justify-between text-xl border-b border-gray-700">
            <Switch.Group
              as="div"
              className="py-2 w-full sm:grid sm:grid-cols-3 sm:gap-4  flex"
            >
              <Switch.Label
                as="dt"
                className="text-xl font-medium text-gray-300"
                passive
              >
                Operational
              </Switch.Label>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Switch
                  checked={operational}
                  onChange={() =>
                    updateStatus({
                      operational: !user.operational,
                    })
                  }
                  className={classNames(
                    operational ? "bg-green-600" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-auto"
                  )}
                >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          operational ? "translate-x-5" : "translate-x-0",
                          "inline-block h-5 w-5 rounded-full bg-black shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                </Switch>
              </dd>
            </Switch.Group>
          </div>
          <div className="px-2 flex justify-between text-xl border-b border-gray-700">
            <Switch.Group
              as="div"
              className="py-2 w-full sm:grid sm:grid-cols-3 sm:gap-4  flex"
            >
              <Switch.Label
                as="dt"
                className="text-xl font-medium text-gray-300"
                passive
              >
                On-Call
              </Switch.Label>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Switch
                  checked={status}
                  onChange={() =>
                    updateStatus({
                      onCall: !user.onCall,
                    })
                  }
                  className={classNames(
                    status ? "bg-green-600" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-auto"
                  )}
                >
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      status ? "translate-x-5" : "translate-x-0",
                                      "inline-block h-5 w-5 rounded-full bg-black shadow transform ring-0 transition ease-in-out duration-200"
                                    )}
                                  />
                </Switch>
              </dd>
            </Switch.Group>
          </div>
        </div>
      </div>
      <div className="pt-2 px-4">
        <UserRoles getData={getData} user={user} organisation={organisation}/>
      </div>
      <div className="pt-2 px-4">
        <UserGroups user={user}/>
      </div>
    </div>
  );
}
export default UserProfile;