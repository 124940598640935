import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import CheckboxSlider from "../../../../shared/components/CheckboxSlider";
import Select from "../../../../shared/components/Select";
import AlertMessage from "../../../../shared/components/AlertMessage";

const defaultData = {
  centralId: "",
  active: true,
};

const NotifySMSForm = ({onCreate, onUpdate, pickableRecipients, editRecipient, onComplete, onCancelEdit}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [data, setData] = useState({
    ...defaultData,
  });

  useEffect(() => {
    setData({
      ...defaultData,
      ...editRecipient,
    });
  }, [editRecipient]);

  const onSubmit = () => {
    setIsSubmitting(true);
    setFormError("");
    if (editRecipient._id !== undefined) {
      // We are updating
      onUpdate(editRecipient._id, {
        active: data.active
      }).then(() => {
        toast.success("Successfully updated notification recipient.", {
          duration: 5000,
        });
        onComplete();
        setData({
          ...defaultData,
        });
      }).catch((err) => {
        setFormError(err.response.data.message);
      }).finally(() => {
        setIsSubmitting(false);
      });
    } else {
      // We are creating
      onCreate(data).then(() => {
        toast.success("Successfully created new notification recipient.", {
          duration: 5000,
        });
        onComplete();
        setData({
          ...defaultData,
        });
      }).catch((err) => {
        setFormError(err.response.data.message);
      }).finally(() => {
        setIsSubmitting(false);
      });
    }
  };

  return (
    <>
      {formError && <AlertMessage.Error>{formError}</AlertMessage.Error>}
      <div className="flex gap-2 justify-around items-center">
        <div className="flex flex-col gap-1 w-full">
          {editRecipient._id !== undefined ? <>
            <label>Central User</label>
            <Select label="" field="template" disabled={true} value={data.centralId} options={[{
              key: editRecipient.centralId,
              value: editRecipient.centralId,
              text: editRecipient._user.fullName + " | " + editRecipient._user.phone
            }]}/>
          </> : <>
            <label>Central User</label>
            <Select label="" field="template" onChange={e => setData({...data, centralId: e.target.value})} value={data.centralId} options={pickableRecipients.map(a => {
              return {
                key: a._id,
                value: a._id,
                text: a.fullName + " | " + a.phone
              }
            })}/>
          </>}
        </div>
        <div className="flex flex-col gap-2 items-center p-2">
          <CheckboxSlider title={"Enabled"} isChecked={data.active} onChange={() =>
            setData({
              ...data,
              active: !data.active,
            })
          } disabled={isSubmitting}/>
        </div>
        <div className="flex items-center p-2 gap-2 ">
          {editRecipient._id !== undefined && (
            <button className="bg-red-600 text-white rounded-xl p-2 mt-4" onClick={() => {
              setFormError("");
              onCancelEdit();
            }}>Cancel</button>
          )}
          <button className="bg-green-600 text-white rounded-xl p-2 mt-4" onClick={() => onSubmit()}>
            {editRecipient._id !== undefined ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </>
  );
};
export default NotifySMSForm;