import React from "react";
import {Authentication} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import {Route, Switch} from "react-router-dom";
import SarStuff from "./sarstuff";
import OrganisationsIndex from "./organisation";

const OrgPages = (props) => {
  return (
    <OrganisationsIndex
      match={props.match}
      history={props.history}
      organisationId={Authentication.getUserData().organisation._id}
    />
  );
};
const AppIndex = () => {
    const profile = Authentication.getUserData();
    if (profile.groups.indexOf("5c5af952d6c1ed0e2c83e1fb") !== -1) {
        return (
            <Switch>
                <Route path="/" component={SarStuff} />
            </Switch>
        );
    }
    return (
        <Switch>
            <Route path="/" component={OrgPages} />
        </Switch>
    );
}
export default AppIndex;