import { IdentificationIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { Organisation_Receiver } from "../../../context/organisation";
import API from "../../../shared/API";
import {
  LockClosedIcon,
  LockOpenIcon,
  SaveIcon,
} from "@heroicons/react/outline";
import Input from "../../../shared/components/input";
import StatBox from "../../../shared/components/StatBox";
import Box from "../../../shared/components/Box";
import { useMount, useSetState } from "../../../shared/components/_hooks";
import { Transition, Dialog } from "@headlessui/react";
import OrganisationPageHeader from "../_components/organisationPageHeader";

function ContactsDash({ organisation }) {
  const [orgs, setOrgs] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [filter, setFilter] = useState(null);
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [contact, setContact] = useSetState({
    firstName: "",
    lastName: "",
    phone: "",
    landline: "",
    email: "",
    org: "",
  });
  useMount(() => {
    getContacts();
  });

  const getContacts = () => {
    API.org.contact.all(organisation.data._id).then((res) => {
      setContacts(res);
    });
  };
  console.log(contacts);
  const handleSubmit = () => {
    const errors = validate(contact);
    console.log("errors", errors);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      API.org.contact.add(organisation.data._id, contact).then((res) => {
        console.log("Added", res);
        setOpenAdd(false);
        getContacts();
        setContact({
          firstName: "",
          lastName: "",
          phone: "",
          landline: "",
          email: "",
          org: "",
          details: "",
        });
      });
    }
  };
  const validate = (data) => {
    const errors = {};
    if (!data.firstName) errors.firstName = "Can't be empty";
    if (!data.lastName) errors.lastName = "Can't be empty";
    if (!data.phone) errors.phone = "Can't be empty";

    return errors;
  };
  const handleUpdate = () => {
    const errors = validate(contact);
    console.log("errors", errors);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      API.org.contact
        .update(organisation.data._id, contact._id, contact)
        .then((res) => {
          console.log("Updated", res);
          setShowContact(false);
          setIsEdit(false);
          getContacts();
          setContact({
            firstName: "",
            lastName: "",
            phone: "",
            landline: "",
            email: "",
            org: "",
            details: "",
          });
        });
    }
  };
  const onContactChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };
  const AddContactDialog = () => {
    return (
      <Transition
        show={openAdd}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={openAdd}
          onClose={() => setOpenAdd(true)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="pt-4">
                <Dialog.Title className={"text-xl text-white"}>
                  Add New Contact
                </Dialog.Title>
              </div>
              <div className="pt-4 text-gray-300">
                <Dialog.Description>
                  Enter the contacts details below.
                </Dialog.Description>
              </div>
              <div className="flex p-4 justify-around gap-4">
                <Input
                  label={"First Name"}
                  type={"text"}
                  field={"firstName"}
                  value={contact.firstName}
                  onChange={onContactChange}
                  placeholder="Bob"
                  error={errors.firstName}
                />
                <Input
                  label={"Last Name"}
                  type={"text"}
                  field={"lastName"}
                  value={contact.lastName}
                  onChange={onContactChange}
                  placeholder="Smith"
                  error={errors.lastName}
                />
              </div>
              <div className="flex p-4 justify-around gap-4">
                <Input
                  label={"Mobile"}
                  type={"text"}
                  field={"phone"}
                  value={contact.phone}
                  onChange={onContactChange}
                  placeholder="07871234..."
                  error={errors.phone}
                />
                <Input
                  label={"Landline"}
                  type={"text"}
                  field={"landline"}
                  value={contact.landline}
                  onChange={onContactChange}
                  placeholder="012330..."
                />
              </div>
              <div className="flex p-4 justify-around gap-4">
                <Input
                  label={"Email"}
                  type={"text"}
                  field={"email"}
                  value={contact.email}
                  onChange={onContactChange}
                />
                <Input
                  label={"Organisation"}
                  type={"text"}
                  field={"org"}
                  value={contact.org}
                  onChange={onContactChange}
                  placeholder="Salvation Army"
                  error={errors.org}
                />
              </div>
              <div className="flex p-2 flex-col justify-around gap-2">
                <label
                  htmlFor={"details"}
                  className={`block text-lg font-medium font-mono text-primary`}
                >
                  Details
                </label>
                <textarea
                  name="details"
                  field="details"
                  value={contact.details}
                  onChange={onContactChange}
                  className={` mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm rounded-md bg-black `}
                />
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-red-600 rounded-lg"
                  onClick={() => setOpenAdd(false)}
                >
                  Cancel
                </button>
                <button
                  // disabled={disabled}
                  className="px-4 py-2 bg-green-600 rounded-lg disabled:bg-red-500"
                  onClick={handleSubmit}
                >
                  Add Contact
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const ShowContactDialog = () => {
    return (
      <Transition
        show={showContact}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={showContact}
          onClose={() => setShowContact(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="pt-4">
                <Dialog.Title className={"text-xl text-white"}>
                  Show Contact
                </Dialog.Title>
              </div>
              <div className="pt-4 text-white">
                <Dialog.Description>
                  Enter the contacts details below.
                </Dialog.Description>
              </div>
              <div className="flex p-2 flex-col md:flex-row justify-around gap-4">
                <Input
                  disabled={!isEdit}
                  label={"First Name"}
                  type={"text"}
                  field={"firstName"}
                  value={contact.firstName}
                  onChange={onContactChange}
                  placeholder="Bob"
                  error={errors.firstName}
                />
                <Input
                  disabled={!isEdit}
                  label={"Last Name"}
                  type={"text"}
                  field={"lastName"}
                  value={contact.lastName}
                  onChange={onContactChange}
                  placeholder="Smith"
                  error={errors.lastName}
                />
              </div>
              <div className="flex p-2 flex-col md:flex-row justify-around gap-4">
                <Input
                  disabled={!isEdit}
                  label={"Mobile"}
                  type={"text"}
                  field={"phone"}
                  value={contact.phone}
                  onChange={onContactChange}
                  placeholder="07871234.."
                />
                <Input
                  disabled={!isEdit}
                  label={"Landline"}
                  type={"text"}
                  field={"landline"}
                  value={contact.landline}
                  onChange={onContactChange}
                  placeholder="01233000000"
                />
              </div>
              <div className="flex p-2 flex-col md:flex-row justify-around gap-4">
                <Input
                  disabled={!isEdit}
                  label={"Email"}
                  type={"text"}
                  field={"email"}
                  value={contact.email}
                  onChange={onContactChange}
                  placeholder="01233000000"
                />

                <Input
                  disabled={!isEdit}
                  label={"Organisation"}
                  field={"org"}
                  value={contact.org}
                  // error={errors.misperType ? errors.misperType : ""}
                  onChange={onContactChange}
                />
              </div>
              <div className="flex p-2 flex-col justify-around gap-4">
                <label
                  htmlFor={"details"}
                  className={`block text-lg font-medium font-mono text-primary`}
                >
                  Details
                </label>
                <textarea
                  disabled={!isEdit}
                  name="details"
                  field="details"
                  value={contact.details}
                  onChange={onContactChange}
                  className={` mt-1 focus:ring-gray-500 focus:border-gray-500 placeholder-gray-600 block w-full shadow-sm rounded-md ${
                    !isEdit
                      ? "bg-gray-900 border-0 text-lg "
                      : " bg-black border-gray-600 text-sm "
                  } text-white p-2`}
                />
              </div>
              <div className="flex justify-between flex-col gap-2 md:flex-row mt-4 border-t border-gray-700 pt-4">
                <button
                  className="px-4 py-2 bg-red-600 rounded-lg"
                  onClick={() => setShowContact(false)}
                >
                  Close
                </button>
                <button
                  className="px-4 py-2 bg-blue-600 rounded-lg flex"
                  onClick={() => setIsEdit(!isEdit)}
                >
                  {!isEdit ? (
                    <span className="flex items-center gap-2">
                      <LockClosedIcon className="h-6" />
                      Edit
                    </span>
                  ) : (
                    <span className="flex items-center gap-2">
                      <LockOpenIcon className="h-6" />
                      Lock
                    </span>
                  )}
                </button>
                <button
                  disabled={!isEdit}
                  className="px-4 py-2 bg-green-600 rounded-lg disabled:bg-red-500 flex"
                  onClick={handleUpdate}
                >
                  <span className="flex items-center gap-2">
                    <SaveIcon className="h-6" /> Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const getFilteredContacts = () => {
    let filterItems = contacts.filter((item) => {
      let orgMatch = true;
      if (filter !== null) {
        orgMatch = item.org === filter;
      }
      return orgMatch;
    });
    return filterItems;
  };
  const ShowTheContact = (data) => {
    setContact(data);
    setShowContact(true);
  };
  return (
    <div className="w-full ">
      {AddContactDialog()}
      {ShowContactDialog()}
      <OrganisationPageHeader
        title={"Global Contacts"}
        subTitle={"Contacts system."}
        subIcon={
          <IdentificationIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        }
      />
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        <div className="col-span-1 md:col-span-1">
          <Box
            title="Contacts Dash"
            button={{
              text: "Add Contact",
              colour: "bg-green-600 border border-green-400 text-white",
              action: () => setOpenAdd(true),
            }}
          >
            <div className="grid grid-cols-1 gap-4 p-4">
              <StatBox label="Contacts" value={contacts.length} />
              {/* <StatBox label="Organisations" value={orgs.length} /> */}
            </div>
          </Box>
        </div>
        <div className="col-span-1 md:col-span-2">
          <Box title="Contacts" styles={"p-0 overflow-x-auto"}>
            <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
              <thead>
                <tr className="bg-gray-800">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Name
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Phone
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase ">
                    Organisation
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray-900 divide-y divide-gray-200">
                {getFilteredContacts().map((u) => {
                  return (
                    <tr
                      onClick={() => ShowTheContact(u)}
                      key={u._id}
                      className={`
             hover:bg-gray-800 bg-opacity-50
              cursor-pointer `}
                    >
                      <td className="p-2">
                        {u.firstName ? u.firstName + " " + u.lastName : u.name}
                      </td>
                      <td className="p-2">{u.phone}</td>
                      <td className="p-2">{u.org}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </div>
  );
}
let ContactsPage = Organisation_Receiver(ContactsDash, (organisation) => {
  return { organisation };
});
export default ContactsPage;
