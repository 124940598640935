import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Organisation_Receiver } from "../../../context/organisation";
import { FlowDownArrow, FlowStep, FlowStepStageForm } from "./_components";
import API from "../../../shared/API";
import AlertMessage from "../../../shared/components/AlertMessage";

const CallFlowManagement = ({selectedPhoneNumber, organisation}) => {
  const [editingStep, setEditingStep] = useState(false);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (selectedPhoneNumber._id === undefined) return;
    handleGetFlow();
  }, [selectedPhoneNumber]);

  const moveArrayItem = (array, index, delta) => {
    array = [].concat(array);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === array.length) return; // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indexes
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from the lowest index, two elements, reverting the order
    return array;
  };

  const handleGetFlow = () => {
    API.org.telephone.phone_numbers.flow(organisation.data._id, selectedPhoneNumber._id).then((res) => {
      setStages(res);
    });
  };
  const handleFlowSave = (flow) => {
    setStages(flow);
    API.org.telephone.phone_numbers
    .updateFlow(organisation.data._id, selectedPhoneNumber._id, {flow})
    .then(() => {
      handleGetFlow();
      toast.success("Flow changed successfully deployed to telephone service.");
    })
    .catch(() => {
    });
  };

  return (
    <div className="p-2">
      <h1 className="text-3xl p-2 text-center">Manage the Call Flow steps</h1>
      <h2 className="text-xl mb-6 text-center">Telephone: {selectedPhoneNumber.phoneNumber}</h2>
      <div className={`grid grid-cols-2`}>
        <div className={`px-4`}>
          <div className={"p-2 text-center border rounded bg-gray-900 border-blue-800"}>NOTE: Some team members details are cached by the telephone service and will take up-to 20 minutes to be fully applied. Members that are currently non-op/off-call shown in gray will be skipped.</div>

          <h2 className="py-4 text-3xl text-center">[Start]</h2>

          {stages.map((item, index) => {
            return (
              <FlowStep
                key={index}
                orderNum={index}
                orderTotal={stages.length}
                step={item}
                onReorder={direction => {
                  handleFlowSave(moveArrayItem(stages, index, direction));
                }}
                onClick={() => {
                  setEditingStep(index);
                }}
              />
            );
          })}
          {stages.length === 0 ? <div className={"p-2 border rounded bg-yellow-800 border-yellow-300 text-center"}>There are no steps configured.</div> : <FlowDownArrow/>}

          <h2 className="py-4 text-3xl text-center">[End Call]</h2>
        </div>
        <div className={`px-4`}>
          <div className={"sticky top-0"}>
            {editingStep !== false ? (
              <FlowStepStageForm
                isEdit
                step={stages[editingStep]}
                onClose={() => setEditingStep(false)}
                onChange={(data) => {
                  handleFlowSave(stages.map((a, i) => {
                    if (i === editingStep) return data;
                    return a;
                  }));
                  setEditingStep(false);
                }}
                onDelete={() => {
                  handleFlowSave(stages.filter((b, c) => c !== editingStep));
                  setEditingStep(false);
                }}
              />
            ) : <FlowStepStageForm
              step={{}} // must pass a blank object else component crashes
              onClose={() => {
              }}
              onChange={(data) => {
                handleFlowSave(stages.concat(data));
              }}
            />}
          </div>
        </div>
      </div>
    </div>
  )
}

const CallFlows = ({organisation}) => {
  const [assignedPhoneNumbers, setAssignedPhoneNumbers] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState({});

  useEffect(() => {
    API.org.telephone.phone_numbers.all(organisation.data._id).then((res) => {
      setAssignedPhoneNumbers(res);
      setSelectedPhoneNumber(res[0] !== undefined ? res[0] : {});
    });
  }, [organisation]);


  if (assignedPhoneNumbers.length === 0) {
    return (
      <div className="p-2">
        <AlertMessage.Info className={"text-center"}>There is no telephone service assigned to your organisation, please contact the SarStuff Sales team to start your trial.</AlertMessage.Info>
      </div>
    );
  }

  return (
    <div className="p-2">
      {assignedPhoneNumbers.length > 1 && <div className={"flex p-1 space-x-1 bg-blue-900/20 rounded-xl"}>
        {assignedPhoneNumbers.map((a, i) => {
          const style = selectedPhoneNumber._id === a._id ? "text-gray-100 bg-green-900 bg-opacity-50" : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800"
          return (
            <div key={i} className={`w-full cursor-pointer pl-3 py-2.5 text-lg leading-5 font-medium rounded-lg ${style}`} onClick={() => setSelectedPhoneNumber(a)}>Tel:- {a.phoneNumber}</div>
          );
        })}
      </div>}
      {selectedPhoneNumber._id !== undefined && <CallFlowManagement selectedPhoneNumber={selectedPhoneNumber} organisation={organisation}/>}
    </div>
  );
};
export default Organisation_Receiver(CallFlows, (organisation) => {
  return {organisation};
});
