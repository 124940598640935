import React, {useState, useEffect} from "react";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";

const Dashboard = ({history}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [configuredPaths, setConfiguredPaths] = useState([]);

  useEffect(() => {
    API.applications.mobile.all().then(res => {
      setConfiguredPaths(res.routes);
      setIsLoading(false);
    });
  }, []);

  const openEdit = () => {

  }

  let lastTeamSlug = "";

  return (
    <div className="p-2">
      {isLoading ? (
        <Loader/>
      ) : (
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700">
          <thead>
            <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Domain
            </th>
            <th className="px-2 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Mobile App
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Path
            </th>
          </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{configuredPaths.sort((a, b) => {
            // if(a.path > b.path) return 1;
            // if(a.path < b.path) return -1;

            if(a.domain > b.domain) return 1;
            if(a.domain < b.domain) return -1;
            return 0;
          }).map(path => {
            let showDivider = lastTeamSlug !== path.domain.split(".")[0];
            if(showDivider) lastTeamSlug = path.domain.split(".")[0];

            return (<React.Fragment key={path._id}>
                {showDivider ? (
                  <tr>
                    <td colSpan={3} className={"py-2 text-lg pl-4 uppercase bold"}>{path.domain.split(".")[0]}</td>
                  </tr>
                ) : null}
                <tr onClick={() => openEdit(path._id)} className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900">
                  <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">
                    https://{path.domain}/
                  </td>
                  <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">
                    {path.app ? path.app.name : "[Broken Application]"}
                  </td>
                  <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">
                    {path.path}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}</tbody>
        </table>
      )}
    </div>
  );
}
export default Dashboard;