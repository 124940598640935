import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import API from "../../../../../shared/API";
import Field_DropdownInput from "../../../../../shared/components/Field_DropdownInput";
import Field_TextInput from "../../../../../shared/components/Field_TextInput";
import ReturnHeadingBar from "../../../../../shared/components/ReturnHeadingBar";

const initialFormValues = {
  title: "",
  provider: "",
  isSarStuff: true,
  organisation: ""
};

const smsGatewayProviders = [
  { text: "TextLocal/BT", value: "TextLocal", id: 1 },
  { text: "Twilio", value: "Twilio", id: 2 }
]

const CreateGateway = ({ history }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({ ...initialFormValues });
  const [errors, setErrors] = useState({});
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    API.org.all().then(res => {
      setOrganisations(res.filter(a => !a.system_settings.is_account_disabled).sort((a,b) => {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }).map(org => {
        return {
          id: org._id,
          value: org._id,
          text: org.name
        }
      }))
    });
  }, []);
  useEffect(() => {
    if(data.isSarStuff && data.organisation !== ""){
      setData({
        ...data,
        organisation: ""
      })
    }

    setErrors(handleFormValidation());
  }, [data]);

  const handleFormValidation = () => {
    const errors = {};

    if (!data.title) errors.title = "Can't be empty";
    if (!data.provider) errors.provider = "Provider must be selected";
    if (!data.isSarStuff){
      if(!data.organisation){
        errors.organisation = "Organisation must be selected";
      }
    }

    return errors;
  };
  const handleCreateGateway = () => {
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      API.management.sms.gateways.create(data).then(() => {
        toast.success("Successfully created the new SMS Gateway", {
          duration: 5000,
        });
        setTimeout(() => {
          history.push("/sms/gateways");
        }, 1000);
      }).catch(error => {
        let errorMessage = "There was an unexpected error while adding new SMS Gateway";
        toast.error(errorMessage, {
          duration: 5000,
        });
      }).finally(() => {
        setIsSubmitting(false)
      })
    }
  }

  return (
    <div className="p-2">
      <ReturnHeadingBar
        linkTo={`/sms/gateways`}
      />
      <div className={"mt-2 w-full flex flex-col sm:flex-row gap-4"}>
        <div className="w-full sm:w-2/3">
          <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">Create Gateway</div>
          <div className="px-4 pb-2 pt-2 w-full border border-gray-700  rounded-b-xl flex flex-col">
            {isSubmitting && <div className={"p-2 bg-gray-700"}>Creating new SMS Gateway...</div>}

            <div className="p-2 flex gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <Field_TextInput
                  title={"Title"}
                  name={"title"}
                  value={data.title}
                  onChange={val => setData({ ...data, title: val })}
                  isDisabled={isSubmitting}
                  error={errors.title}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <Field_DropdownInput
                  title={"Provider"}
                  name={"provider"}
                  value={data.provider}
                  options={smsGatewayProviders}
                  onChange={val => setData({ ...data, provider: val })}
                  isDisabled={isSubmitting}
                  error={errors.provider}
                />
              </div>
            </div>

            <div className="p-2 flex gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <Field_DropdownInput
                  title={"Is SarStuff Owned"}
                  name={"isSarStuff"}
                  value={data.isSarStuff ? "true": "false"}
                  options={[
                    { text: "Yes", value: "true", id: 1},
                    { text: "No", value: "false", id: 0}
                  ]}
                  onChange={val => setData({ ...data, isSarStuff: (val === "true") })}
                  isDisabled={isSubmitting}
                  error={errors.isSarStuff}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                {!data.isSarStuff ? <Field_DropdownInput
                  title={"Organisation"}
                  name={"organisation"}
                  value={data.organisation}
                  options={organisations}
                  onChange={val => setData({ ...data, organisation: val })}
                  isDisabled={isSubmitting}
                  error={errors.organisation}
                /> : ""}
              </div>
            </div>

            <div className="flex w-full border-t border-gray-700 mt-8 p-4">
              <button
                onClick={handleCreateGateway}
                disabled={Object.keys(errors).length > 0}
                className={`${Object.keys(errors).length > 0
                  ? "cursor-not-allowed bg-green-900 bg-opacity-40 text-gray-700 border-green-800 "
                  : "bg-green-700 hover:bg-opacity-80 border-green-300 text-white "
                } rounded-xl border p-2 w-full`}
              >Create</button>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/3">
          <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">Final Data</div>
          <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateGateway;