import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { GrCopy } from "react-icons/gr";
import API from "../../../../shared/API";
import CheckboxSlider from "../../../../shared/components/CheckboxSlider";
import PasswordChangeFields from "../../../../shared/components/PasswordChangeFields";
import PostcodeLookup from "../../../../shared/components/formFields/postcodeLookup";

const UserEditPopup = ({ isEdit = false, user, organisation, onClose }) => {
  const [isAutoPassword, setIsAutoPassword] = useState(true);
  const [showCreateSuccess, setShowCreateSuccess] = useState(false);
  const [autoCreatedPassword, setAutoCreatedPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [data, setData] = useState({
    firstName: "",
    surname: "",
    email: "",
    teamId: "",
    phone: "",
    phoneSecondary: "",
    location: {
      type: "Feature",
      geometry: {
        coordinates: [],
        type: "Point",
      },
      properties: {},
    },
    password: null,
  });
  const [validationIssues, setValidationIssues] = useState({});
  const [postcodeIsValid, setPostcodeIsValid] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setData(user);
    }
  }, [user]);

  const handleSubmit = async () => {
    setLoading(true);
    setValidationIssues({});
    setSubmitError("");

    let validationResults = {};
    if (data.firstName.trim().length === 0) {
      validationResults.firstName = "First Name can not be empty.";
    }
    if (data.surname.trim().length === 0) {
      validationResults.surname = "Last Name can not be empty.";
    }
    if (data.email.trim().length === 0) {
      validationResults.email = "Email Address can not be empty.";
    } else if (data.email.indexOf("@") === -1) {
      validationResults.email = "Invalid Email Address supplied.";
    }
    if (data.phone.trim().length === 0) {
      validationResults.phone = "Phone number can not be empty.";
    }
    // if (!postcodeIsValid) {
    //   validationResults.postcode = "Postcode can not be empty.";
    // }

    if (!isEdit) {
      if (!isAutoPassword) {
        if (data.password === null) {
          validationResults.password =
            "Password must meet all the requirements below.";
        }
      }
    }

    if (Object.keys(validationResults).length !== 0) {
      setLoading(false);
      setValidationIssues(validationResults);
      return;
    }
    if (isEdit) {
      API.org.users
        .update(organisation.data._id, user._id, {
          firstName: data.firstName,
          surname: data.surname,
          email: data.email,
          teamId: data.teamId,
          phone: data.phone,
          phoneSecondary: data.phoneSecondary,
          location: data.location
        })
        .then(() => {
          onClose();
        })
        .catch((err) => {
          setSubmitError(err.response.data.message);
          setLoading(false);
        });
    } else {
      if (isAutoPassword) {
        data.password = "";
        data.isAutoPassword = true;
      } else {
        data.isAutoPassword = false;
      }
      API.org.users
        .add(organisation.data._id, data)
        .then((res) => {
          setShowCreateSuccess(true);
          if (res.password !== undefined) {
            setAutoCreatedPassword(res.password);
          }
        })
        .catch((err) => {
          setSubmitError(err.response.data.message);
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        // Do nothing
      }}
      className="fixed z-10 inset-0 overflow-y-auto "
    >
      <div className="text-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
        <div className="border-4  border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
          <div className="text-white text-2xl border-b border-gray-700 p-2">
            <Dialog.Title>{isEdit ? "Edit" : "Create"} User</Dialog.Title>
          </div>
          {submitError && (
            <div className={"p-2 m-2 bg-red-700 text-white"}>{submitError}</div>
          )}
          {showCreateSuccess ? (
            <React.Fragment>
              <div className="text-md p-2">
                <div className={"p-2 bg-green-600 text-white text-center"}>
                  Successfully created the new user's SarStuff Central account.
                  {autoCreatedPassword !== "" && (
                    <React.Fragment>
                      <br />
                      Auto Generated Password:{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {autoCreatedPassword}{" "}
                        <button
                          className="m-2"
                          onClick={() => {
                            navigator.clipboard.writeText(autoCreatedPassword);
                          }}
                        >
                          <GrCopy className="mr-2 " />
                        </button>
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="py-2 px-4 bg-green-500 text-white rounded-lg"
                  onClick={() => onClose()}
                >
                  Close
                </button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="text-md p-2">
                <div className="flex gap-4">
                  <div className="p-2 flex flex-col w-full">
                    <label className="px-2 text-gray-400">First Name*</label>
                    <input
                      disabled={loading}
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="firstName"
                      onChange={(e) =>
                        setData({ ...data, firstName: e.target.value })
                      }
                      value={data.firstName}
                    />
                    {validationIssues.firstName !== undefined && (
                      <div className={"p-2 text-red-600"}>
                        {validationIssues.firstName}
                      </div>
                    )}
                  </div>
                  <div className="p-2 flex flex-col w-full">
                    <label className="px-2 text-gray-400">Last Name*</label>
                    <input
                      disabled={loading}
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="surname"
                      onChange={(e) =>
                        setData({ ...data, surname: e.target.value })
                      }
                      value={data.surname}
                    />
                    {validationIssues.surname !== undefined && (
                      <div className={"p-2 text-red-600"}>
                        {validationIssues.surname}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="p-2 flex flex-col w-full">
                    <label className="px-2 text-gray-400">Email*</label>
                    <input
                      disabled={loading}
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="email"
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                      value={data.email}
                    />
                    {validationIssues.email !== undefined && (
                      <div className={"p-2 text-red-600"}>
                        {validationIssues.email}
                      </div>
                    )}
                  </div>
                  <div className="p-2 flex flex-col w-full">
                    <label className="px-2 text-gray-400">Callsign</label>
                    <input
                      disabled={loading}
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="teamId"
                      onChange={(e) =>
                        setData({ ...data, teamId: e.target.value })
                      }
                      value={data.teamId}
                    />
                    {validationIssues.teamId !== undefined && (
                      <div className={"p-2 text-red-600"}>
                        {validationIssues.teamId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="p-2 flex flex-col w-full">
                    <label className="px-2 text-gray-400">Primary Phone*</label>
                    <input
                      disabled={loading}
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="phone"
                      onChange={(e) =>
                        setData({ ...data, phone: e.target.value })
                      }
                      value={data.phone}
                    />
                    {validationIssues.phone !== undefined && (
                      <div className={"p-2 text-red-600"}>
                        {validationIssues.phone}
                      </div>
                    )}
                  </div>
                  <div className="p-2 flex flex-col w-full">
                    <label className="px-2 text-gray-400">
                      Secondary Phone
                    </label>
                    <input
                      disabled={loading}
                      className={`p-2 bg-gray-900 rounded-xl text-white`}
                      name="phoneSecondary"
                      onChange={(e) =>
                        setData({ ...data, phoneSecondary: e.target.value })
                      }
                      value={data.phoneSecondary}
                    />
                    {validationIssues.phoneSecondary !== undefined && (
                      <div className={"p-2 text-red-600"}>
                        {validationIssues.phoneSecondary}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="p-2 flex flex-col w-full">
                    <PostcodeLookup
                      title={"Home Address Postcode"}
                      isValid={setPostcodeIsValid}
                      onChange={(geoJSON) => {
                        setData({ ...data, location: geoJSON });
                      }}
                      geoJSON={data.location}
                      validationIssue={validationIssues.postcode}
                    />
                  </div>
                </div>

                {!isEdit && (
                  <div className={"flex gap-4"}>
                    <div className={"p-2 flex flex-col w-full"}>
                      <CheckboxSlider
                        title={"Automatically generate new password?"}
                        disabled={loading}
                        isChecked={isAutoPassword}
                        onChange={() => setIsAutoPassword(!isAutoPassword)}
                      />
                    </div>
                  </div>
                )}
                {!isAutoPassword && (
                  <PasswordChangeFields
                    onChange={(password) =>
                      setData({ ...data, password: password })
                    }
                    labels
                    error={validationIssues.password}
                  />
                )}
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  className="py-2 px-4 bg-gray-500 text-white rounded-lg"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  className="py-2 px-4 bg-green-500 text-white rounded-lg"
                  onClick={handleSubmit}
                >
                  {isEdit ? "Save" : "Create"}
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default UserEditPopup;
