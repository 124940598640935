import React, {useEffect, useState} from "react";
import API from "../../../../../shared/API";
import {FaPencilAlt, FaTrash} from "react-icons/fa";
import Select from "../../../../../shared/components/Select";
import Toast from "react-hot-toast";
import Confirm from "../../../../../shared/components/Confirm";


const CustomRules = ({organisation}) => {
  const [rules, setRules] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteRuleObj, setDeleteRuleObj] = useState(null);
  const [editRule, setEditRule] = useState(false);
  const [data, setData] = useState({
    user: "",
    mode: "local"
  })

  const getData = () => {
    API.org.settings.entra_id.rules.all(organisation.data._id).then(res => {
      setRules(res);
    });
  };

  useEffect(() => {
    API.org.users.all(organisation.data._id).then(users => {
      setUsers(users.sort((a, b) => {
        if(a.surname > b.surname) return 1;
        if(a.surname < b.surname) return -1;
        return 0;
      }));
    })
    getData();
  }, [organisation]);

  const handleSubmit = () => {
    if(editRule._id !== undefined){
      API.org.settings.entra_id.rules.update(organisation.data._id, editRule._id, data).then(() => {
        setEditRule(false);
        setData({
          user: "",
          mode: "local"
        });
        getData();
        Toast.success("Custom sign-in rule updated successfully");
      }).catch(err => {
        Toast.error(err.response !== undefined ? err.response.data.message : err.message);
      });
    } else {
      API.org.settings.entra_id.rules.add(organisation.data._id, data).then(() => {
        getData();
        setData({
          user: "",
          mode: "local"
        });
        Toast.success("Custom sign-in rule added successfully");
      }).catch(err => {
        Toast.error(err.response!== undefined ? err.response.data.message : err.message);
      });
    }
  };
  const handleDelete = () => {
    API.org.settings.entra_id.rules.remove(organisation.data._id, deleteRuleObj._id).then(() => {
      getData();
      Toast.success("Custom sign-in rule deleted successfully");
      setDeleteRuleObj(null);
    }).catch(err => {
      Toast.error(err.response !== undefined ? err.response.data.message : err.message);
    })
  };

  return (
    <>
      <div className={"border-b-2 border-gray-500 pb-2 mb-2"}>For phased rollouts or for team members who need different sign-in method from the general team members.</div>
      {deleteRuleObj !== null && <Confirm open={true} title={"Delete custom sign-in rule?"} isMiddle
                                          message={`Are you sure you like to delete rule for: ${deleteRuleObj.user.firstName} ${deleteRuleObj.user.surname} (${deleteRuleObj.user.email})`}
                                          onCancel={() => setDeleteRuleObj(null)}
                                          onConfirm={() => handleDelete()} />}

      <div className="flex gap-2 justify-around items-center mb-6">
        <div className="flex flex-col gap-1 w-full">
          <label>Team Member</label>
          {editRule._id !== undefined ? <Select label="" field="template" disabled={true} value={editRule.user._id} options={[{
            key: editRule.user._id,
            value: editRule.user._id,
            text: editRule.user.firstName + " " + editRule.user.surname + " (" + editRule.user.email + ")"
          }]}/> : <Select label="" field="user" onChange={e => setData({...data, user: e.target.value})} value={data.user} options={users.filter(a => {
            return rules.filter(b => b.user === a._id).length === 0;
          }).map(a => {
            return {
              key: a._id,
              value: a._id,
              text: a.firstName + " " + a.surname + " (" + a.email + ")"
            }
          })}/>}
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label>Sign-in method</label>
          <Select label="" field="mode" value={data.mode} options={[
            {
              key: 1,
              value: "local",
              text: "SarStuff Central only"
            },
            {
              key: 2,
              value: "both",
              text: "SarStuff Central or Microsoft Entra"
            },
            {
              key: 3,
              value: "federated",
              text: "Microsoft Entra only"
            }
          ]} onChange={e => setData({...data, mode: e.target.value})} />
        </div>
        <button className="bg-green-600 text-white rounded-xl p-2 mt-4" onClick={() => handleSubmit()}>
          {editRule._id !== undefined ? "Update" : "Add"}
        </button>
      </div>

      <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
        <thead>
        <tr className="rounded-md bg-gray-900">
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Team Member
          </th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Sign-in method
          </th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {""}
          </th>
        </tr>
        </thead>
        <tbody className="bg-black divide-y divide-gray-700">{rules.length === 0 ? (
          <tr>
            <td colSpan={3} className="p-2 text-center">No rules to display</td>
          </tr>
        ) : rules.map(item => {
          return (
            <tr key={item._id}>
              <td className="p-2 items-center divide-x-2 divide-gray-700  hover:bg-gray-900">
                {item.user.firstName} {item.user.surname} ({item.user.email})
              </td>
              <td className="p-2 w-20 items-center divide-x-2 divide-gray-700 hover:bg-gray-900 whitespace-nowrap">
                {item.mode === "local" ? "SarStuff Central only" : (item.mode === "both" ? "SarStuff Central or Microsoft Entra" : "Microsoft Entra only")}
              </td>
              <td className="p-2 w-14 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                <div className="flex justify-between gap-2 text-xl">
                  <button onClick={() => {
                    setEditRule(item);
                    setData({
                      mode: item.mode,
                      user: item.user._id
                    })
                  }} className="text-green-500 text-xl"><FaPencilAlt/></button>
                  <button onClick={() => setDeleteRuleObj(item)} className="text-red-500 text-xl"><FaTrash/></button>
                </div>
              </td>
            </tr>
        );
        })}</tbody>
      </table>
    </>
  )
};
export default CustomRules;