import React, { useState, useEffect } from "react";
import API from "../../API";

const PostcodeLookup = ({
  title,
  isValid,
  onChange,
  geoJSON = {
    type: "Feature",
    geometry: {
      coordinates: [],
      type: "Point",
    },
    properties: {},
  },
  isDisabled = false,
  validationIssue = "",
}) => {
  const [isChecking, setIsChecking] = useState(false);
  const [savedPostcode, setSavedPostcode] = useState("");
  const [postcode, setPostcode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      geoJSON.properties !== undefined &&
      geoJSON.properties.postcode !== undefined
    ) {
      setSavedPostcode(geoJSON.properties.postcode);
      setPostcode(geoJSON.properties.postcode);
    }
  }, [geoJSON]);
  useEffect(() => {
    if (postcode.trim() === "") {
      isValid(true);
      return;
    }
    isValid(postcode === savedPostcode);
  }, [postcode, savedPostcode]);
  useEffect(() => {
    setErrorMessage(validationIssue);
  }, [validationIssue]);

  const handleLookup = () => {
    setIsChecking(true);
    setErrorMessage("");
    API.data_services
      .postcode_lookup(postcode)
      .then((res) => {
        if (res.length > 0) {
          setSavedPostcode(res[0].postcode);
          setPostcode(res[0].postcode);

          onChange({
            type: "Feature",
            geometry: res[0].geometry,
            properties: {
              ...(geoJSON.properties || {}),
              ...res[0].properties,
              postcode: res[0].postcode,
            },
          });
        } else {
          setErrorMessage("No results/invalid postcode.");
        }
      })
      .catch(() => {
        setErrorMessage(
          "Unexpected error while querying the server, try again in a few moments."
        );
      })
      .finally(() => {
        setIsChecking(false);
      });
  };

  return (
    <div className="flex gap-2 justify-around items-center">
      <div className="flex flex-col w-full">
        <label className="px-2 text-gray-400">{title}</label>
        <input
          disabled={isDisabled || isChecking}
          className={`p-2 bg-gray-900 rounded-xl text-white mb-4`}
          name="phoneSecondary"
          onChange={(e) => setPostcode(e.target.value.toUpperCase())}
          value={postcode}
        />
        {errorMessage !== "" && (<div className={"text-red-600"}>{errorMessage}</div>)}
      </div>
      {savedPostcode !== postcode ? (
          <div className={"flex flex-col w-full"}>
          <button
            disabled={isChecking}
            className="py-2 px-4 border-2 border-green-600 text-white rounded-lg"
            onClick={handleLookup}
          >
            {isChecking ? "Checking..." : "Check Postcode"}
          </button>
          </div>
        ) : (
          <div className={"flex flex-col w-full"}>
            {geoJSON.geometry.coordinates.length > 0 && (
              <p className={"text-white"}>
                Lat: {geoJSON.geometry.coordinates[0].toFixed(5)}, Lng: {geoJSON.geometry.coordinates[1].toFixed(5)}
              </p>
            )}
          </div>
      )}
    </div>
  );
};
export default PostcodeLookup;
