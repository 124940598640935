import React, { useEffect, useState } from "react";
import Modal from "../../../../shared/components/Modal";
import Field_DropdownInput from "../../../../shared/components/Field_DropdownInput";
import Field_TextInput from "../../../../shared/components/Field_TextInput";
import ContactList from "./contactList";

const TimeoutSelectList = [
  { text: "5 Seconds", value: 5, id: 0 },
  { text: "10 Seconds", value: 10, id: 1 },
  { text: "15 Seconds", value: 15, id: 2 },
  { text: "20 Seconds", value: 20, id: 3 },
  { text: "25 Seconds", value: 25, id: 4 },
  { text: "30 Seconds", value: 30, id: 5 },
];

const ContactListFields = ({ isOrderableList, title, data, setData, errors, isSubmitting }) => {
  return (
    <div className="space-y-2">
      <Field_DropdownInput
        labelClassName={"text-gray-400"}
        title={"Ring Timeout (per contact)"}
        name={"timeout"}
        value={data.timeout || ""}
        options={TimeoutSelectList}
        onChange={(val) => {
          setData({
            ...data,
            timeout: val,
          });
        }}
        isDisabled={isSubmitting}
        error={errors.timeout}
      />
      <ContactList
        isOrderableList={isOrderableList}
        title={title}
        list={data.contacts || []}
        onChange={(contacts) =>
          setData({
            ...data,
            contacts,
          })
        }
      />
      {errors.contacts && <p style={{ color: "red" }}>{errors.contacts}</p>}
    </div>
  );
};

const FlowStepStageForm = ({ isEdit = false, step, onChange, onClose, onDelete }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepType, setStepType] = useState("");
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (step.data !== undefined) {
      setData(step.data);
    } else {
      setData({});
    }
    if (step.type !== undefined) {
      setStepType(step.type);
    } else {
      setStepType("");
    }
  }, [step]);

  const handleSubmit = () => {
    setErrors({}); // reset the errors

    if (stepType === "") {
      setErrors({
        stepType: "A step type is required",
      });
      return;
    }

    let errors = {};

    if (stepType === "ordered_list" || stepType === "random_list") {
      if (data.timeout === undefined || data.timeout === "") {
        errors.timeout = "You must select a ring timeout";
      }
      if (data.contacts === undefined || data.contacts.length === 0) {
        errors.contacts = "You must select at least one contact";
      }
    }

    if (stepType === "text") {
      if (data.say === undefined || data.say === "") {
        errors.say = "Text can not be empty";
      }
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    onChange({
      type: stepType,
      data,
    });
  };

  return (
    <div>
      <h1 className={"text-2xl p-2 text-center"}>{!isEdit ? "Add flow step" : "Modify flow step"}</h1>
      <div className="text-md p-2">
        <div className="flex flex-col gap-2 w-full">
          <Field_DropdownInput
            labelClassName={"text-gray-400"}
            title={"Step Type"}
            name={"stepType"}
            value={stepType}
            options={[
              { text: "Text to speech", value: "text", id: 0 },
              { text: "Ordered list", value: "ordered_list", id: 1 },
              { text: "Random order list", value: "random_list", id: 2 },
            ]}
            onChange={(val) => {
              setData({});
              setStepType(val);
            }}
            isDisabled={isSubmitting}
            error={errors.stepType}
          />
        </div>
      </div>
      <div className="p-2">
        {stepType === "text" && (
          <>
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                textArea={true}
                labelClassName={"text-gray-400"}
                title={"Text to be spoken"}
                name={"say"}
                value={data.say || ""}
                onChange={(val) => setData({ ...data, say: val })}
                isDisabled={isSubmitting}
                error={errors.say}
              />
            </div>
          </>
        )}
        {stepType === "ordered_list" && (
          <ContactListFields isOrderableList title={"Call contacts in order"} data={data} setData={(a) => setData(a)} errors={errors} isSubmitting={isSubmitting} />
        )}
        {stepType === "random_list" && (
          <ContactListFields title={"Call contacts in random order"} data={data} setData={(a) => setData(a)} errors={errors} isSubmitting={isSubmitting} />
        )}
      </div>
      <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
        {isEdit && <button className="py-2 px-4 bg-gray-500 text-white rounded-lg" onClick={() => onClose()}>
          Cancel
        </button>}
        {isEdit && (
          <button className="py-2 px-4 bg-red-500 text-white rounded-lg" onClick={() => onDelete()}>
            Delete
          </button>
        )}
        <button className="py-2 px-4 bg-green-500 text-white rounded-lg" onClick={handleSubmit}>
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
};
export default FlowStepStageForm;
