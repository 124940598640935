import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import CreateApplication from "./create";
import ManageApplication from "./manage";
import MobileDashboard from "./mobileDashboard";
import CentralHeader from "../../../../shared/components/CentralHeader";

const ApplicationRoutes = () => {
  return (
    <>
      <CentralHeader />
      <div className="block">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            <NavLink
              to={`/applications`}
              isActive={(m, l) => {
                return l.pathname.indexOf("mobile") === -1;
              }}
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >System App's</NavLink>
            <NavLink
              to={`/applications/mobile`}
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Mobile App's</NavLink>
          </nav>
        </div>
      </div>
      <Switch>
        <Route exact path="/applications" component={Dashboard} />
        <Route exact path="/applications/create" component={CreateApplication} />
        <Route exact path="/applications/mobile" component={MobileDashboard} />
        <Route path="/applications/manage/:application_id" component={ManageApplication} />
      </Switch>
    </>
  );
}
export default ApplicationRoutes;