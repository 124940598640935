import React, { useEffect, useState, useCallback } from "react";
import {BellIcon} from "@heroicons/react/outline";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import RecipientSelector from "./_components/RecipientSelector"
import Box from "../../../shared/components/Box";
import SendPushNotificationForm from "./_components/SendPushNotificationForm";

const PushNotifications = ({location}) => {
  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    if (location.state && location.state.recipients) {
      addToRecipients(location.state.recipients);
    }
  }, [location]);

  const addToRecipients = useCallback((data) => {
    if(data === null){
      return;
    }
    if (!Array.isArray(data)) {
      data = [data];
    }
    let newRecipients = [].concat(recipients);
    for (let index in data) {
      const singleData = data[index];
      if(singleData === null){
        continue;
      }
      const found = newRecipients.filter(item => item._id === singleData._id && item.type === singleData.type);
      if (found.length === 0) {;
        newRecipients.push(singleData);
      }
    }
    setRecipients(newRecipients);
  }, [recipients]);
  const removeRecipient = useCallback((data) => {
    if(data === null){
      return;
    }
    if (!Array.isArray(data)) {
      data = [data];
    }

    let newRecipients = [];
    for (let index in recipients) {
      const singleData = recipients[index];
      if(singleData === null){
        continue;
      }
      const found = data.filter(item => item._id === singleData._id && item.type === singleData.type);
      if (found.length === 0) {
        newRecipients.push(singleData);
      }
    }
    setRecipients(newRecipients);
  }, [recipients]);

  return (
    <div className="w-full">
      <OrganisationPageHeader
        title={"Push Notifications"}
        subTitle={"Send push notifications to users who have devices registered with this service."}
        subIcon={
          <BellIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
        }
      />
      <div className={"bg-yellow-800 p-2 m-2 border border-yellow-500"}><span className={"text-xl"}>Notice</span><br />Only users with registered push notification devices will be displayed.</div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        <div className="col-span-2 md:col-span-2">
          <Box className="flex" title="Compose & Send Push Notification">
            <SendPushNotificationForm
              recipients={recipients}
              onRecipientRemove={removeRecipient}
            />
          </Box>
        </div>
        <div className="col-span-1 md:col-span-1">
          <Box className="flex" title="Users">
            <RecipientSelector
              recipients={recipients}
              onRecipientRemove={removeRecipient}
              onRecipientAdd={addToRecipients}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
export default PushNotifications;