import React, { useEffect, useState } from "react";
import prettyBytes from "pretty-bytes";
import Toast from "react-hot-toast";

const FileUploadSelector = ({ onFileSelected, className = "", maxSize = 5120000, isMulti = false }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if(selectedFiles.length === 0) return;
    onFileSelected(selectedFiles);
  }, [selectedFiles]);

  const addFileToState = files => {
    if (isMulti) {
      const theFiles = selectedFiles.concat(files);
      if(theFiles.reduce((acc, file) => acc + file.size, 0) > maxSize) {
        Toast.error("Total sub of all files exceeds maximum upload limit of " + prettyBytes(maxSize, {maximumFractionDigits: 0, binary: true}));
        return;
      }
      setSelectedFiles(theFiles);
    } else {
      if(files[0].size > maxSize){
        Toast.error("File exceeds max size of " + prettyBytes(maxSize, {maximumFractionDigits: 0, binary: true}));
        return;
      }
      setSelectedFiles([files[0]]);
    }
  }
  const onFileSelect = (e) => {
    e.preventDefault();

    addFileToState(Object.keys(e.currentTarget.files).map((a) => e.currentTarget.files[a]));

    setIsHovered(false);
  };
  const onFileDrop = (e) => {
    e.preventDefault();
    e.dataTransfer.clearData();

    addFileToState(Object.keys(e.dataTransfer.files).map((a) => e.dataTransfer.files[a]));

    setIsHovered(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsHovered(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsHovered(true);
  };

  return (
    <div className={`min-w-full ${className}`}>
      <label
        className={`flex flex-col items-center py-5 px-6 border-2 border-dashed rounded-md ${isHovered ? "border-green-400" : "border-gray-400"}`}
        onDrop={onFileDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <p className="text-xl">Drag and drop file{isMulti && "(s)"}</p>
        <p className={"my-2"}>or</p>
        <div className={`bg-white px-4 h-9 inline-flex items-center rounded border border-gray-200 shadow-sm font-medium text-gray-700 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}>
          Select File{isMulti && "(s)"}
        </div>
        <p className="text-xs text-gray-600 mt-4">Maximum file size: {prettyBytes(maxSize, {maximumFractionDigits: 0, binary: true})}</p>
        <input type="file" multiple={isMulti} onChange={onFileSelect} className="hidden" />
      </label>
      <div className={"p-1"}>
        {selectedFiles.map((file, id) => {
          return (<div key={id} className={"flex gap-3 py-1"}>
            <div className={"w-full overflow-clip"}>{file.name}</div> <button className={"text-red-600 border rounded-3xl w-7 float-right p-1 text-sm"} onClick={() => setSelectedFiles(a => a.filter((b, c) => c !== id))}>X</button>
          </div>)
        })}
      </div>
    </div>
  );
};


export default FileUploadSelector;
