import React from "react";

const Field_TextInput = ({ title, name, error, value, isDisabled, onChange, placeholder = "", labelClassName = "", textArea = false }) => {
  return (
    <>
      <label htmlFor={name} className={labelClassName}>{title}</label>
      {textArea ? <textarea
        cols={3}
        name={name}
        id={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
        autoComplete="off"
        disabled={isDisabled}
       /> : <input
        name={name}
        id={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
        autoComplete="off"
        disabled={isDisabled}
      />}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};
export default Field_TextInput;