import React, {useEffect, useState} from "react";
import API from "../../../../../shared/API";
import AlertMessage from "../../../../../shared/components/AlertMessage";

const ListGateways = ({history}) => {
  const [gateways, setGateways] = useState([]);

  useEffect(() => {
    API.management.sms.gateways.getAll().then(res => {
      setGateways(res);
    })
  }, []);

  const goTo = (id) => history.push(`/sms/gateways/${id}`);

  return (
    <div className={"p-2"}>
      <div className="p-2 flex gap-4 justify-between border border-gray-700 rounded-xl my-2">
        <div className={"w-full flex flex-col"}>
          <h1 className={"pl-2 text-2xl"}>SMS Gateways</h1>
        </div>
        <div className="w-full flex flex-col sm:flex-row gap-4">
          <button onClick={() => history.push(`/sms/gateways/create`)} className={`bg-green-600 p-2 rounded-md`}>Create</button>
        </div>
      </div>
      <div className="rounded-md border border-gray-700">
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700">
          <thead>
            <tr className="rounded-md bg-gray-900">
              <th className=" px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Provider
              </th>
              <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Owner
              </th>
            </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{gateways.map(gateway => {
            return (
              <tr
                key={gateway._id}
                onClick={() => goTo(gateway._id)}
                className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
              >
                <td className="px-2 py-2 text-md text-gray-200">{gateway.title}</td>
                <td className="px-2 py-2 text-sm text-gray-200">{gateway.provider}</td>
                <td className="px-2 py-2 text-sm text-gray-200">{gateway.isDisabled ? <span className={"py-1 px-2 rounded bg-red-600 text-white"}>Disabled</span> : <span className={"py-1 px-2 rounded bg-green-600 text-white"}>Enabled</span>}</td>
                <td className="px-2 py-2 text-sm text-gray-200">{gateway.isSarStuff ? <span className={"py-1 px-2 rounded bg-orange-600 text-white"}>SarStuff Gateway</span> : <span className={"py-1 px-2 rounded bg-blue-600 text-white"}>Organisation Gateway</span>}</td>
              </tr>
            );
          })}</tbody>
        </table>
        {gateways.length === 0 && <AlertMessage.Info>There are no Gateways configured</AlertMessage.Info>}
      </div>
    </div>
  );
}
export default ListGateways;