import React, {Suspense} from "react";
import {AuthenticationWrapper} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import {Toaster} from "react-hot-toast";
import AppConfig from "./_appConfig";
import AppIndex from "./_app";
import FrontendUpdateChecker from "./lib/frontendUpdateChecker";

const App = () => {
    return (
        <AuthenticationWrapper
            config={{
                clientId: AppConfig.auth.clientId,
                loginServer: AppConfig.auth.loginServer,
                localPrefix: AppConfig.app_storage_prefix,
                applicationServer: AppConfig.api_server,
            }}
        >
            <Suspense fallback={<div>Loading</div>}>
                <Toaster position="top-right"/>
                <FrontendUpdateChecker/>
                <AppIndex/>
            </Suspense>
        </AuthenticationWrapper>
    );
};
export default App;