import React, {useEffect, useState} from "react";
import API from "../../../../../shared/API";
import {FaTrash} from "react-icons/fa";
import Modal from "../../../../../shared/components/Modal";
import Toast from "react-hot-toast";

const DomainManagement = ({organisation}) => {
  const [domains, setDomains] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [domainName, setDomainName] = useState("");

  const setRemoveDomainId = () => {

  }

  const getData = () => {
    API.org.settings.entra_id.domains.all(organisation.data._id).then(res => {
      setDomains(res);
    });
  };

  useEffect(() => {
    if (!showAddForm) {
      setDomainName("");
    }
  }, [showAddForm])
  useEffect(() => {
    getData();
  }, [organisation]);

  const handleAddDomain = () => {
    if (domainName.length < 5) {
      Toast.error("Domain name must be specified.");
      return;
    }

    setIsLoading(true);
    API.org.settings.entra_id.domains.add(organisation.data._id, {domain: domainName}).then((res) => {
      getData();
      setShowVerify(res);
      setShowAddForm(false);
      Toast.success("Successfully added domain, please complete the verification process to activate it.")
    }).catch(err => {
      Toast.error(err.response !== undefined ? err.response.data.message : "Unable to add domain at this time, please try again later.");
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div>
      {showAddForm && <Modal title={"Add Domain"} onClose={() => setShowAddForm(false)}>
        {isLoading ? <div className={"text-2xl text-center py-10 text-white"}>Loading...</div> : <div className={"text-white"}>
          <div className="flex flex-col gap-1 w-full">
            <label>Domain Name</label>
            <input
              className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
              type={"text"}
              name={"domain"}
              autoComplete={"none"}
              required
              value={domainName}
              onChange={(e) => setDomainName(e.target.value.trim().toLowerCase())}
            />
          </div>
          <div className="flex items-center p-2 gap-2 justify-evenly">
            <button className="bg-red-600 text-white rounded-xl p-2 mt-4" onClick={() => {
              setShowAddForm(false);
            }}>Cancel
            </button>
            <button className="bg-green-600 text-white rounded-xl p-2 mt-4" onClick={() => handleAddDomain()}>
              Add domain
            </button>
          </div>
        </div>}
      </Modal>}
      {showVerify !== false && <Modal title={"Verify domain ownership"}>
        {isLoading ? <div className={"text-2xl text-center py-10 text-white"}>Loading...</div> : <div className={"text-white"}>
          <div className="flex flex-col gap-1 w-full">
            <p>To verify of {showVerify.domain}. Please add the following TXT record to your public facing DNS.</p>

          </div>
          <div className="flex items-center p-2 gap-2 justify-evenly">
            <button className="bg-red-600 text-white rounded-xl p-2 mt-4" onClick={() => {
              setShowVerify(false);
            }}>Cancel
            </button>
            <button className="bg-green-600 text-white rounded-xl p-2 mt-4" onClick={() => {
              setShowVerify(false);
              // Redirect to verify domain ownership
            }}>
              Verify domain
            </button>
          </div>
        </div>}

      </Modal>}

      <div className={"border-b-2 border-gray-500 pb-2 mb-2"}>Verify your domains to enable team members with related email address to be redirected to Microsoft Entra ID for sign-in.</div>
      <button className="float-right bg-green-600 text-white rounded-xl p-2 my-2" onClick={() => setShowAddForm(true)}>
        Add domain
      </button>

      <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
        <thead>
        <tr className="rounded-md bg-gray-900">
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Domain
          </th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  whitespace-nowrap">
            Verification Status
          </th>
          <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {""}
          </th>
        </tr>
        </thead>
        <tbody className="bg-black divide-y divide-gray-700">{domains.length === 0 ? (
          <tr>
            <td colSpan={3} className="p-2 text-center">No domains to display</td>
          </tr>
        ) : domains.map(item => {
          return (
            <tr key={item._id}>
              <td className="p-2 items-center divide-x-2 divide-gray-700  hover:bg-gray-900">
                {item.domain}
              </td>
              <td className="p-2 w-20 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                {item.isActive ? <div className={"px-1 bg-green-800 border border-green-400 w-10 text-center rounded"}>Yes</div> :
                  <button className={"w-40 border rounded"} onClick={() => setShowVerify(item)}>Verify Domain</button>
                }
              </td>
              <td className="p-2 w-14 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                <button onClick={() => setRemoveDomainId(item._id)} className="text-red-500 text-xl"><FaTrash/></button>
              </td>
            </tr>
          );
        })}</tbody>
      </table>
    </div>
  );
}
export default DomainManagement;