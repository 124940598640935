import React, {useState} from "react";
import {FaPencilAlt, FaTrash} from "react-icons/fa";
import NotifyEmailForm from "./notify_email_form";
import Confirm from "../../../../shared/components/Confirm";
import AlertMessage from "../../../../shared/components/AlertMessage";

export default function NotifyEmails({recipients, onCreate, onUpdate, onDelete}) {
  const [editRecipient, setEditRecipient] = useState({});
  const [removeRecipientId, setRemoveRecipientId] = useState("");

  const removeRecipient = () => {
    onDelete(removeRecipientId).then(() => {
      // Do nothing
    }).catch((err) => {
      //TODO
    }).finally(() => {
      setEditRecipient({});
      setRemoveRecipientId("");
    });
  };

  let removeName = "";
  if (removeRecipientId !== "") {
    removeName = recipients.filter(a => a._id === removeRecipientId)[0].email;
  }

  return (
    <>
      <Confirm
        open={removeRecipientId !== ""}
        onCancel={() => setRemoveRecipientId("")}
        onConfirm={() => removeRecipient()}
        title={"Remove notification recipient?"}
        message={"Are you sure you want to remove recipient: " + removeName}
      />

      <NotifyEmailForm
        editRecipient={editRecipient}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onComplete={() => {
          setEditRecipient({});
        }}
        onCancelEdit={() => {
          setEditRecipient({});
        }}
      />
      <div className="mt-1 border-t border-gray-700 py-1"></div>
      {recipients.length === 0 ? (
        <AlertMessage.Info>There are no stored recipients, please add one to start receiving email notifications.</AlertMessage.Info>
      ) : (
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
          <thead>
          <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Active
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {""}
            </th>
          </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{recipients.map((recipient) => {
            return (
              <tr key={recipient._id}>
                <td className="p-2 items-center divide-x-2 divide-gray-700  hover:bg-gray-900">
                  {recipient.email}
                </td>
                <td className="p-2 w-20 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                  {recipient.active ? <div className={"px-1 bg-green-800 border border-green-400 w-10 text-center rounded"}>Yes</div> : <div className={"px-1 bg-red-800 border border-red-400 w-10 text-center rounded"}>No</div>}
                </td>
                <td className="p-2 w-14 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                  <div className="flex gap-4 text-xl">
                    <button onClick={() => setEditRecipient(recipient)} className="text-blue-500 text-xl"><FaPencilAlt/></button>
                    <button onClick={() => setRemoveRecipientId(recipient._id)} className="text-red-500 text-xl"><FaTrash/></button>
                  </div>
                </td>
              </tr>
            );
          })}</tbody>
        </table>
      )}
    </>
  );
}