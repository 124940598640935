import React from "react";
import {Route, Switch} from "react-router-dom";

const ReportsIndex = () => {
  return (
    <div className="p-2">
      SMS Reports
    </div>
  );
}
export default ReportsIndex;