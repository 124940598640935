import React, { useEffect, useState } from "react";
import Select from "../../../shared/components/Select";
import MainMapOS from "../../../shared/components/mapping/MainMap";
import API from "../../../shared/API";
import toast from "react-hot-toast";

const LocationAndMapping = ({ organisation }) => {
  const [center, setCenter] = useState(organisation.data.defaultMapCenter.length > 0 ? organisation.data.defaultMapCenter : [0.8927, 51.2271]);
  const [isDisabled, setDisabled] = useState(true);
  const [gridType, setGridType] = useState(organisation.data.gridType);
  const [update, setUpdate] = useState(true);
  const [datahubKey, setDatahubKey] = useState(organisation.data.datahubKey ? organisation.data.datahubKey : "");
  const [gridTypes, setGridTypes] = useState([]);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    let types =
      settings.gridTypes &&
      settings.gridTypes.map((t, i) => {
        return {
          key: i,
          text: t,
          value: t,
        };
      });
    setGridTypes(types);
    return () => {
      setGridTypes([]);
    };
  }, [settings]);

  useEffect(() => {
    getData();
  }, []);

  function handleSelectChange(e) {
    setGridType(e.target.value);
    setUpdate(false);
  }

  function handleUpdate() {
    API.org.update(organisation.data._id, { gridType: gridType }).then(() => {
      organisation._refresh();
      toast.success("Successfully updated grid type.", {
        duration: 2000,
      });
    });
    setUpdate(true);
  }

  const getData = () => {
    Promise.all([API.settings.all()])
      .then((res) => {
        setSettings(res[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onClickMap = (coords) => {
    // console.log("click", map, evt);
    // if (evt.lngLat) {
    //   let coords = [
    //     parseFloat(evt.lngLat.lng.toFixed(5)),
    //     parseFloat(evt.lngLat.lat.toFixed(5)),
    //   ];
    setDisabled(false);
    if (coords.length) {
      setCenter([coords[1].toFixed(4), coords[0].toFixed(4)]);
    }
    // }
  };
  const setDefaultCenter = () => {
    setDisabled(true);
    API.org.update(organisation.data._id, { defaultMapCenter: center }).then(() => {
      organisation._refresh();
      getData();
      toast.success("Successfully updated default map center.", {
        duration: 2000,
      });
    });
  };
  const setAPIKey = () => {
    setDisabled(true);
    API.org.update(organisation.data._id, { datahubKey: datahubKey }).then(() => {
      organisation._refresh();
      toast.success("Successfully set map key.", {
        duration: 2000,
      });
    });
  };

  return (
    <div className="w-full flex flex-col sm:flex-row">
      <div className="w-full sm:w-1/2 flex flex-col p-4">
        <div>
          <label>OS Data Hub Key</label>
          <div className="flex">
            <input
              className="w-full bg-gray-800 p-2 rounded-l-md border-gray-700 border"
              value={datahubKey}
              placeholder="PSGA key..."
              onChange={(e) => setDatahubKey(e.target.value)}
            />
            <button className="bg-green-600 py-2 px-4 rounded-r-md" onClick={setAPIKey}>
              Set
            </button>
          </div>

          <div className="italic text-gray-500 p-2 mb-2">If you have a valid OS Data Hub enter it above and you will then be able to us OS Mapping.</div>
        </div>
        <div>
          <label htmlFor="type">Grid Type</label>
          <div className="flex">
            <Select placeholder={"Pick One"} name={"gridType"} options={gridTypes ? gridTypes : []} value={gridType} onChange={handleSelectChange} />
            <button className={` rounded-r-md p-2 ${update ? "bg-green-900 text-gray-700" : "bg-green-500 text-white"}`} disabled={update} onClick={handleUpdate}>
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 p-2">
        <label>Default Map Center</label>
        <div className="flex mb-2">
          <input
            className="px-2 w-2/3 bg-gray-800  rounded-l-md border-gray-700 border text-gray-200"
            value={center.join(",")}
            placeholder="Example: 0.8927,51.2271"
            onChange={(e) => setCenter(e.target.value.split(","))}
          />
          <button className={`w-1/3  py-2 px-4 rounded-r-md ${isDisabled ? "bg-green-900" : "bg-green-500 text-white"}`} onClick={setDefaultCenter}>
            Set Center
          </button>
        </div>
        <div className="border border-gray-700 rounded-xl">
          {center && center.length === 2 && <MainMapOS mapCenter={center} showCCTV={false} addLoc={true} onClick={onClickMap} />}
        </div>
      </div>
    </div>
  );
};
export default LocationAndMapping;
