import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import AlertMessage from "../../../../../shared/components/AlertMessage";

const defaultData = {
  callback: "",
};

const RedirectForm = ({editRecipient, onCreate, onUpdate, onComplete, onCancelEdit}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [data, setData] = useState({
    ...defaultData,
  });

  useEffect(() => {
    setData({
      ...defaultData,
      ...editRecipient,
    });
  }, [editRecipient]);

  const onSubmit = () => {
    setIsSubmitting(true);
    setFormError("");
    if (editRecipient._id !== undefined) {
      // We are updating
      onUpdate(editRecipient._id, data).then(() => {
        toast.success("Successfully updated.", {
          duration: 5000,
        });
        onComplete();
        setData({
          ...defaultData,
        });
      }).catch((err) => {
        setFormError(err.response.data.message);
      }).finally(() => {
        setIsSubmitting(false);
      });
    } else {
      // We are creating
      onCreate(data).then(() => {
        toast.success("Successfully added.", {
          duration: 5000,
        });
        onComplete();
        setData({
          ...defaultData,
        });
      }).catch((err) => {
        setFormError(err.response.data.message);
      }).finally(() => {
        setIsSubmitting(false);
      });
    }
  };

  return (
    <>
      {formError && <AlertMessage.Error>{formError}</AlertMessage.Error>}
      <div className="flex gap-2 justify-around items-center">
        <div className="flex flex-col gap-1 w-full">
          <label>{editRecipient._id !== undefined ? "[Editing: " + editRecipient.callback + "]" : "URL"}</label>
          <input
            className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
            type={"text"}
            name={"callback"}
            autoComplete={"none"}
            required
            disabled={isSubmitting}
            value={data.callback}
            onChange={(e) => setData({...data, callback: e.target.value})}
          />
        </div>
        <div className="flex items-center pt-3 gap-2">
          {editRecipient._id !== undefined && (
            <button className="bg-red-600 text-white rounded-xl p-2 mt-4" disabled={isSubmitting} onClick={() => {
              setFormError("");
              onCancelEdit();
            }}>Cancel</button>
          )}
          <button className="bg-green-600 text-white rounded-xl p-2 mt-4" disabled={isSubmitting} onClick={() => onSubmit()}>
            {editRecipient._id !== undefined ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </>
  );
};
export default RedirectForm;