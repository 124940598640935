import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import {UsersIcon} from "@heroicons/react/outline";
import {Organisation_Receiver} from "../../../context/organisation";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import UserPage from "./userPage";
import SecurityGroup_Routes from "./security_groups";
import Dashboard from "./dashboard";
import UserImporter from "./import";
import RolesPage from "./roles";

const TeamIndex = ({organisation}) => {
  return (
    <div>
      <OrganisationPageHeader title={"Team Members"} subTitle={"Manage your organisations team members user accounts."}
                              subIcon={<UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>}/>
      <div className="block">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            <NavLink
              to={`${organisation._urlPrefix}/team`}
              isActive={(m, l) => {
                return l.pathname.indexOf("roles") === -1 && l.pathname.indexOf("security_groups") === -1;
              }}
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >User Accounts</NavLink>
            <NavLink
              to={`${organisation._urlPrefix}/team/security_groups`}
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Security Groups</NavLink>
            <NavLink
              to={`${organisation._urlPrefix}/team/roles`}
              exact
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Roles</NavLink>
          </nav>
        </div>
      </div>
      <Switch>
        <Route exact path={`${organisation._urlPrefix}/team/import`} component={UserImporter} />
        <Route exact path={`${organisation._urlPrefix}/team/roles`} component={RolesPage} />
        <Route path={`${organisation._urlPrefix}/team/security_groups`} component={SecurityGroup_Routes} />
        <Route exact path={`${organisation._urlPrefix}/team/:userid`} component={UserPage} />
        <Route exact path={`${organisation._urlPrefix}/team`} component={Dashboard} />
      </Switch>
    </div>
  );
};
export default Organisation_Receiver(TeamIndex, (organisation) => {
  return {organisation};
});