import React, {useEffect, useState} from "react";
import API from "../../../../shared/API";
import {Organisation_Receiver} from "../../../../context/organisation";

const Groups = ({organisation, history}) => {
    const [isLoading, setLoading] = useState(true);
    const [groups, setGroups]     = useState([]);

    useEffect(() => {
        getData();
    }, [organisation]);
    const getData = () => {
        Promise.all([
            API.org.groups.all(organisation.data._id)
        ]).then((res) => {
            setGroups(res[0]);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const createNewGroup = (data) => {
        setLoading(true);
        API.org.groups
            .add(organisation.data._id, data)
            .then((res) => {
                // setAdd(false);
                getData();
            })
            .catch((err) => {
                let errorMessage =
                        "There was an unknown network error while s data from the server";
                if (err.response !== undefined){
                    if (err.response.data.message !== undefined){
                        errorMessage = err.response.data.message;
                    }
                }
                console.log(errorMessage);
                // setError(errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const goToGroup      = id => {
        history.push(`${organisation._urlPrefix}/team/security_groups/${id}`)
    };

    return (
        <div>
            <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
                <thead className="">
                <tr className="rounded-md bg-gray-900">
                    <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">
                        Description
                    </th>
                    <th className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                        Users
                    </th>
                    {/* <th className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase w-24">
                     Action
                     </th> */}
                </tr>
                </thead>
                <tbody className="bg-black divide-y divide-gray-700">
                {groups.filter(a => a.isGlobal).map((g) => {
                    return (
                        <tr
                            key={g._id}
                            onClick={() => goToGroup(g._id)}
                            className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
                        >
                            <td className="px-2 py-2 text-md text-gray-200">{g.name}</td>
                            <td className="px-2 py-2 text-md text-gray-200">
                                {g.description}
                            </td>
                            <td className="px-2 py-2 text-md text-center text-gray-200">
                                {g.total_users}
                            </td>
                            {/* <td className="px-2 py-2 text-md text-center text-gray-200">
                             <button className="bg-blue-600 p-2 rounded-lg">
                             Somthing
                             </button>
                             </td> */}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>

    );
};
export default  Organisation_Receiver(Groups, (organisation) => {
  return {organisation};
});