import React, {useState, useEffect} from "react";
import dayjs from "dayjs";
import API from "../../../shared/API";
import Loader from "../../../shared/components/Loader";
import AlertMessage from "../../../shared/components/AlertMessage";

const DashboardAuditLogs = ({organisation}) => {
    const [isLoading, setLoading] = useState(true);
    const [logs, setLogs]         = useState([]);

    useEffect(() => {
        const getData = () => {
            API.org.dashboard.auditLogs(organisation.data._id).then(res => setLogs(res)).catch(err => {
                // TODO impairment this
            }).finally(() => {
                setLoading(false);
            })
        };
        const repeatingTask = setInterval(getData, 10000);
        getData();
        return () => {
            clearInterval(repeatingTask);
        }
    }, [organisation]);

    if(isLoading){
        return (
            <Loader>Loading audit logs...</Loader>
        );
    }

    return (
        <table className="min-w-full divide-y border border-gray-700 divide-gray-800">
            <thead>
                <tr className="bg-gray-900">
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                </th>
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                </th>
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Message
                </th>
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    System
                </th>
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                </th>
            </tr>
            </thead>
            <tbody className="bg-black divide-y divide-gray-200">
            {logs.length === 0 && (
                <tr>
                    <td colSpan={5} className={"p-1"}><AlertMessage.Info>There are no recent important audit logs to display.</AlertMessage.Info></td>
                </tr>
            )}
            {logs.map(item => {
                let typeColor = "";
                switch (item.type) {
                    case "info":
                        typeColor = "bg-blue-500";
                        break;
                    case "warning":
                        typeColor = "bg-orange-500";
                        break;
                    case "alert":
                        typeColor = "bg-red-500";
                        break;
                    default:
                        typeColor = "bg-grey-500";
                        break;
                }
                return (<tr key={item._id} className="text-gray-200">
                    <td className="px-2 py-4 text-xs">{dayjs(item.createdAt).fromNow()}</td>
                    <td className="px-2 py-4">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${typeColor} text-white`}>{item.type.toUpperCase()}</span>
                    </td>
                    <td className="px-2 py-4 text-xs tracking-wider">{item.message}</td>
                    <td className="px-2 py-4 text-xs">
                        {item.application !== undefined && item.application !== null && item.application.name !== undefined ? item.application.name : "[NO APPLICATION]"}
                    </td>
                    <td className="px-2 py-4 text-right text-xs">
                        {item.user !== undefined && item.user !== null ? ((item.user.fullName !== undefined && item.user.fullName !== null) ? item.user.fullName : item.user._id) : ""}
                    </td>
                </tr>)
            })}
            </tbody>
        </table>
    );
}
export default DashboardAuditLogs;