import React, { useState, useEffect, useRef } from "react";
import { Map, TileLayer, ZoomControl, LayersControl, GeoJSON, Marker } from "react-leaflet";
import UseWindowDimensions from "./UseWindowDimensions";
import Proj4 from "proj4leaflet";
import L from "leaflet";
import * as turf from "@turf/turf";
import { Transition, Dialog } from "@headlessui/react";
import { FaWindowClose } from "react-icons/fa";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

const crs = new Proj4.CRS(
  "EPSG:27700",
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999601 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.060,0.1502,0.2470,0.8421,-20.4894 +datum=OSGB36 +units=m +no_defs",
  {
    transformation: new L.Transformation(1, 238375, -1, 1376256),
    resolutions: [896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375],
  }
);

export default function MainMapOS({ mapCenter = [], addLoc, gridRef, showCCTV, cctv = [], showBase = false, deleteCCTV, onClick, minus = 0 }) {
  const [center, setCenter] = useState([mapCenter[1], mapCenter[0]]);
  const { height, width } = UseWindowDimensions();
  const [zoom, setZoom] = useState(12);
  const [newLoc, setNewLoc] = useState([]);
  const [cctvItem, setCCTVItem] = useState({
    properties: {},
  });
  const [delCCTV, setDelCCTV] = useState(false);
  const theMap = useRef(null);

  useEffect(() => {
    if (mapCenter) {
      setCenter([mapCenter[1], mapCenter[0]]);
    }
    if (cctv.length > 0) {
      setTimeout(() => {
        mapBounds();
      }, 300);
    }
  }, []);
  useEffect(() => {
    if (mapCenter) {
      setCenter([mapCenter[1], mapCenter[0]]);
    }
  }, [mapCenter]);
  useEffect(() => {
    if (cctv.length > 0) {
      setTimeout(() => {
        mapBounds();
      }, 300);
    }
  }, [cctv]);

  let cctvData = {
    type: "FeatureCollection",
    features: cctv && cctv.length ? cctv : [],
  };
  const createBounds = (bbox) => {
    return [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]],
    ];
  };
  const mapBounds = () => {
    if (cctvData && theMap.current && theMap.current.leafletElement) {
      if (cctvData.features && cctvData.features.length) {
        var bounds = turf.bbox(cctvData);
        theMap.current.leafletElement.fitBounds(createBounds(bounds), {
          maxZoom: 18,
        });
      }
    }
  };
  const MapLayersHolder = () => {
    return (
      <LayersControl position="bottomleft" collapsed={false}>
        <LayersControl.BaseLayer checked={!showBase} name="Hybrid">
          {/* <BaseLayer name="Hybrid"> */}
          <ReactLeafletGoogleLayer
            useGoogMapsLoader={false}
            // googleMapsLoaderConf={{
            //   KEY: "AIzaSyDP4JsKkKGbc1WZK7B1Dq2GJ0uOhH2MhqM",
            // }}
            type={"hybrid"}
          />
          {/* <TileLayer
            maxNativeZoom={9}
            maxZoom={11}
            attribution='&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>'
            url="https://tiles.sarstuff.net/maps/ordnance_survey/Leisure_27700/{z}/{x}_{y}.png"
          /> */}
        </LayersControl.BaseLayer>
        {/* <LayersControl.BaseLayer
          checked={showBase}
          name="OS Basemap"
        >
          <TileLayer
            maxZoom={13}
            attribution='&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>'
            url="https://tiles.sarstuff.net/maps/ordnance_survey/Outdoor_27700/{z}/{x}_{y}.png"
          />
        </LayersControl.BaseLayer> */}
      </LayersControl>
    );
  };

  const handleDelCCTV = (feature) => {
    setDelCCTV(true);
    setCCTVItem(feature);
  };
  const delTheCCTV = () => {
    deleteCCTV(cctvItem.properties._id);
    setDelCCTV(false);
    setCCTVItem({
      properties: {},
    });
  };
  const renderCCTVModal = () => {
    let showDel = true;
    return (
      <Transition
        show={delCCTV}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog open={delCCTV} onClose={() => setDelCCTV(false)} className="fixed inset-0  cctvmapmodal m-2">
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-4 overflow-hidden text-left  transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <button onClick={() => setDelCCTV(false)} className="p-2 text-gray-700 float-right">
                <FaWindowClose size={"1.6rem"} />
              </button>
              <div className="text-white text-2xl">
                <Dialog.Title>CCTV Details</Dialog.Title>
              </div>

              <div className="flex flex-col justify-between mt-4 border-t border-gray-700 pt-4">
                <div className="text-white text-xl p-2">{cctvItem.properties.locName}</div>
                <div className="text-white text-md p-2">W3W: {cctvItem.properties.w3w}</div>
                <div className="text-white text-md p-2">Note: {cctvItem.properties.note}</div>
                {showDel && (
                  <button onClick={delTheCCTV} className="p-2 text-red-500 rounded-md border border-red-800">
                    Delete
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  function onEachCCTVFeature(feature, layer) {
    if (feature.geometry.type === "Point") {
      if (feature.properties && feature.properties.type) {
        layer.bindTooltip(feature.properties.type, {
          permanent: true,
          direction: "bottom",
          offset: [-0, 10],
        });
      }
      layer.on({
        click: () => handleDelCCTV(feature),
      });
      layer.setIcon(svgIcon);
    } else {
      console.log("here");
    }
    return;
  }
  const MapClick = (e) => {
    setNewLoc([e.latlng.lat, e.latlng.lng]);
    onClick([e.latlng.lat, e.latlng.lng]);
    // gridRef([e.latlng.lat, e.latlng.lng]);
  };

  return (
    <div className={`h-full  w-full`}>
      {renderCCTVModal()}
      <Map
        ref={theMap}
        style={{ height: height - 300 }}
        center={center}
        zoom={zoom}
        // crs={crs}
        zoomControl={false}
        scrollWheelZoom={true}
        onClick={(e) => addLoc && MapClick(e)}
        onzoomend={() => setZoom(theMap.current.leafletElement.getZoom())}
        tap={false}
      >
        <ZoomControl position="bottomright" />
        {MapLayersHolder()}
        {showCCTV && cctv.length && <GeoJSON data={cctvData} onEachFeature={onEachCCTVFeature} />}
        {!showCCTV && center.length > 0 && <Marker position={center} />}
      </Map>
    </div>
  );
}
const greenIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const blueIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const redIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const yellowIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/marker-icon-2x-yellow.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const violetIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/marker-icon-2x-violet.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const blackIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/marker-icon-2x-black.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const cctvIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/cctv.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 31],
  iconAnchor: [10, 20],
  popupAnchor: [0, -15],
  shadowSize: [41, 31],
});
const orangeDotIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/sarstuff/leaflet-color-markers/master/img/orange_dot1.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 31],
  iconAnchor: [10, 20],
  popupAnchor: [0, -15],
  shadowSize: [41, 31],
});
const svgIcon = L.divIcon({
  html: `
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary" fill="darkorange" viewBox="0 0 24 24" stroke="black">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
</svg>`,
  className: "",
  iconSize: [10, 10],
  iconAnchor: [10, 10],
});
