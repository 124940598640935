import React, {useEffect, useState} from "react";
import {Dialog} from "@headlessui/react";
import toast from "react-hot-toast";
import API from "../../../../../../shared/API";
import usePrevious from "../../../../../../shared/hooks/usePrevious";
import Field_PhoneNumber from "../../../../../../shared/components/Field_PhoneNumber";
import Field_DropdownInput from "../../../../../../shared/components/Field_DropdownInput";
import Field_TextInput from "../../../../../../shared/components/Field_TextInput";

const initialFormValues = {
  title: "",
  isShared: true,
  isInbound: true,
  phoneNumber: ""
};

const PhoneNumber_FormPopup = ({isEdit = false, onClose, phoneNumber, gateway}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError]   = useState(null);
  const [errors, setErrors]             = useState({});
  const [data, setData]                 = useState({...initialFormValues});
  const lastData                        = usePrevious(data);

  useEffect(() => {
    if (isEdit){
      setData(phoneNumber);
    }
  }, [isEdit, phoneNumber]);
  useEffect(() => {
    setErrors(handleFormValidation());
  }, [data]);
  useEffect(() => {
    if (lastData === undefined) return;

    if (lastData.isInbound !== data.isInbound){
      setData({
        ...data,
        phoneNumber: ""
      })
    }
  }, [data, lastData]);

  const handleFormValidation = () => {
    const errors = {};

    if (!data.title) errors.title = "Can't be empty";
    if (!data.phoneNumber) errors.phoneNumber = "Can't be empty";
    if (!data.isInbound){
      if (data.phoneNumber.length < 3){
        errors.phoneNumber = "Sender name too short";
      }
      if (data.phoneNumber.length > 11){
        errors.phoneNumber = "Sender name too long";
      }
    }

    return errors;
  };

  const handleSubmit = () => {
    if (Object.keys(errors).length === 0){
      setIsSubmitting(true);
      setSubmitError(null);

      const apiHandler = isEdit ? API.management.sms.gateways.phoneNumbers.update(gateway._id, data._id, data) : API.management.sms.gateways.phoneNumbers.create(gateway._id, data);

      apiHandler.then(() => {
        if (isEdit){
          toast.success("Successfully updated the Phone Number", {
            duration: 5000,
          });
        } else {
          toast.success("Successfully created the new Phone Number", {
            duration: 5000,
          });
        }
        setTimeout(() => {
          onClose()
        }, 100);
      }).catch(error => {
        let errorMessage = "There was an unexpected error while handling the request";

        if (error.isAxiosError !== undefined){
          if(error.response !== undefined){
            errorMessage = error.response.data.message;
          } else {
            errorMessage = error.message;
          }
        }

        setSubmitError(errorMessage);
      }).finally(() => {
        setIsSubmitting(false)
      })
    }
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        // Do nothing
      }}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="text-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
        <div className="border-4  border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
          <div className="text-white text-2xl border-b border-gray-700 p-2">
            <Dialog.Title>{isEdit ? "Edit" : "Create"} Phone Number</Dialog.Title>
          </div>
          {submitError && (<div className={"p-2 bg-red-700 border border-white rounded text-white"}>{submitError}</div>)}
          {isSubmitting && <div className={"p-2 bg-orange-700 border border-white rounded text-white"}>Submitting form data...</div>}
          <>
            <div className="text-md p-2">
              <div className="flex gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <Field_TextInput
                    labelClassName={"text-gray-400"}
                    title={"Title"}
                    name={"title"}
                    value={data.title}
                    onChange={val => setData({...data, title: val})}
                    isDisabled={isSubmitting}
                    error={errors.title}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <Field_DropdownInput
                    labelClassName={"text-gray-400"}
                    title={"Is Shared"}
                    name={"isShared"}
                    value={data.isShared ? "true" : "false"}
                    options={[
                      {text: "Yes", value: "true", id: 1},
                      {text: "No", value: "false", id: 0}
                    ]}
                    onChange={val => setData({...data, isShared: (val === "true")})}
                    isDisabled={isSubmitting}
                    error={errors.isShared}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <Field_DropdownInput
                    labelClassName={"text-gray-400"}
                    title={"Is Inbound"}
                    name={"isInbound"}
                    value={data.isInbound ? "true" : "false"}
                    options={[
                      {text: "Yes", value: "true", id: 1},
                      {text: "No", value: "false", id: 0}
                    ]}
                    onChange={val => setData({...data, isInbound: (val === "true")})}
                    isDisabled={isSubmitting}
                    error={errors.isInbound}
                  />
                </div>
              </div>
              <div className="pt-2 flex gap-4">
                <div className="flex flex-col gap-2 w-full">
                  {data.isInbound ? <Field_PhoneNumber
                    labelClassName={"text-gray-400"}
                    title={"Phone Number"}
                    name={"phoneNumber"}
                    value={data.phoneNumber}
                    onChange={val => setData({...data, phoneNumber: val})}
                    isDisabled={isSubmitting}
                    error={errors.phoneNumber}
                  /> : <Field_TextInput
                    labelClassName={"text-gray-400"}
                    title={"Sender Name"}
                    name={"phoneNumber"}
                    value={data.phoneNumber}
                    onChange={val => setData({...data, phoneNumber: val})}
                    isDisabled={isSubmitting}
                    error={errors.phoneNumber}
                  />}
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
              <button className="py-2 px-4 bg-gray-500 text-white rounded-lg" disabled={isSubmitting} onClick={() => onClose()}>Cancel</button>
              <button className="py-2 px-4 bg-green-500 text-white rounded-lg" disabled={isSubmitting} onClick={handleSubmit}>{isEdit ? "Save" : "Create"}</button>
            </div>
          </>
        </div>
      </div>
    </Dialog>
  );
}
export default PhoneNumber_FormPopup;