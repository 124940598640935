import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Organisation_Receiver } from "../../../context/organisation";
import ReturnHeadingBar from "../../../shared/components/ReturnHeadingBar";
import UserAuditLog from "./_components/userAuditLogs";
import UserProfile from "./_components/userProfile";
import API from "../../../shared/API";
import Loader from "../../../shared/components/Loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function UserPage({ organisation, history }) {
  let { userid } = useParams();
  const [selected, setSelected] = useState("Details");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [delCheck, setDelCheck] = useState(false);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    Promise.all([API.org.users.show(organisation.data._id, userid)])
      .then((res) => {
        setUser(res[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loader>Loading User...</Loader>;
  }

  const tabs = [{ name: "Details" }, { name: "Audit Log" }];
  return (
    <div>
      <ReturnHeadingBar
        title={user.fullName}
        linkTo={`${organisation._urlPrefix}/team`}
      />

      <div className="hidden lg:block">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => setSelected(tab.name)}
                className={classNames(
                  selected === tab.name
                    ? "border-amber-500 text-amber-600"
                    : "border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500",
                  "whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
                )}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className={"px-2"}>
        {selected === "Details" && (
          <UserProfile
            getData={getData}
            user={user}
            organisation={organisation}
          />
        )}
        {selected === "Audit Log" && (
          <UserAuditLog userId={userid} organisation={organisation} />
        )}
      </div>
    </div>
  );
}

export default Organisation_Receiver(UserPage, (organisation) => {
  return { organisation };
});
