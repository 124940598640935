import React, {useEffect, useState} from "react";
import {Dialog} from "@headlessui/react";
import toast from "react-hot-toast";
import Field_TextInput from "../../../../../../shared/components/Field_TextInput";
import API from "../../../../../../shared/API";
import Loader from "../../../../../../shared/components/Loader";

const Gateway_ApiKey = ({gateway, gatewayReload}) => {
  const [showForm, setShowForm] = useState(false);

  return (<>
      {showForm && <ApiKey_Form gateway={gateway} onClose={() => {
        gatewayReload();
        setShowForm(false);
      }}/>}
      <button onClick={() => setShowForm(true)} className={`text-sm bg-gray-800 py-1 px-4 rounded-xl border-gray-700 border hover:bg-gray-900`}>View/Change</button>
    </>
  );
}

const ApiKey_Form = ({gateway, onClose}) => {
  const [isLoading, setIsLoading]       = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError]   = useState(null);
  const [errors, setErrors]             = useState({});
  const [data, setData]                 = useState({
    apiKey: ""
  });

  useEffect(() => {
    getData();
  }, [gateway._id]);
  useEffect(() => {
    setErrors(handleFormValidation());
  }, [data]);

  const getData = () => {
    API.management.sms.gateways.apiKey.retrieve(gateway._id).then(res => {
      setData({
        apiKey: res.apiKey
      });
      setIsLoading(false);
    });
  };

  const handleFormValidation = () => {
    const errors = {};

    if(gateway.provider === "TextLocal"){
      if (!data.apiKey) errors.apiKey = "Can't be empty";
    } else {
      const keySplit = data.apiKey.split(":");
      const keyAccountId = keySplit[0] || "";
      const keyId = keySplit[1] || "";
      const keySecret = keySplit[2] || "";

      if (!keyAccountId) errors.keyAccountId = "Can't be empty";
      if (!keyId) errors.keyId = "Can't be empty";
      if (!keySecret) errors.keySecret = "Can't be empty";
    }

    return errors;
  };

  const handleSubmit = () => {
    if (Object.keys(errors).length === 0){
      setIsSubmitting(true);
      setSubmitError(null);

      API.management.sms.gateways.apiKey.update(gateway._id, data.apiKey).then(() => {
        toast.success("Successfully updated the API Key", {
          duration: 5000,
        });
        setTimeout(() => {
          onClose()
        }, 100);
      }).catch(error => {
        let errorMessage = "There was an unexpected error while handling the request";

        if (error.isAxiosError !== undefined){
          if (error.response !== undefined){
            errorMessage = error.response.data.message;
          } else {
            errorMessage = error.message;
          }
        }

        setSubmitError(errorMessage);
      }).finally(() => {
        setIsSubmitting(false)
      });
    }

  };

  console.log("data", data);

  const renderProviderFields = () => {
    if(gateway.provider === "TextLocal"){
      return (
        <div className="text-md p-2">
          <div className="flex gap-4">
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                labelClassName={"text-gray-400"}
                title={"API Key"}
                name={"apiKey"}
                value={data.apiKey}
                onChange={val => setData({...data, apiKey: val})}
                isDisabled={isSubmitting}
                error={errors.apiKey}
              />
            </div>
          </div>
        </div>
      )
    }
    if(gateway.provider === "Twilio"){
      const keySplit = data.apiKey.split(":");
      const keyAccountId = keySplit[0] || "";
      const keyId = keySplit[1] || "";
      const keySecret = keySplit[2] || "";

      const updateHandle = (type, val) => {
        if(type === "keyAccountId"){
          setData({...data, apiKey: val + ":" + keyId + ":" + keySecret});
        }
        if(type === "keyId"){
          setData({...data, apiKey: keyAccountId + ":" + val + ":" + keySecret});
        }
        if(type === "keySecret"){
          setData({...data, apiKey: keyAccountId + ":" + keyId + ":" + val});
        }
      }

      return (<>
        <div className="text-md p-2">
          <div className="flex gap-4">
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                labelClassName={"text-gray-400"}
                title={"Account ID"}
                name={"keyAccountId"}
                value={keyAccountId}
                onChange={val => updateHandle("keyAccountId", val)}
                isDisabled={isSubmitting}
                error={errors.keyAccountId}
              />
            </div>
          </div>
        </div>
        <div className="text-md p-2">
          <div className="flex gap-4">
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                labelClassName={"text-gray-400"}
                title={"Secret ID"}
                name={"keyId"}
                value={keyId}
                onChange={val => updateHandle("keyId", val)}
                isDisabled={isSubmitting}
                error={errors.keyId}
              />
            </div>
          </div>
        </div>
        <div className="text-md p-2">
          <div className="flex gap-4">
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                labelClassName={"text-gray-400"}
                title={"Secret Key"}
                name={"keySecret"}
                value={keySecret}
                onChange={val => updateHandle("keySecret", val)}
                isDisabled={isSubmitting}
                error={errors.keySecret}
              />
            </div>
          </div>
        </div>
      </>)
    }

    return (
      <div className={"text-white"}>
        There are no forms configured to handle the provider: {gateway.provider}
      </div>
    );
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        // Do nothing
      }}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="text-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
        <div className="border-4  border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
          <div className="text-white text-2xl border-b border-gray-700 p-2">
            <Dialog.Title>Manage API Key</Dialog.Title>
          </div>
          {submitError && (<div className={"p-2 bg-red-700 border border-white rounded text-white"}>{submitError}</div>)}
          {isSubmitting && <div className={"p-2 bg-orange-700 border border-white rounded text-white"}>Submitting form data...</div>}

          {isLoading ? <div className={"text-white"}><Loader>Retrieving API Key...</Loader></div> : renderProviderFields()}

          <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
            <button className="py-2 px-4 bg-gray-500 text-white rounded-lg" disabled={isSubmitting} onClick={() => onClose()}>Cancel</button>
            <button className="py-2 px-4 bg-green-500 text-white rounded-lg" disabled={isSubmitting || isLoading} onClick={handleSubmit}>Save</button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Gateway_ApiKey;