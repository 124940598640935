import ReturnHeadingBar from "../../../../shared/components/ReturnHeadingBar";
import React, {useState, useEffect, Fragment} from "react";
import {useParams} from "react-router-dom";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";
import * as dayjs from "dayjs";
import RedirectForm from "./_components/redirectForm";
import AlertMessage from "../../../../shared/components/AlertMessage";
import {FaPencilAlt, FaTrash} from "react-icons/fa";
import Confirm from "../../../../shared/components/Confirm";
import ApplicationForm from "./_components/applicationForm";


const ManageApplication = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication]     = useState({});
  const [callbacks, setCallbacks] = useState([]);
  const [logs, setLogs] = useState([]);
  const [editRedirect, setEditRedirect] = useState({});
  const [removeRedirectId, setRemoveRedirectId] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);

  const {application_id} = useParams();

  useEffect(() => {
    getData();
  }, [application_id]);

  const getData = () => {
    Promise.all([
      API.applications.show(application_id),
      API.applications.callbacks.list(application_id)
    ]).then(res => {
      setApplication(res[0]);
      setCallbacks(res[1]);
      setIsLoading(false);
    })

    API.applications.logs(application_id).then((res) => {
      setLogs(res);
    })
  };

  const handleUpdateApplication = (data) => {
    return API.applications.update(application_id, data).then(() => {
      getData();
      setShowEditForm(false);
    });
  }
  const onRedirectCreate = (data) => {
    return API.applications.callbacks.add(application_id, data.callback).then(() => {
      getData();
    });
  };
  const onRedirectUpdate = (id, data) => {
    return API.applications.callbacks.edit(application_id, id, data.callback).then(() => {
      getData();
    });
  };
  const onRedirectDelete = () => {
    return API.applications.callbacks.remove(application_id, removeRedirectId).then(() => {
      getData();
      setEditRedirect({});
      setRemoveRedirectId("");
    });
  };

  if (isLoading){
    return (
      <Loader>Loading Application...</Loader>
    );
  }

  return (
    <div>
      <ReturnHeadingBar
        title={application.name}
        linkTo={`/applications`}
      />
      <div className={"px-2"}>
        <div className={"mt-2 w-full flex flex-col sm:flex-row gap-4"}>
          <div className="w-full sm:w-2/3">
            {application.is_central && <AlertMessage.Info className={"text-center"}>### Central Application ###</AlertMessage.Info>}

            <div>
              <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
                Application Details
                <button onClick={() => setShowEditForm(true)} className="text-sm bg-gray-800 py-1 px-4 rounded-xl border-gray-700 border hover:bg-gray-900">Edit</button>
              </div>
              {showEditForm ? (
                  <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around">
                    <ApplicationForm
                      application={application}
                      onSubmit={handleUpdateApplication}
                      onCancel={() => setShowEditForm(false)}
                    />
                  </div>
              ) : (
                <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
                  <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                    Name: <span className="text-primary">{application.name}</span>
                  </div>
                  <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                    System: <span className="text-primary">{application.system?.name || "[NOT SET]"}</span>
                  </div>
                  <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                    Token TTL [Seconds]: <span className="text-primary">{application.jwt_expiration}</span>
                  </div>
                  <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                    Password Grant Type: <span className="text-primary">{application.is_password_grant_allowed ? "Enabled" : "Disabled"}</span>
                  </div>
                  <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                    JWT Signature: <span className="text-primary">{application.sign_with_secret ? "Own Secret" : "RSA 256"}</span>
                  </div>
                </div>
              )}
            </div>

            <div className={"mt-2"}>
              <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
                Config
              </div>
              <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
                <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                  ClientID: <input className="p-2 bg-gray-800 text-xs rounded-xl border border-gray-700 w-1/3 text-right" value={application._id} disabled={true}/>
                </div>
                <div className="p-2 flex justify-between text-xl">
                  Client Secret: <input className="p-2 bg-gray-800 text-xs rounded-xl border border-gray-700 w-3/4 text-right" value={application.app_secret} disabled={true}/>
                </div>
              </div>
            </div>

            <div className={"mt-2"}>
              <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
                Redirect Whitelist
              </div>
              <div className="px-4 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
                <Confirm
                  open={removeRedirectId !== ""}
                  onCancel={() => setRemoveRedirectId("")}
                  onConfirm={() => onRedirectDelete()}
                  title={"Remove redirect URL?"}
                  message={"Are you sure you want to remove this redirect URL"}
                />

                <RedirectForm
                  editRecipient={editRedirect}
                  onCreate={onRedirectCreate}
                  onUpdate={onRedirectUpdate}
                  onComplete={() => setEditRedirect({})}
                  onCancelEdit={() => setEditRedirect({})}
                />

                {callbacks.length === 0 ? (
                  <AlertMessage.Info className={"mx-0"}>There are no stored recipients, please add one to start receiving email notifications.</AlertMessage.Info>
                  ) : (
                  <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700 mt-4">
                    <tbody className="bg-black divide-y divide-gray-700">{callbacks.map((recipient) => {
                      return (
                        <tr key={recipient._id}>
                          <td className="p-2 items-center divide-x-2 divide-gray-700  hover:bg-gray-900">
                            {recipient.callback}
                          </td>
                          <td className="p-2 w-14 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                            <div className="flex gap-4 text-xl">
                              <button onClick={() => setEditRedirect(recipient)} className="text-blue-500 text-xl"><FaPencilAlt/></button>
                              <button onClick={() => setRemoveRedirectId(recipient._id)} className="text-red-500 text-xl"><FaTrash/></button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}</tbody>
                  </table>
                )}
              </div>
            </div>

          </div>
          <div className="w-full sm:w-1/3">
            <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
              Logs
            </div>
            <div className="px-1 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
              <table className="min-w-full divide-y divide-gray-800">
                <thead>
                <tr className="bg-gray-900">
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                    Level
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                  </th>
                </tr>
                </thead>
                <tbody className="bg-black divide-y divide-gray-200">
                {logs.map((log, index) => {
                  let typeColor = "";
                  switch (log.type.toLowerCase()) {
                    case "info":
                      typeColor = "bg-blue-500";
                      break;
                    case "warning":
                      typeColor = "bg-orange-500";
                      break;
                    case "alert":
                      typeColor = "bg-red-500";
                      break;
                    default:
                      typeColor = "bg-purple-500";
                      break;
                  }

                  return (
                    <Fragment key={index}>
                        <tr className="text-gray-200">
                          <td className="px-2 py-4">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${typeColor} text-white`}>{log.type.toUpperCase()}</span>
                          </td>
                          <td className="px-2 py-4 text-xs">{dayjs(log.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
                          <td className="px-2 py-4 text-xs">{log.user?.fullName}</td>
                        </tr>
                        <tr className="text-gray-200">
                          <td colSpan={3} className="px-2 py-4 text-xs tracking-wider">
                            {/*<span className={`px-2 mr-4 inline-flex text-xs leading-5 font-semibold rounded-full ${typeColor} text-white`}>{log.type.toUpperCase()}</span>*/}
                            {log.message}
                          </td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
export default ManageApplication;