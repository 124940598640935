import React, {useEffect, useMemo, useState} from "react";
import Loader from "../../../../shared/components/Loader";
import API from "../../../../shared/API";

const Application = ({ history }) => {
  // const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [query, setQuery] = useState("");

  const filtered = useMemo(() => {
    return applications.filter(a => {
      if(query === "") return a;
      return a.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }, [applications, query])

  useEffect(() => {
    API.applications.all().then(res => {
      setApplications(res.toSorted((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        return 0;
      }));
      setIsLoading(false);
    });
  }, []);

  const goTo = (id) => history.push(`/applications/manage/${id}`);
  const goToCreate = () => history.push(`/applications/create`);


  return (
    <>
      <div className="p-2">
        <div className="p-2 flex gap-4 justify-between border border-gray-700 rounded-xl my-2">
          <div className="w-full flex flex-col sm:flex-row gap-4">
          {/*  <button onClick={filterActive} className={`${isActive ? "bg-green-600" : "bg-blue-600"} p-2 rounded-md`}>Active</button>*/}
          {/*  <button onClick={filterDisabled} className={`${!isActive ? "bg-green-600" : "bg-blue-600"} p-2 rounded-md`}>Archived</button>*/}
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-4">
            <button onClick={goToCreate} className={`bg-green-600 p-2 rounded-md`}>Create</button>
            <input
              autoComplete="off"
              className="bg-gray-800 rounded-md p-2 w-full"
              name="filter"
              placeholder="search..."
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="rounded-md border border-gray-700">
          {isLoading ? (
            <Loader/>
          ) : (
            <table className="rounded-md min-w-full divide-y divide-x divide-gray-700">
              <thead>
                <tr className="rounded-md bg-gray-900">
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-2 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  JWT TTL
                </th>
              </tr>
              </thead>
              <tbody className="bg-black divide-y divide-gray-700">
              {filtered.map((o) => {
                return (
                  <tr key={o._id} onClick={() => goTo(o._id)} className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900">
                    <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">
                      {/*{o.system_settings.is_account_disabled && <span className={"mr-2 py-1 px-2 rounded bg-red-600 text-white"}>DISABLED</span>}*/}
                      {/*{o.system_settings.hide_account && <span className={"mr-2 py-1 px-2 rounded bg-orange-600 text-white"}>HIDDEN</span>}*/}
                      {o.name}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-200 whitespace-nowrap">
                      {o.system?.name}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-200">
                      {o.jwt_expiration}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
export default Application;