import React from 'react';
import { useParams } from "react-router-dom";
import OrganisationsIndex from "../../../organisation";

const ManageOrganisation = props => {
    const { organisation_id } = useParams();
    return (
        <OrganisationsIndex
            match={props.match}
            history={props.history}
            organisationId={organisation_id}
            isSarStuff
            urlPrefix={"/organisations/manage/" + organisation_id}
        />
    );
}
export default ManageOrganisation;