import CentralHeader from "../../../../shared/components/CentralHeader";

const Telephone = () => {
  return (
    <>
      <CentralHeader />
      <div className="p-2">
        Telephone Service
      </div>
    </>
  );
}
export default Telephone;