import React, { Component } from "react";

export default class SarStuffErrorPage extends Component {
  render() {
    return (
      <div className="text-center text-xl">
        <h1>SarStuff 404!</h1>
      </div>
    );
  }
}
