import React, {useEffect, useState} from "react";
import {Organisation_Receiver} from "../../../../../context/organisation";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";
import API from "../../../../../shared/API";
import Loader from "../../../../../shared/components/Loader";
import Select from "../../../../../shared/components/Select";
import FieldSSOApplications from "./_fieldSSOApplication";

const TextingConfiguration = ({organisation}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [config, setConfig] = useState({
    // defaultPhoneNumber: "",
    // organisationId: "",
    // costPerCredit: 0.10,
    isEnabled: false,
    server: ""
  });
  const [orgConfig, setOrgConfig] = useState("");


  const getData = () => {
    API.org.settings.systems.getConfig(organisation.data._id, "sms").then(res => {
      setConfig(res);
      setOrgConfig(JSON.stringify(res));
    }).finally(() => {
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (JSON.stringify(config) !== orgConfig) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [config, orgConfig]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSubmitError("");

    API.org.settings.systems.setConfig(organisation.data._id, "sms", config).then(() => {
      getData();
    }).catch(err => {
      setSubmitError(err.response.data.message);
    }).finally(() => {
      setIsSubmitting(false);
    });
  }
  const onChangeHandler = e => {
    setConfig({...config, [e.target.name]: e.target.value});
  }

  if (isLoading) {
    return (
      <Loader>Getting SMS Config...</Loader>
    );
  }

  return (
    <div className={`border ${isModified ? "border-yellow-800" : "border-green-800"} rounded-xl p-2`}>
      <h1 className={"text-xl"}>SMS Server</h1>
      <CheckboxSlider title={"Use New Server"} onChange={a => setConfig({...config, isEnabled: a})} isChecked={config.isEnabled} />
      {config.isEnabled && <>
        <FieldSSOApplications
          systemType={"sms"}
          disabled={isSubmitting}
          application={config.application}
          endpoint={config.endpoint}
          onChange={data => {
            setConfig({
              ...config,
              application: data.application,
              endpoint: data.endpoint
            })
          }} />
      </>}

      {isModified && <button disabled={isSubmitting} className="bg-green-600 text-white rounded-xl p-2 mt-4 float-right" onClick={() => handleSubmit()}>Save Changes</button>}
      <div className={"clear-both"}>{""}</div>
    </div>
  );
}
export default Organisation_Receiver(TextingConfiguration, (organisation) => {
  return {organisation};
});