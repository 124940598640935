import React, { useState, useEffect } from "react";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { Organisation_Receiver } from "../../../context/organisation";
import MainMapOS from "../../../shared/components/mapping/MainMap";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import API from "../../../shared/API";

const Index = ({ organisation, history }) => {
  const [center, setCenter] = useState(organisation.defaultMapCenter !== undefined ? organisation.defaultMapCenter : []);
  const [cctvs, setCctvs] = useState([]);
  const [showCCTV, setShowCCTV] = useState(false);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    API.org.cctv
      .getAll(organisation._id)
      .then((res) => {
        setCctvs(res);
        setShowCCTV(true);
      })
      .catch((err) => {});
  };
  const deleteCCTV = (id) => {
    setShowCCTV(false);
    API.org.cctv
      .remove(organisation._id, id)
      .then(() => {
        getData();
      })
      .catch((err) => {})
      .finally(() => {
        setTimeout(() => {
          setShowCCTV(true);
        }, 500);
      });
  };
  const subTitle = "Manage the CCTV locations your team members have added via the SarSYS mobile app.";
  return (
    <div className=" ">
      <OrganisationPageHeader title={"CCTV Map"} subTitle={subTitle} subIcon={<LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />} />
      <div className="h-full w-full">
        {center && center.length === 2 ? (
          <>
            <div className={"m-4 p-2 bg-blue-700"}>
              NOTICE: CCTV management is on the move... you can begin to manage your CCTV location directly within SarSYS via the CCTV icon located in the bottom management navigation bar.
            </div>
            <MainMapOS
              mapCenter={center}
              cctv={cctvs}
              // showBase
              showCCTV={showCCTV}
              deleteCCTV={deleteCCTV}
            />
          </>
        ) : (
          <div className={"m-4 p-2 bg-red-700"}>
            Your organisation profile currently does not have a defined map center location. Please set this location within settings before continuing.
          </div>
        )}
      </div>
    </div>
  );
};

export default Organisation_Receiver(Index, (organisation) => {
  return { organisation: organisation.data };
});
