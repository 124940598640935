import React, { useState } from "react";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { Organisation_Receiver } from "../../../context/organisation";
import CentralHeader from "../../../shared/components/CentralHeader";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import LocationAndMapping from "./locationAndMapping";
import CiemSettings from "./ciemSettings";
import EntraID from "./entra_id";
import Systems from "./systems";
import RenderSettings from "./SettingsPage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SettingsIndex = ({ organisation }) => {
  const org = organisation.data;
  const [selected, setSelected] = useState("Location and Map");

  const tabs = [
    {
      name: "Location and Map",
      scope: "organisation:update",
      render: LocationAndMapping,
    },
    {
      name: "CiEM",
      scope: "organisation:update",
      render: CiemSettings
    },
    {
      name: "Microsoft Entra ID",
      // scope: "organisation:update",
      scope: "sarstuff:global:admins", // Temp only SarStuff
      render: EntraID
    },
    {
      name: "Settings",
      scope: "sarstuff:global:admins",
      render: RenderSettings,
    },
    {
      name: "Systems",
      scope: "sarstuff:global:admins",
      render: Systems,
    },
  ];
  return (
    <div>
      <CentralHeader
        title={org.name}
        subTitle={org && org.country + ": " + org.counties.join(", ")}
        subIcon={
          <LocationMarkerIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        }
        image={org.logoImage}
      />
      <div className="p-2 w-full">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            {tabs
              .filter((a) => {
                return Authentication.can(a.scope);
              })
              .map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => setSelected(tab.name)}
                  className={classNames(
                    selected === tab.name
                      ? "border-amber-500 text-amber-600"
                      : "border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500",
                    "whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
                  )}
                >
                  {tab.name}
                </div>
              ))}
          </nav>
        </div>
        <div>
          {tabs
            .filter((a) => Authentication.can(a.scope))
            .filter((a) => selected === a.name)
            .map((tab, index) => {
              return <tab.render key={index} organisation={organisation} />;
            })}
        </div>
      </div>
    </div>
  );
};
export default Organisation_Receiver(SettingsIndex, (organisation) => {
  return { organisation };
});
