import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ReturnHeadingBar from "../../../../../shared/components/ReturnHeadingBar";
import Gateway_PhoneNumberList from "./_components/Gateway_PhoneNumberList";
import API from "../../../../../shared/API";
import Loader from "../../../../../shared/components/Loader";
import Gateway_ApiKey from "./_components/Gateway_ApiKey";
import AlertMessage from "../../../../../shared/components/AlertMessage";

const SingleGateway = ({history}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [gateway, setGateway]     = useState({});

  const {gateway_id} = useParams();

  useEffect(() => {
    getData();
  }, [gateway_id]);

  const getData = () => {
    API.management.sms.gateways.getAll().then(res => {
      const theGateway = res.filter(a => a._id === gateway_id);
      setGateway(theGateway[0] ? theGateway[0] : {});
      setIsLoading(false);
    })
  }

  if (isLoading){
    return (
      <Loader>Loading Gateway...</Loader>
    );
  }

  return (
    <div>
      <ReturnHeadingBar
        title={gateway.title}
        linkTo={`/sms/gateways`}
      />
      <div className={"px-2"}>
        {gateway.isDisabled && <AlertMessage.Error className={"text-center"}>### Gateway is currently disabled ###</AlertMessage.Error>}
        <div className={"mt-2 w-full flex flex-col sm:flex-row gap-4"}>
          <div className="w-full sm:w-2/3">
            <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
              Gateway Details
              <button onClick={() => {
                alert("todo: add edit gateway")
              }} className="text-sm bg-gray-800 py-1 px-4 rounded-xl border-gray-700 border hover:bg-gray-900">Edit</button>
            </div>
            <div className="px-6 pb-2 pt-2 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
              <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                Title: <span className="text-primary">{gateway.title}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                Provider: <span className="text-primary"> {gateway.provider}</span>
              </div>
              <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                Is SarStuff: <span className="text-primary">{gateway.isSarStuff ? "Yes" : "No"}</span>
              </div>
              {gateway.organisation && <div className="p-2 flex justify-between text-xl border-b border-gray-700">
                Organisation: <span className="text-primary">{gateway.organisation}</span>
              </div>}
              <div className="p-2 flex justify-between border-b border-gray-700">
                <span className={"text-xl"}>Is Enabled:</span> {gateway.isDisabled ? <>
                ### Disabled ###
              </> : <>
                Enabled
              </>}
              </div>
              <div className="p-2 flex justify-between">
                <span className={"text-xl"}>API Key:</span> <Gateway_ApiKey gateway={gateway} gatewayReload={() => {
                getData();
              }}/>
              </div>
            </div>

          </div>
          <div className="w-full sm:w-1/3">
            <Gateway_PhoneNumberList gateway={gateway}/>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SingleGateway;