import React, {useCallback, useEffect, useState} from "react";
import Toast from "react-hot-toast";
import NotifyEmails from "../_components/notify_emails";
import API from "../../../shared/API";

const CiemSettings = ({organisation}) => {
  const [emailRecipients, setEmailRecipients] = useState([]);

  const getEmailList = useCallback(() => {
    API.org.settings.ciem.notify_email.getAll(organisation.data._id).then((res) => {
      setEmailRecipients(res);
    }).catch((err) => {
      Toast.error("Unable to retrieve list of email addresses at this time")
      //TODO
    });
  }, [organisation]);

  useEffect(() => {
    organisation._refresh();
  }, []);
  useEffect(() => {
    getEmailList();
  }, [getEmailList]);

  const handleEmailCreate = data => {
    return API.org.settings.ciem.notify_email.create(organisation.data._id, data).finally(() => {
      getEmailList();
    })
  };
  const handleEmailUpdate = (id, data) => {
    return API.org.settings.ciem.notify_email.update(organisation.data._id, id, data).finally(() => {
      getEmailList();
    });
  };
  const handleEmailDelete = id => {
    return API.org.settings.ciem.notify_email.remove(organisation.data._id, id).finally(() => {
      getEmailList();
    });
  };

  return (
    <div className="w-full">
      <div className="mt-2 p-2 border border-gray-700 rounded-lg">
        <div className="text-center text-xl p-4">Notification Recipients [Email]</div>
        <div className={"text-center text-sm"}>When a new TRiM request is sent from SarSYS, the following will receive a notification.</div>
        <NotifyEmails recipients={emailRecipients} onCreate={handleEmailCreate} onUpdate={handleEmailUpdate} onDelete={handleEmailDelete} />
      </div>
    </div>
  );
};
export default CiemSettings;