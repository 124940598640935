import React from "react";
import {Dialog} from "@headlessui/react";
import API from "../../../../shared/API";

const DeleteUserPopup = ({organisation, user, onClose}) => {

    const handleUnlockUser = () => {
        API.org.users.updateStatus(organisation.data._id, user._id, {
            isLockedOut: false
        }).then(() => {
            onClose();
        }).catch(err => {

        });
    };

    return (
        <Dialog
            open={true}
            onClose={() => {
                // Do nothing
            }}
            className="fixed z-10 inset-0 overflow-y-auto"
        >
            <div className="text-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
                <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-4 my-2 overflow-hidden text-left align-middle transition-all transform bg-gray-900 shadow-xl rounded-2xl">
                    <div className="text-white text-2xl border-b border-gray-700 p-2">
                        <Dialog.Title>Unlock {user.fullName}'s account</Dialog.Title>
                    </div>
                    <div className="text-md p-2 text-white">
                        Are you sure you want to unlock this account?<br/><br/>
                        Only do this if you know the user is actively trying to login and these failed login attempts are not from a robot/attacker.
                    </div>
                    <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                        <button className="py-2 px-4 bg-gray-500 text-white rounded-lg" onClick={() => onClose()}>Cancel</button>
                        <button className="py-2 px-4 bg-green-500 text-white rounded-lg" onClick={handleUnlockUser}>I confirm I want to unlock this user</button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default DeleteUserPopup;