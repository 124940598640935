import React from "react";
import Logo from "../../assets/images/central.png";

export default function CentralHeader({
  title = "SarStuff Central",
  subTitle,
  subIcon,
  showImage = true,
  image = Logo,
  button,
}) {
  return (
    <div className="bg-gray-900 border-b pb-1 border-gray-500  shadow">
      <div className="px-4 sm:px-6 w-full lg:mx-auto lg:px-8">
        <div className="py-2 md:flex md:items-center md:justify-between ">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <div>
                <div className="flex items-center">
                  <h1 className="tracking-widest ml-3 text-2xl font-bold leading-7 text-gray-200 sm:leading-9 sm:truncate">
                    {title}
                  </h1>
                </div>
                <dl className="h-6 mt-6 flex flex-col sm:ml-3 sm:mt-0 sm:flex-row sm:flex-wrap">
                  <dd className="flex items-center text-sm text-gray-500 font-medium  sm:mr-6">
                    {subIcon}
                    {subTitle}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="flex space-x-3 h-20 items-center">
            {button && button}
            {showImage && <img src={image} className="h-16" />}
          </div>
        </div>
      </div>
    </div>
  );
}
