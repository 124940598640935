import React, {useEffect, useState, useMemo} from "react";
import {Organisation_Receiver} from "../../../../../context/organisation";
import Select from "../../../../../shared/components/Select";
import API from "../../../../../shared/API";

const FieldSSOApplicationsAndEndpoint = ({organisation, systemType, application, endpoint, onChange, disabled, flavour = null}) => {
  const [ssoApplications, setSsoApplications] = useState([]);
  const pickableEndpoints = useMemo(() => {
    const app = ssoApplications.filter(a => a._id === application);
    return app[0] !== undefined ? app[0].allowed_callbacks : [];
  }, [ssoApplications, application]);

  useEffect(() => {
    API.org.settings.systems.getSSOApplications(organisation.data._id, systemType).then(res => {
      setSsoApplications(res.filter(a => a.name.toUpperCase().indexOf("APP") === -1));
    });
  }, [organisation]);

  const handleApplicationChange = e => {
    onChange({
      application: e.target.value,
      endpoint: ""
    });
  };
  const handleEndpointChange    = e => {
    onChange({
      application,
      endpoint: e.target.value
    });
  };

  return (
    <>
      <Select
        label={"SSO Application"}
        field={"application"}
        value={application}
        onChange={handleApplicationChange}
        disabled={disabled}
        options={ssoApplications.filter(a => {
          if (flavour === null) return true;

          return a.system.slug === flavour;
        }).map(a => {
          return {text: a.name, value: a._id, key: a._id};
        })} />

      <Select
        label={"Endpoint"}
        field={"endpoint"}
        value={endpoint}
        onChange={handleEndpointChange}
        disabled={disabled}
        options={pickableEndpoints.map(a => {
          return {text: a.callback, value: a.callback.replace(/\/$/, ''), key: a._id};
        })} />
    </>
  );
}
export default Organisation_Receiver(FieldSSOApplicationsAndEndpoint, (organisation) => {
  return {organisation};
});