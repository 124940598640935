import React, {useEffect, useState, useMemo} from "react";
import {Organisation_Receiver} from "../../../../context/organisation";
import API from "../../../../shared/API"

const RecipientSelector = ({recipients, onRecipientRemove, onRecipientAdd, organisation}) => {
  const [isLoading, setIsLoading]         = useState(true);
  const [users, setUsers]                 = useState([]);
  const [roles, setRoles]                 = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);

  useEffect(() => {
    Promise.all([
      API.org.push_notifications.getRoles(organisation.data._id),
      API.org.push_notifications.getUsers(organisation.data._id)
    ]).then(res => {
      setRoles(res[0]);
      setUsers(res[1]);
    }).catch(err => {

    }).finally(() => {
      setIsLoading(true);
    })
  }, [organisation]);

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      if(filteredRoles.length === 0) return true;
      for (let roleIndex in user.roles) {
        const role = user.roles[roleIndex];
        if (filteredRoles.indexOf(role) !== -1) {
          return true;
        }
      }
      return false;
    });
  }, [users, filteredRoles]);
  const handleRoleFilter = role => {
    if (filteredRoles.indexOf(role) !== -1) {
      setFilteredRoles(
        filteredRoles.filter((i) => i !== role)
      );
    } else {
      setFilteredRoles(filteredRoles.concat([role]));
    }
  };

  return (
    <div>
      <div className="p-4 flex gap-4 flex-wrap">
        {roles.map((role, i) => {
          return (
            <button key={i} onClick={() => handleRoleFilter(role)} className={`${filteredRoles.indexOf(role) === -1 ? "bg-gray-800" : "bg-green-800"} p-2 text-sm text-white border border-gray-400 rounded-lg hover:bg-opacity-60`}>{role}</button>
          );
        })}
      </div>
      <div className="p-2 bg-gray-800">
        {filteredUsers.length >=1 && (
          <div className="p-1 flex items-center justify-between">
            <button onClick={() => {
              onRecipientAdd(filteredUsers.map(u => {
                return {
                  _id: u._id,
                  type: "team",
                  name: u.firstName + " " + u.surname,
                }
              }));
            }} className={`m-2 py-1 px-3 bg-green-500 rounded-lg text-center`}>Add All</button>
          </div>
        )}
        <table className="min-w-full divide-y divide-gray-200 border-separate table-fixed">
          <thead className="bg-gray-800">
            <tr>
              <th className="px-2 py-3 text-left text-xs font-medium text-gray-400 uppercase">Name</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-gray-400 uppercase">Status</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-gray-400 uppercase"></th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-200">
          {filteredUsers.map((u) => {
            const addRemoveData = {
              _id: u._id,
              type: "team",
              name: u.firstName + " " + u.surname,
            };

            return (
              <tr key={u._id} className={`p-2  ${u.onCall ? "bg-green-900" : "bg-red-900"} bg-opacity-50 border-b border-gray-600`}>
                <td className="p-2">{u.firstName} {u.surname}</td>
                <td className="p-2">
                  {u.onCall === false && (
                    <span className="italic text-sm mr-2">(Off-call)</span>
                  )}
                  {u.operational === false && (
                    <span className="italic text-sm">(Non-op)</span>
                  )}
                </td>
                <td className="p-2 text-right">
                  {recipients.some(i => i._id === u._id) ? (
                    <button onClick={() => onRecipientRemove(addRemoveData)} className={`m-2 py-1 px-3 bg-red-500 rounded-lg text-center`}>Remove</button>
                  ) : (
                    <button onClick={() => onRecipientAdd(addRemoveData)} className={`m-2 py-1 px-3 bg-green-500 rounded-lg text-center`}>Add</button>
                  )}
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
        {filteredUsers.length === 0 && (
          <div className={"bg-blue-800 p-2 mt-4 border border-blue-500"}>There are no results to display.</div>
        )}
      </div>
    </div>
  );
};
export default Organisation_Receiver(RecipientSelector, organisation => {
  return {organisation};
});