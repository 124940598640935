import React from "react";
import { Route, Switch } from "react-router-dom";
import { OrganisationProvider } from "../../context/organisation";
import API from "../../shared/API";
import MenuHolder from "../../shared/components/MenuHolder";
import CCTVPage from "./cctv";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import SMS from "./sms";
import SystemOnePage from "./sarsys_request";
import SarSYS_Review from "./sarsys_review";
import TeamPages from "./team";
import VideoFeedPages from "./video_feeds";
import SarMAP_Pages from "./sarmap";
import ContactsPage from "./contacts";
import TelephonePages from "./telephone";
import PushNotificationsPage from "./push_notifications";
import NotFoundPage from "./_notFound";

const OrganisationsIndex = ({
  urlPrefix = "",
  organisationId,
  isSarStuff = false,
}) => {
  const getData = async () => {
    return await API.org.show(organisationId);
  };
  return (
    <OrganisationProvider loadData={getData} urlPrefix={urlPrefix}>
      <MenuHolder
        type={"organisation"}
        isSarStuff={isSarStuff}
        urlPrefix={urlPrefix}
      >
        <Switch>
          <Route exact path={`${urlPrefix}/`} component={DashboardPage} />
          <Route exact path={`${urlPrefix}/settings`} component={SettingsPage}/>
          <Route exact path={`${urlPrefix}/cctv`} component={CCTVPage} />
          <Route exact path={`${urlPrefix}/sms`} component={SMS} />
          {/*<Route exact path={`${urlPrefix}/sms/:inboxid`} component={Inbox} />*/}
          <Route exact path={`${urlPrefix}/sarsys_request`} component={SystemOnePage}/>
          <Route exact path={`${urlPrefix}/sarsys_review`} component={SarSYS_Review}/>
          <Route exact path={`${urlPrefix}/contacts`} component={ContactsPage}/>
          <Route exact path={`${urlPrefix}/push_notifications`} component={PushNotificationsPage} />
          <Route path={`${urlPrefix}/video_feeds`} component={VideoFeedPages} />
          <Route path={`${urlPrefix}/sarmap`} component={SarMAP_Pages} />
          <Route path={`${urlPrefix}/team`} component={TeamPages} />
          <Route path={`${urlPrefix}/telephone`} component={TelephonePages} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </MenuHolder>
    </OrganisationProvider>
  );
};
export default OrganisationsIndex;