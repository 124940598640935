import React from "react";

const Box = ({
  title,
  button,
  children,
  styles = "",
  outer = "",
  className = "",
}) => {
  return (
    <div className={`w-full ${outer} ${className}`}>
      <div className=" w-full border-2   border-gray-700 rounded  p-0">
        <div className="flex w-full justify-between bg-gray-900 border-b border-gray-700">
          {title && (
            <header className="p-2  text-gray-100 text-xl  ">{title}</header>
          )}
          {button && button.text && (
            <button
              onClick={button.action}
              className={`m-2 py-1 px-3 ${button.colour} rounded-lg  
               text-center`}
            >
              {button.text}
            </button>
          )}
        </div>

        <div className={`  ${styles}`}>{children}</div>
      </div>
    </div>
  );
};
export default Box;
