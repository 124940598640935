import React, {useState, useEffect, useCallback} from "react";
import dayjs from "dayjs";
import {ChatIcon} from "@heroicons/react/outline";
import Toast from "react-hot-toast";
import {AuthComponents} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import API from "../../../shared/API";
import StatBox from "../../../shared/components/StatBox";
import AlertMessage from "../../../shared/components/AlertMessage";
import {Organisation_Receiver} from "../../../context/organisation";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import InboxPhoneNumbers from "./_components/inboxPhoneNumbers";
import SentMessages from "./_components/sentMessages";
import CreditAdjustment from "./_components/creditAdjustment";

// import SMSCreditPurchases from "./_components/creditPurchases";

function SMSPage({organisation}) {
  const [smsError, setSMSError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreditAdjustment, setShowCreditAdjustment] = useState(false);
  const [stats, setStats] = useState({});
  const [sms, setSMS] = useState({});

  const getData = useCallback(() => {
    Promise.all([
      API.org.sms.getStats(organisation.data._id),
      API.org.sms.getDetails(organisation.data._id),
    ]).then((res) => {
      setStats(res[0]);
      setSMS(res[1]);
    }).catch((error) => {
      console.log(error);

      setSMSError(error.response.data.message)

    }).finally(() => {
      setLoading(false);
    });
  }, [organisation]);
  const handleSenderChange = useCallback((newSenderId) => {
    API.org.sms.changeSender(organisation.data._id, newSenderId).then(() => {
      getData();
      organisation._refresh();
      Toast.success("Successfully updated default sender... PLEASE NOTE: This change may take upto 5 minutes to appear in all systems.", {
        duration: 10000
      });
    }).catch(err => {
      Toast.error(err.response.data.message);
    });
  }, [organisation, getData]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (smsError) {
    return (
      <div>
        <OrganisationPageHeader title={"SMS Management"}
                                subTitle={"Manage your organisations SMS credits, view previously sent and received text messages."}
                                subIcon={<ChatIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                   aria-hidden="true"/>}/>
        <div className={"p-2"}>
          <AlertMessage.Error>SMS Error: {smsError}</AlertMessage.Error>
        </div>
      </div>
    );
  }

  const isV2 = sms.isV2 !== undefined ? sms.isV2 : false;

  return (
    <div>
      <OrganisationPageHeader title={"SMS Management"}
                              subTitle={"Manage your organisations SMS credits, view previously sent and received text messages."}
                              subIcon={<ChatIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                 aria-hidden="true"/>}/>
      {showCreditAdjustment && <CreditAdjustment totalCredits={sms ? sms.balance : 0} dataReload={() => {
        getData();
      }} onClose={() => {
        setShowCreditAdjustment(false);
      }}/>}
      <div className="p-2">
        <div className="py-2 grid grid-cols-2 grid-rows-3 sm:grid-cols-3 sm:grid-rows-2 text-center gap-2">
          <StatBox
            label={(<>
              Available Credits
              {isV2 && <AuthComponents.Can scope={"sarstuff:global:admins"}>
                <button className={"float-right border rounded p-1 text-xs text-white"}
                        onClick={() => setShowCreditAdjustment(true)}>Adjust
                </button>
              </AuthComponents.Can>}
            </>)}
            value={sms ? sms.balance : 0}
            loading={loading}
          />
          <StatBox
            label={"Credits Used (-7 Days)"}
            value={stats && stats.creditsUsed && stats.creditsUsed.week}
            loading={loading}
          />
          <StatBox
            label={" Credits Used (-31 Days)"}
            value={stats && stats.creditsUsed && stats.creditsUsed.month}
            loading={loading}
          />
          <StatBox
            label={"Est. date till no credits"}
            value={stats.estimatedDate !== null ? dayjs(stats.estimatedDate).format("DD/MM/YYYY") : "[N/A]"}
            loading={loading}
          />
          <StatBox
            label={"Sent Messages (-7 Days)"}
            value={stats && stats.messagesSent && stats.messagesSent.week}
            loading={loading}
          />
          <StatBox
            label={"Sent Messages (-31 Days)"}
            value={stats && stats.messagesSent && stats.messagesSent.month}
            loading={loading}
          />
        </div>
        <div className="flex gap-2">
          <div className="pb-4 mb-2 w-1/3 sm:w-1/3">
            <InboxPhoneNumbers
              phoneNumbers={sms.phone_numbers}
              isV2={isV2}
              onChangeSenderClick={handleSenderChange}
              onDataReload={() => {
                getData();
                organisation._refresh();
              }}
            />
            {/* TODO repair sms credit purchases */}
            {/*<SMSCreditPurchases organisation={organisation} />*/}
          </div>
          <div className="pb-4 mb-2 w-1/3 sm:w-2/3">
            <SentMessages/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organisation_Receiver(SMSPage, (organisation) => {
  return {organisation};
});