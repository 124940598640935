import React, { useEffect, useState, useMemo } from "react";
import {
  MinusCircleIcon,
  PlusCircleIcon,
  XIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import Select from "../../../../shared/components/Select";
import API from "../../../../shared/API";
import toast from "react-hot-toast";

const UserRoles = ({ getData, user, organisation }) => {
  const [orgRoles, setOrgRoles] = useState([]);
  const [addRole, setAddRole] = useState(false);
  const [removeRole, setRemoveRole] = useState("");
  const [role, setRole] = useState("");

  const canAddRoles = useMemo(() => {
    if (organisation.data.team_site !== undefined) {
      if (organisation.data.team_site.is_active && organisation.data.team_site.system === "Team4") {
        return false;
      }
    }
    return true;
  }, [organisation.data]);

  useEffect(() => {
    if (organisation.data.userRoles) {
      setOrgRoles(
        organisation.data.userRoles.map((r, i) => {
          return {
            key: i,
            text: r,
            value: r,
          };
        })
      );
    }
  }, [organisation]);

  useEffect(() => {
    if (removeRole !== "") {
      let current = user.roles.filter((item) => item !== removeRole);

      API.org.users
        .update(organisation.data._id, user._id, {
          roles: current,
        })
        .then(() => {
          toast.success("Successfully removed role.", {
            duration: 2000,
          });
          setRole("");
          getData();
        });
    }
  }, [removeRole]);

  const handleDropdownChange = (e) => {
    setRole(e.target.value);
  };

  const handleAddUserRole = () => {
    if (role) {
      let current = user.roles;
      current.push(role);

      API.org.users
        .update(organisation.data._id, user._id, { roles: current })
        .then(() => {
          setRole("");
          toast.success("Successfully added role.", {
            duration: 2000,
          });
        });
    }
  };

  if (user._id === undefined) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="text-white tracking-wider bg-gray-900 text-xl p-2 border border-gray-700 rounded-t-xl ">
        Roles{" "}
        {canAddRoles && (
          <React.Fragment>
            <button onClick={() => setAddRole(!addRole)}>
              {addRole ? (
                <MinusCircleIcon className="h-6 text-red-400" />
              ) : (
                <PlusCircleIcon className="h-6 text-green-400" />
              )}
            </button>
            {addRole && (
              <div className="p-2 flex gap-4">
                <Select
                  options={orgRoles.filter(
                    (item) =>
                      (user.roles ? user.roles : []).find(
                        (userRole) => userRole === item.value
                      ) === undefined
                  )}
                  onChange={handleDropdownChange}
                  name={"role"}
                  value={role}
                />
                <button
                  className={"p-2 bg-green-600 rounded-xl"}
                  onClick={handleAddUserRole}
                >
                  Add
                </button>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <div className="flex gap-3 flex-wrap border border-gray-700 bg-gray-900 px-2 py-4 rounded-b-xl">
        {!canAddRoles && (
          <div className={"bg-blue-400 w-full p-3"}>
            <InformationCircleIcon className={"h-6 inline mr-2"} /> Management
            of roles are performed within TeamSite.
          </div>
        )}
        {user.roles.length === 0 && (
          <p>There are currently no roles assigned to this user to display.</p>
        )}
        {user.roles.map((r) => {
          return (
            <span
              key={r}
              className="flex items-center border border-gray-700 bg-gray-800 text-gray-300 px-4 py-2 rounded-xl w-fit"
            >
              <p>{r}</p>
              {canAddRoles && (
                <XIcon
                  className="my-auto h-5 ml-2 text-red-500 cursor-pointer hover:text-white"
                  onClick={() => setRemoveRole(r)}
                />
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
};
export default UserRoles;
