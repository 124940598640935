import React, {useEffect, useState} from "react";
import DayJS from "dayjs";
import {Organisation_Receiver} from "../../../context/organisation";
import API from "../../../shared/API";
import Loader from "../../../shared/components/Loader";
import Modal from "../../../shared/components/Modal";
import SarMAP_App_Icon from "../../../assets/svg/sarmap_app.svg";
import SarMAP_Zello_Icon from "../../../assets/svg/sarmap_zello.svg";
import SarMAP_Quartix_Icon from "../../../assets/svg/sarmap_quartix.svg";
import AlertMessage from "../../../shared/components/AlertMessage";
import AssetForm from "./_components/assetForm";
import Confirm from "../../../shared/components/Confirm";
import toast from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Assets = ({organisation}) => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [showAddAssetDialog, setShowAddAssetDialog] = useState(false);
  const [asset, setAsset] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteAsset, setDeleteAsset] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    getAssets();
  }, [organisation]);

  const getAssets = () => {
    API.org.sarmap.assets.all(organisation.data._id).then(res => setAssets(res)).catch(err => {
      if (err.isAxiosError) {
        setLoadingError(err.response.data.message);
        return;
      }
      setLoadingError("There was an unexpected error while retrieving data from server.");
    }).finally(() => setLoading(false));
  };

  const handleAssetUpdate = data => {
    return API.org.sarmap.assets.update(organisation.data._id, data._id, data).then(() => {
      setAsset(false);
      getAssets();
    });
  };
  const handleCreateAsset = data => {
    return API.org.sarmap.assets.add(organisation.data._id, data).then(() => {
      setShowAddAssetDialog(false);
      getAssets();
    });
  };
  const handleDeleteAsset = () => {
    setIsDeleting(true);
    API.org.sarmap.assets.remove(organisation.data._id, deleteAsset._id).then(() => {
      toast.success("Successfully deleted asset and its associated gps data.");
      setIsDeleting(false);

      setDeleteAsset(false);
      getAssets();
    }).catch(err => {
      setIsDeleting(false);
      toast.error("Unable to delete asset due to a server error: " + err.response.data.message);
    });
  };

  const getFilteredAssets = () => {
    return assets.filter((item) => {
      if (searchQuery === null || searchQuery.trim() === "") {
        return true;
      }
      let titleMatch = item.title
        ? item.title.toLowerCase().search(searchQuery) !== -1
        : false;
      let assetID = item.assetID
        ? item.assetID.toLowerCase().search(searchQuery) !== -1
        : false;
      return titleMatch || assetID;
    });
  };
  const filterList = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const renderAssetIcon = asset => {
    return (
      <>
        {asset.assetFeed === "app" && (
          <img src={SarMAP_App_Icon} alt={"Tracker App"}/>
        )}
        {asset.assetFeed === "smc_gateway" && (
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
          >
            <g fill={"#fff"}>
              <g>
                <path
                  d="M347.429,161.959v-31.347h-6.269V19.853C341.159,8.906,332.253,0,321.306,0c-10.947,0-19.853,8.906-19.853,19.853v110.759
			h-6.269v31.347h-31.347v-13.061c0-12.963-10.547-23.51-23.51-23.51s-23.51,10.547-23.51,23.51v13.061h-5.224v-13.061
			c0-12.963-10.547-23.51-23.51-23.51s-23.51,10.547-23.51,23.51v13.061h-15.673v184.425l10.449,37.091v99.791
			c0,15.844,12.891,28.735,28.735,28.735h135.837c15.844,0,28.735-12.891,28.735-28.735v-99.791l10.449-37.091V161.959H347.429z
			 M317.126,19.853c0-2.305,1.875-4.18,4.18-4.18c2.305,0,4.18,1.875,4.18,4.18v110.759h-8.359V19.853z M310.857,146.286h20.898
			v15.673h-20.898V146.286z M232.49,148.898c0-4.322,3.515-7.837,7.837-7.837s7.837,3.515,7.837,7.837v13.061H232.49V148.898z
			 M180.245,148.898c0-4.322,3.515-7.837,7.837-7.837c4.322,0,7.837,3.515,7.837,7.837v13.061h-15.673V148.898z M347.429,344.219
			l-10.449,37.091v101.956c0,7.202-5.859,13.061-13.061,13.061H188.082c-7.203,0-13.061-5.859-13.061-13.061V381.309l-10.449-37.091
			V177.633h182.857V344.219z"
                />
              </g>
            </g>
            <g fill={"#fff"}>
              <g>
                <path
                  d="M332.001,305.078c2.254-7.423,3.412-15.137,3.412-23.018v-60.103c0-15.844-12.891-28.735-28.735-28.735H205.322
			c-15.844,0-28.735,12.891-28.735,28.735v60.103c0,7.881,1.159,15.595,3.412,23.018c-4.358,4.271-7.069,10.216-7.069,16.784
			c0,12.963,10.547,23.51,23.51,23.51c3.282,0,6.408-0.678,9.249-1.899c6.523,5.342,13.888,9.635,21.723,12.662
			c1.471,14.474,13.731,25.808,28.586,25.808c14.852,0,27.11-11.329,28.585-25.797c7.833-3.027,15.197-7.324,21.726-12.671
			c2.84,1.219,5.966,1.898,9.248,1.898c12.963,0,23.51-10.547,23.51-23.51C339.069,315.294,336.358,309.348,332.001,305.078z
			 M192.261,221.957c0-7.202,5.859-13.061,13.061-13.061h101.355c7.203,0,13.061,5.859,13.061,13.061v40.835
			c0,7.202-5.859,13.061-13.061,13.061H205.322c-7.202,0-13.061-5.859-13.061-13.061V221.957z M196.441,329.699
			c-4.322,0-7.837-3.515-7.837-7.837c0-4.322,3.515-7.837,7.837-7.837c4.322,0,7.837,3.515,7.837,7.837
			C204.278,326.184,200.762,329.699,196.441,329.699z M256,366.27c-7.202,0-13.061-5.859-13.061-13.061
			c0-7.203,5.859-13.061,13.061-13.061c7.202,0,13.061,5.859,13.061,13.061C269.061,360.411,263.202,366.27,256,366.27z
			 M292.049,321.863c0,3.9,0.959,7.576,2.647,10.816c-4.019,3.074-8.387,5.669-12.993,7.705
			c-4.722-9.422-14.466-15.909-25.701-15.909c-11.233,0-20.975,6.484-25.698,15.901c-4.609-2.035-8.98-4.629-12.996-7.699
			c1.686-3.239,2.646-6.916,2.646-10.815c0-12.963-10.547-23.51-23.51-23.51c-0.688,0-1.366,0.036-2.039,0.094
			c-0.863-3.24-1.47-6.545-1.808-9.901c3.839,1.905,8.159,2.981,12.727,2.981h101.355c4.568,0,8.888-1.076,12.727-2.981
			c-0.338,3.357-0.945,6.661-1.807,9.901c-0.673-0.059-1.351-0.094-2.039-0.094C302.596,298.353,292.049,308.9,292.049,321.863z
			 M315.559,329.699c-4.322,0-7.837-3.515-7.837-7.837c0-4.322,3.515-7.837,7.837-7.837c4.322,0,7.837,3.515,7.837,7.837
			C323.396,326.184,319.881,329.699,315.559,329.699z"
                />
              </g>
            </g>
            <g fill={"#fff"}>
              <g>
                <rect
                  x="229.878"
                  y="409.778"
                  width="52.245"
                  height="15.673"
                />
              </g>
            </g>
            <g fill={"#fff"}>
              <g>
                <rect
                  x="214.204"
                  y="435.9"
                  width="83.592"
                  height="15.673"
                />
              </g>
            </g>
            <g fill={"#fff"}>
              <g>
                <rect
                  x="229.878"
                  y="462.023"
                  width="52.245"
                  height="15.673"
                />
              </g>
            </g>
            <g fill={"#ff0"}>
              <g>
                <circle cx="309.081" cy="398.064" r="10.449"/>
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        )}
        {asset.assetFeed === "zello" && (
          <img src={SarMAP_Zello_Icon} alt={"Zello App"}/>
        )}
        {asset.assetFeed === "quartix" && (
          <img src={SarMAP_Quartix_Icon} alt={"Quartix"}/>
        )}
      </>
    );
  }
  const AddAssetModal = () => {
    if (!showAddAssetDialog) return;
    return (
      <Modal onClose={() => {
        // setShowAddAssetDialog(false);
      }} title={(
        <>
          {/*<div className="w-12 h-12">*/}
          {/*  {renderAssetIcon()}*/}
          {/*</div>*/}
          <div className="text-2xl font-mono">
            Add Asset
          </div>
        </>
      )}>
        <AssetForm onSubmit={handleCreateAsset} organisation={organisation} onClose={() => {
          setShowAddAssetDialog(false);
        }}/>
      </Modal>
    );
  };
  const EditAssetModal = () => {
    if (!asset) return;
    return (
      <Modal onClose={() => {
        // setAsset(false);
      }} title={(
        <>
          <div className="w-12 h-12">
            {renderAssetIcon(asset)}
          </div>
          <div className="text-2xl font-mono">
            {asset.title}
          </div>
        </>
      )}>
        <AssetForm onSubmit={handleAssetUpdate} asset={asset} organisation={organisation} onClose={() => {
          setAsset(false);
        }} onDelete={() => {
          setTimeout(() => {
            setDeleteAsset({
              ...asset
            });
          }, 100);
          setAsset(false);
        }}/>
      </Modal>
    );
  };

  if (loading) {
    return (
      <Loader>Querying SarMAP server...</Loader>
    );
  }
  if (loadingError) {
    return (
      <AlertMessage.Error>{loadingError}</AlertMessage.Error>
    );
  }

  const assetsList = getFilteredAssets();

  return (
    <div>
      {deleteAsset !== false && <Confirm
        isMiddle={true}
        open={true}
        disabled={isDeleting}
        onCancel={() => {
          setTimeout(() => {
            setAsset({
              ...deleteAsset
            });
          }, 100);
          setDeleteAsset(false);
        }}
        onConfirm={() => handleDeleteAsset()}
        title={"Delete asset: " + deleteAsset.title}
        message={"Are you sure you would like to delete this asset and its historical GPS data? This action is non-reversible however the asset may automatically be re-created if it returns online."}
      />}

      {AddAssetModal()}
      {EditAssetModal()}
      <div className="p-2">
        <button
          onClick={() => {
            setShowAddAssetDialog(true);
          }}
          className="rounded bg-green-700 text-white p-2 float-right"
        >
          Add New Asset
        </button>
        <div className={"clear-both"}/>
      </div>
      <div className="px-2">
        <input
          onChange={filterList}
          placeholder="Search Asset..."
          value={searchQuery}
          className="p-2 border border-gray-700 rounded bg-gray-900 w-full ring-0"
        />
      </div>
      {assetsList.length === 0 && <AlertMessage.Info>
        There are no assets to display.
      </AlertMessage.Info>}
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 p-2"
      >
        {assetsList.map((asset) => {
          const isOnline =
            asset._lastGPS &&
            DayJS(asset._lastGPS).isAfter(DayJS().subtract(30, "minute"));
          return (
            <li
              onClick={() => setAsset(asset)}
              key={asset._id}
              className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
            >
              <div
                className={classNames(
                  isOnline
                    ? "bg-green-500 bg-opacity-40"
                    : "bg-red-500 bg-opacity-40",
                  "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md p-1"
                )}
              >
                {asset.assetFeed === "app" && (
                  <img src={SarMAP_App_Icon} alt={"Tracker App"}/>
                )}
                {asset.assetFeed === "smc_gateway" && (
                  <svg
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                  >
                    <g fill={"#fff"}>
                      <g>
                        <path
                          d="M347.429,161.959v-31.347h-6.269V19.853C341.159,8.906,332.253,0,321.306,0c-10.947,0-19.853,8.906-19.853,19.853v110.759
			h-6.269v31.347h-31.347v-13.061c0-12.963-10.547-23.51-23.51-23.51s-23.51,10.547-23.51,23.51v13.061h-5.224v-13.061
			c0-12.963-10.547-23.51-23.51-23.51s-23.51,10.547-23.51,23.51v13.061h-15.673v184.425l10.449,37.091v99.791
			c0,15.844,12.891,28.735,28.735,28.735h135.837c15.844,0,28.735-12.891,28.735-28.735v-99.791l10.449-37.091V161.959H347.429z
			 M317.126,19.853c0-2.305,1.875-4.18,4.18-4.18c2.305,0,4.18,1.875,4.18,4.18v110.759h-8.359V19.853z M310.857,146.286h20.898
			v15.673h-20.898V146.286z M232.49,148.898c0-4.322,3.515-7.837,7.837-7.837s7.837,3.515,7.837,7.837v13.061H232.49V148.898z
			 M180.245,148.898c0-4.322,3.515-7.837,7.837-7.837c4.322,0,7.837,3.515,7.837,7.837v13.061h-15.673V148.898z M347.429,344.219
			l-10.449,37.091v101.956c0,7.202-5.859,13.061-13.061,13.061H188.082c-7.203,0-13.061-5.859-13.061-13.061V381.309l-10.449-37.091
			V177.633h182.857V344.219z"
                        />
                      </g>
                    </g>
                    <g fill={"#fff"}>
                      <g>
                        <path
                          d="M332.001,305.078c2.254-7.423,3.412-15.137,3.412-23.018v-60.103c0-15.844-12.891-28.735-28.735-28.735H205.322
			c-15.844,0-28.735,12.891-28.735,28.735v60.103c0,7.881,1.159,15.595,3.412,23.018c-4.358,4.271-7.069,10.216-7.069,16.784
			c0,12.963,10.547,23.51,23.51,23.51c3.282,0,6.408-0.678,9.249-1.899c6.523,5.342,13.888,9.635,21.723,12.662
			c1.471,14.474,13.731,25.808,28.586,25.808c14.852,0,27.11-11.329,28.585-25.797c7.833-3.027,15.197-7.324,21.726-12.671
			c2.84,1.219,5.966,1.898,9.248,1.898c12.963,0,23.51-10.547,23.51-23.51C339.069,315.294,336.358,309.348,332.001,305.078z
			 M192.261,221.957c0-7.202,5.859-13.061,13.061-13.061h101.355c7.203,0,13.061,5.859,13.061,13.061v40.835
			c0,7.202-5.859,13.061-13.061,13.061H205.322c-7.202,0-13.061-5.859-13.061-13.061V221.957z M196.441,329.699
			c-4.322,0-7.837-3.515-7.837-7.837c0-4.322,3.515-7.837,7.837-7.837c4.322,0,7.837,3.515,7.837,7.837
			C204.278,326.184,200.762,329.699,196.441,329.699z M256,366.27c-7.202,0-13.061-5.859-13.061-13.061
			c0-7.203,5.859-13.061,13.061-13.061c7.202,0,13.061,5.859,13.061,13.061C269.061,360.411,263.202,366.27,256,366.27z
			 M292.049,321.863c0,3.9,0.959,7.576,2.647,10.816c-4.019,3.074-8.387,5.669-12.993,7.705
			c-4.722-9.422-14.466-15.909-25.701-15.909c-11.233,0-20.975,6.484-25.698,15.901c-4.609-2.035-8.98-4.629-12.996-7.699
			c1.686-3.239,2.646-6.916,2.646-10.815c0-12.963-10.547-23.51-23.51-23.51c-0.688,0-1.366,0.036-2.039,0.094
			c-0.863-3.24-1.47-6.545-1.808-9.901c3.839,1.905,8.159,2.981,12.727,2.981h101.355c4.568,0,8.888-1.076,12.727-2.981
			c-0.338,3.357-0.945,6.661-1.807,9.901c-0.673-0.059-1.351-0.094-2.039-0.094C302.596,298.353,292.049,308.9,292.049,321.863z
			 M315.559,329.699c-4.322,0-7.837-3.515-7.837-7.837c0-4.322,3.515-7.837,7.837-7.837c4.322,0,7.837,3.515,7.837,7.837
			C323.396,326.184,319.881,329.699,315.559,329.699z"
                        />
                      </g>
                    </g>
                    <g fill={"#fff"}>
                      <g>
                        <rect
                          x="229.878"
                          y="409.778"
                          width="52.245"
                          height="15.673"
                        />
                      </g>
                    </g>
                    <g fill={"#fff"}>
                      <g>
                        <rect
                          x="214.204"
                          y="435.9"
                          width="83.592"
                          height="15.673"
                        />
                      </g>
                    </g>
                    <g fill={"#fff"}>
                      <g>
                        <rect
                          x="229.878"
                          y="462.023"
                          width="52.245"
                          height="15.673"
                        />
                      </g>
                    </g>
                    <g fill={"#ff0"}>
                      <g>
                        <circle cx="309.081" cy="398.064" r="10.449"/>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                )}
                {asset.assetFeed === "zello" && (
                  <img src={SarMAP_Zello_Icon} alt={"Zello App"}/>
                )}
                {asset.assetFeed === "quartix" && (
                  <img src={SarMAP_Quartix_Icon} alt={"Quartix"}/>
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-700 bg-gray-800 hover:bg-gray-900 ">
                <div className="flex-1 truncate px-4 py-2 ">
                  <div className="font-medium text-gray-100  text-md">
                    {asset.title}
                  </div>
                  <p className="text-gray-200 text-sm truncate">
                    Asset ID: {asset.assetID}
                  </p>
                  <p className="text-gray-200 text-sm truncate">
                    Last GPS Date:{" "}
                    {asset._lastGPS !== undefined
                      ? DayJS(asset._lastGPS).format("DD/MM/YYYY HH:mm:ss")
                      : "[Offline]"}
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2 flex flex-col"></div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Organisation_Receiver(Assets, (organisation) => {
  return {organisation};
});