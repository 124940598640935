import React, {useEffect} from "react";
import {Organisation_Receiver} from "../../../context/organisation";


const Dashboard = ({ organisation, history }) => {

  useEffect(() => {
    history.push(`${organisation._urlPrefix}/telephone/call_flow`);
  }, []);
  return (
    <div>
      Dashboard coming soon...
    </div>
  );
};
export default Organisation_Receiver(Dashboard, (organisation) => {
  return {organisation};
});