import React from "react";
import { PasswordPolicy, charsets } from "password-sheriff";
import API from "../../shared/API";
import {
  CheckCircleIcon,
  CheckIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";

export default class PasswordChangeFields extends React.Component {
  state = {
    policy: {
      charset: {},
    },
    password: "",
    password_confirm: "",
    loading: true,
  };

  componentDidMount() {
    API.authentication
      .password_policy()
      .then((res) => {
        this.setState({
          policy: res,
        });
      })
      .catch((err) => {
        // TODO
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  validatePassword = () => {
    const requirements = this.passwordRequirements();
    const totalFailed = requirements.filter((item) => !item.passed).length;
    if (totalFailed >= 1) {
      // Password failed, alert the parent
      this.props.onChange(null);
    } else {
      // Password passed, alert the parent
      this.props.onChange(this.state.password);
    }
  };
  passwordRequirements = () => {
    let requirements = [
      {
        text: "Password & Confirm Password fields match",
        passed:
          this.state.password !== ""
            ? this.state.password === this.state.password_confirm
            : false,
      },
      {
        text:
          "Must be longer then " + this.state.policy.minLength + " characters",
        passed: this.state.password.length >= this.state.policy.minLength,
      },
    ];

    // Lowercase
    if (this.state.policy.charset.lowercase) {
      let passwordPolicy = new PasswordPolicy({
        contains: {
          expressions: [charsets.lowerCase],
        },
      });
      requirements.push({
        text: "Must contain lowercase letters (a-z)",
        passed: passwordPolicy.check(this.state.password),
      });
    } else {
      requirements.push({
        text: "Can contain lowercase letters (a-z)",
        optional: true,
        passed: true,
      });
    }

    // Uppercase
    if (this.state.policy.charset.uppercase) {
      let passwordPolicy = new PasswordPolicy({
        contains: {
          expressions: [charsets.upperCase],
        },
      });
      requirements.push({
        text: "Must contain uppercase letters (A-Z)",
        passed: passwordPolicy.check(this.state.password),
      });
    } else {
      requirements.push({
        text: "Can contain uppercase letters (A-Z)",
        optional: true,
        passed: true,
      });
    }

    // Special
    if (this.state.policy.charset.special) {
      let passwordPolicy = new PasswordPolicy({
        contains: {
          expressions: [charsets.specialCharacters],
        },
      });
      requirements.push({
        text: "Must contain special characters e.g. # @ < > ? ! £ $ % & * - _ / ~ [ ] { } = +",
        passed: passwordPolicy.check(this.state.password),
      });
    } else {
      requirements.push({
        text: "Can contain special characters e.g. # @ < > ? ! £ $ % & * - _ / ~ [ ] { } = +",
        optional: true,
        passed: true,
      });
    }

    // Numbers
    if (this.state.policy.charset.numbers) {
      let passwordPolicy = new PasswordPolicy({
        contains: {
          expressions: [charsets.numbers],
        },
      });
      requirements.push({
        text: "Must contain numbers (0-9)",
        passed: passwordPolicy.check(this.state.password),
      });
    } else {
      requirements.push({
        text: "Can contain numbers (0-9)",
        optional: true,
        passed: true,
      });
    }
    return requirements;
  };
  renderPasswordRequirements() {
    const requirements = this.passwordRequirements();
    return (
      <ul className="bg-gray-700 p-2 border border-gray-900 rounded-xl">
        {requirements.map((item, index) => {
          return (
            <li
              key={index}
              className="flex gap-2"
              style={{
                color:
                  item.optional !== undefined
                    ? "lightblue"
                    : item.passed
                    ? "green"
                    : "red",
              }}
            >
              {item.optional !== undefined ? (
                <CheckCircleIcon className="h-6" />
              ) : item.passed ? (
                <CheckIcon className="h-6" />
              ) : (
                <ExclamationIcon className="h-6" />
              )}
              {item.text}
            </li>
          );
        })}
      </ul>
    );
  }
  render() {
    return (
      <div>
        {this.props.labels ? (
          <React.Fragment>
            <div className="flex flex-col gap-2 p-2 text-white">
              <label>Password *</label>
              <input
                className="bg-gray-800 p-2 border border-gray-700 rounded-xl"
                type={"password"}
                name={"password"}
                onChange={(e) =>
                  this.setState(
                    { password: e.target.value },
                    this.validatePassword
                  )
                }
                value={this.state.password}
              />
              {this.props.error && <div className="flex gap-2" style={{color: "red"}}><ExclamationIcon className="h-6" /> {this.props.error}</div>}
            </div>
            <div className="flex flex-col gap-2 p-2 text-white">
              <label>Confirm Password *</label>
              <input
                className="bg-gray-800 p-2 border border-gray-700 rounded-xl"
                type={"password"}
                name={"password_confirm"}
                onChange={(e) =>
                  this.setState(
                    { password_confirm: e.target.value },
                    this.validatePassword
                  )
                }
                value={this.state.password_confirm}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <input
                type={"password"}
                placeholder="Password"
                name={"password"}
                onChange={(e) =>
                  this.setState(
                    { password: e.target.value },
                    this.validatePassword
                  )
                }
                value={this.state.password}
              />
            </div>
            <div>
              <input
                icon="lock"
                iconPosition="left"
                type={"password"}
                placeholder="Confirm Password"
                name={"password_confirm"}
                onChange={(e) =>
                  this.setState(
                    { password_confirm: e.target.value },
                    this.validatePassword
                  )
                }
                value={this.state.password_confirm}
              />
            </div>
          </React.Fragment>
        )}
        <div>
          <div className="p-2 flex gap-4 text-white">
            <InformationCircleIcon className="h-6" /> Password must meet the
            following requirements:
          </div>
          {this.renderPasswordRequirements()}
        </div>
      </div>
    );
  }
}
