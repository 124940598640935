import React, { useEffect, useState } from "react";
import { Organisation_Receiver } from "../../../../../context/organisation";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";
import Loader from "../../../../../shared/components/Loader";
import API from "../../../../../shared/API";
import FieldSSOApplications from "./_fieldSSOApplication";

const Configuration = ({ title, value, onChange, disabled }) => {
  return (
    <div className={"my-2 p-2 border"}>
      <h2 className={"text-center"}>{title}</h2>
      <FieldSSOApplications
        systemType={"ciem"}
        disabled={disabled}
        application={value.application}
        endpoint={value.endpoint}
        onChange={(data) =>
          onChange({
            ...value,
            application: data.application,
            endpoint: data.endpoint,
          })
        }
      />
    </div>
  );
};

const CiemConfiguration = ({ organisation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [orgConfig, setOrgConfig] = useState("");
  const [config, setConfig] = useState({
    isEnabled: false,
    primary: {
      application: "",
      endpoint: ""
    },
    backup: {
      application: "",
      endpoint: ""
    },
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSubmitError("");
    API.org.settings.systems
    .setConfig(organisation.data._id, "ciem", config)
    .then(() => {
      getData();
    })
    .catch((err) => {
      setSubmitError(err.response.data.message);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };
  const getData = () => {
    API.org.settings.systems
    .getConfig(organisation.data._id, "ciem")
    .then((res) => {
      setConfig((a) => {
        const b = {
          ...a,
          ...res,
          primary: {
            ...a.primary,
            ...res.primary,
          },
          backup: {
            ...a.backup,
            ...res.backup,
          },
        };
        setOrgConfig(JSON.stringify(b));
        return b;
      });
    })
    .catch((err) => {})
    .finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (JSON.stringify(config) !== orgConfig) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [config]);

  if (isLoading) {
    return <Loader>Getting CiEM Config...</Loader>;
  }

  return (
    <div
      className={`border ${
        isModified ? "border-yellow-800" : "border-green-800"
      } rounded-xl p-2`}
    >
      <h1 className={"text-xl"}>CiEM</h1>
      {isSubmitting && (
        <div className={"p-2 bg-blue-600"}>Saving changes...</div>
      )}
      {submitError && <div className={"p-2 bg-red-600"}>{submitError}</div>}
      <CheckboxSlider
        disabled={isSubmitting}
        title={"Enabled"}
        onChange={(a) => setConfig({ ...config, isEnabled: a })}
        isChecked={config.isEnabled}
      />

      {config.isEnabled && (
        <Configuration
          disabled={isSubmitting}
          organisation={organisation}
          title={"For SarSYS Primary"}
          value={config.primary}
          onChange={(a) => setConfig({ ...config, primary: a })}
        />
      )}
      {config.isEnabled && (
        <Configuration
          disabled={isSubmitting}
          organisation={organisation}
          title={"For SarSYS Backup"}
          value={config.backup}
          onChange={(a) => setConfig({ ...config, backup: a })}
        />
      )}

      {isModified && (
        <button
          disabled={isSubmitting}
          className="bg-green-600 text-white rounded-xl p-2 mt-4 float-right"
          onClick={() => handleSubmit()}
        >
          Save Changes
        </button>
      )}
      <div className={"clear-both"}>{""}</div>
    </div>
  );
};
export default Organisation_Receiver(CiemConfiguration, (organisation) => {
  return { organisation };
});