import React, {useEffect, useState} from "react";
import {Organisation_Receiver} from "../../../../../context/organisation";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";
import Select from "../../../../../shared/components/Select";
import FieldSingleSignOnAppsAndEndpoints from "./_fieldSSOApplication";
import API from "../../../../../shared/API";
import Loader from "../../../../../shared/components/Loader";

const teamSiteFlavors = [
  {
    title: "SarStuff TeamSite",
    flavour: "teamsite",
    modules: []
  },
  {
    title: "D4H",
    flavour: "d4h",
    modules: []
  }
];

const TeamSiteConfiguration = ({organisation}) => {
  const [isLoading, setIsLoading]       = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError]   = useState("");
  const [isModified, setIsModified]     = useState(false);
  const [orgConfig, setOrgConfig]       = useState("");
  const [config, setConfig]             = useState({
    isEnabled: false,
    flavour: "",
    application: "",
    endpoint: "",
    access_token: ""
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSubmitError("");
    API.org.settings.systems.setConfig(organisation.data._id, "teamsite", config).then(() => {
      getData();
      organisation._refresh();
    }).catch(err => {
      setSubmitError(err.response.data.message);
    }).finally(() => {
      setIsSubmitting(false);
    });
  };
  const getData      = () => {
    API.org.settings.systems.getConfig(organisation.data._id, "teamsite").then(res => {
      setConfig(a => {
        const b = {
          ...a,
          ...res
        };
        setOrgConfig(JSON.stringify(b));
        return b;
      });
    }).catch(err => {

    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (JSON.stringify(config) !== orgConfig){
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [config]);

  const onChangeHandler = e => {
    setConfig({...config, [e.target.name]: e.target.value});
  }

  if (isLoading){
    return (
      <Loader>Getting TeamSite Config...</Loader>
    );
  }

  return (
    <div className={`border ${isModified ? "border-yellow-800" : "border-green-800"} rounded-xl p-2`}>
      <h1 className={"text-xl"}>TeamSite</h1>
      {isSubmitting && <div className={"p-2 bg-blue-600"}>Saving changes...</div>}
      {submitError && <div className={"p-2 bg-red-600"}>{submitError}</div>}
      <CheckboxSlider title={"Enabled"} onChange={a => setConfig({...config, isEnabled: a})} isChecked={config.isEnabled}/>

      {config.isEnabled && <div className={"my-2 p-2 border"}>
        <Select
          label={"Flavour"}
          field={"flavour"}
          value={config.flavour}
          onChange={onChangeHandler}
          disabled={isSubmitting}
          options={teamSiteFlavors.map(a => {
            return {
              text: a.title,
              value: a.flavour,
              key: a.flavour
            };
          })} />
        {config.flavour === "teamsite" && <FieldSingleSignOnAppsAndEndpoints
          systemType={"teamsite"}
          disabled={isSubmitting}
          application={config.application}
          endpoint={config.endpoint}
          onChange={data => {
            setConfig({
              ...config,
              application: data.application,
              endpoint: data.endpoint
            })
          }} />}
        {config.flavour === "d4h" && <>
          <div className="flex flex-col gap-1 w-full">
            <label>Access Token</label>
            <input
              className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
              type={"text"}
              name={"access_token"}
              autoComplete={"none"}
              value={config.access_token}
              onChange={onChangeHandler}
              disabled={isSubmitting}
            />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label>Endpoint</label>
            <input
              className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
              type={"text"}
              name={"endpoint"}
              autoComplete={"none"}
              value={config.endpoint}
              onChange={onChangeHandler}
              disabled={isSubmitting}
            />
          </div>
        </>}
      </div>}
      {isModified && <button disabled={isSubmitting} className="bg-green-600 text-white rounded-xl p-2 mt-4 float-right" onClick={() => handleSubmit()}>Save Changes</button>}
      <div className={"clear-both"}>{""}</div>
    </div>
  );
}
export default Organisation_Receiver(TeamSiteConfiguration, (organisation) => {
  return {organisation};
});