import React from "react";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import {PhoneIcon} from "@heroicons/react/outline";
import {NavLink, Route, Switch} from "react-router-dom";
import {Organisation_Receiver} from "../../../context/organisation";
import Dashboard from "./dashboard";
import CallFlow from "./call_flows";
import CallRecordings from "./call_recordings";
// import CallLogs from "./call_logs";

const TelephoneIndex = ({ organisation }) => {

  return (
    <div>
      <OrganisationPageHeader title={"Telephone"} subTitle={"Manage your organisations callout phone line."}
                              subIcon={<PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>}/>
      <div className="block">
        <div className="border-b border-gray-700">
          <nav className="-mb-px flex space-x-8 ml-2">
            {/*<NavLink*/}
            {/*  to={`${organisation._urlPrefix}/telephone`}*/}
            {/*  isActive={(m, l) => {*/}
            {/*    return l.pathname.indexOf("logs") === -1 && l.pathname.indexOf("call_flow") === -1;*/}
            {/*  }}*/}
            {/*  activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"*/}
            {/*  className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}*/}
            {/*>Dashboard</NavLink>*/}
            <NavLink
              to={`${organisation._urlPrefix}/telephone/call_flow`}
              exact
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Call Flow</NavLink>
            <NavLink
              to={`${organisation._urlPrefix}/telephone/call_recordings`}
              exact
              activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
              className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}
            >Call Recordings</NavLink>
            {/*<NavLink*/}
            {/*  to={`${organisation._urlPrefix}/telephone/logs`}*/}
            {/*  exact*/}
            {/*  activeClassName="border-amber-500 text-amber-600 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"*/}
            {/*  className={"border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500 whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"}*/}
            {/*>Call Logs</NavLink>*/}
          </nav>
        </div>
      </div>
      <Switch>
        <Route exact path={`${organisation._urlPrefix}/telephone`} component={Dashboard} />
        <Route exact path={`${organisation._urlPrefix}/telephone/call_flow`} component={CallFlow} />
        <Route exact path={`${organisation._urlPrefix}/telephone/call_recordings`} component={CallRecordings} />
        {/*<Route exact path={`${organisation._urlPrefix}/telephone/logs`} component={CallLogs} />*/}
      </Switch>
    </div>
  );
}
export default Organisation_Receiver(TelephoneIndex, (organisation) => {
  return {organisation};
});