import React, {useState, useEffect, useCallback} from "react";
import {Organisation_Receiver} from "../../../../context/organisation";
import Modal from "../../../../shared/components/Modal";
import API from "../../../../shared/API"
import Toast from "react-hot-toast";
import DayJS from "dayjs";

const CreditAdjustment = ({organisation, dataReload, totalCredits, onClose}) => {
  const [creditLog, setCreditLog] = useState([]);
  const [credits, setCredits] = useState(0);
  const [notes, setNotes] = useState("");

  const getData = useCallback(() => {
    API.management.sms.organisations.credits.adjustmentLog(organisation.data._id).then(res => {
      setCreditLog(res);
    })
  }, [organisation])

  useEffect(() => {
    getData();
  }, [getData]);

  const handleAdjustment = () => {
    API.management.sms.organisations.credits.adjust(organisation.data._id, {
      credits,
      notes
    }).then(() => {
      getData();
      dataReload();
      setNotes("")
      setCredits(0);
      Toast.success("Successfully adjusted credits");
    }).catch(err => {
      Toast.error(err.response.data.message);
    })
  }

  return (
    <Modal title={"Credit Adjustment | Credits: " + totalCredits} subTitle={">> " + organisation.data.name + " <<"} onClose={onClose}>
      <div className="py-2 flex gap-4 text-white">
        <div className="flex flex-col gap-1 w-50">
          <label>Credits</label>
          <input
            className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
            type={"number"}
            name={"credits"}
            autoComplete={"none"}
            value={credits}
            onChange={e => setCredits(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label>Notes</label>
          <input
            className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
            type={"text"}
            name={"access_token"}
            autoComplete={"none"}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
        <button className={"p-2 bg-green-600 rounded border border-green-200 disabled:bg-gray-700 disabled:border-green-700"} onClick={handleAdjustment}>Adjust</button>
      </div>
      <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border border-gray-700">
        <thead>
          <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Date</th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Credit(s)</th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Notes</th>
          </tr>
        </thead>
        <tbody>
        {creditLog.map(item => {
          return (<tr key={item._id} className={"items-center divide-x-2 divide-gray-700 hover:bg-gray-900"}>
            <td className={"px-2 py-2 text-md text-gray-200 w-52"}>{DayJS(item.createdAt).format("DD/MM/YYYY hh:mma")}</td>
            <td className={"px-2 py-2 text-md text-gray-200 w-24"}>{item.credits}</td>
            <td className={"px-2 py-2 text-md text-gray-200"}>{item.note}</td>
          </tr>);
        })}</tbody>
      </table>

      <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
        <button className="px-4 py-2 bg-blue-400 rounded-lg" onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
}
export default Organisation_Receiver(CreditAdjustment, (organisation) => {
  return {organisation};
});