import Select from "../../../../shared/components/Select";
import React, {useEffect, useState} from "react";
import API from "../../../../shared/API";
import Loader from "../../../../shared/components/Loader";

const AssetForm = ({asset, onSubmit, onClose, onDelete, organisation}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [unit_members, setUnit_members] = useState([]);
  const [data, setData] = useState({
    title: "",
    type: "",
    assetID: "",
    assetFeed: "",
  });

  useEffect(() => {
    if (asset !== undefined && asset._id !== undefined) {
      setData(asset);
    }
  }, [asset]);
  useEffect(() => {
    getTeam();
  }, [organisation]);

  const getTeam = () => {
    API.org.users.all(organisation.data._id).then((res) => {
      setUnit_members(res.filter((item) => item.isDisabled === false).map((i) => {
        return {
          key: i._id,
          value: i._id,
          text: (i.teamId !== undefined && i.teamId.trim().length > 0 ? "[" + i.teamId + "] " : "") + i.firstName + " " + i.surname,
        };
      }));
    }).catch((error) => {
      console.log(error);
    });
  };
  const handleSubmit = () => {
    setSubmitError(null);
    setIsSubmitting(true);
    onSubmit(data).catch(err => {
      setIsSubmitting(false);
      if (err.isAxiosError) {
        setSubmitError(err.response.data.message);
        return;
      }
      setSubmitError("There was an unexpected error while processing your request, please try again later.");
    });
  }

  if (isSubmitting) {
    return (
      <div className={"text-white"}>
        <Loader>Sending form data...</Loader>
      </div>
    );
  }

  return (
    <div>
      {submitError && <div className={"p-2 m-1 bg-red-700 text-white border border-red-400"}>{submitError}</div>}
      <div className="pt-4">
        <div className="grid grid-cols-2 gap-4 my-2">
          <div>
            <label className="text-gray-300 block text-md font-medium font-mono">
              Asset Title
            </label>
            <input
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              value={data.title}
              disabled={isSubmitting}
              onChange={(e) =>
                setData({
                  ...data,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label className="text-gray-300 block text-md font-medium font-mono">
              Asset ID
            </label>
            {data.assetFeed === "app" ? <span className={"pt-2 text-white"}>{data.assetID}</span> : <input
              className={
                "p-2 bg-gray-900 rounded border-gray-700 border text-gray-200 w-full "
              }
              value={data.assetID}
              disabled={isSubmitting || data.assetFeed === "app"}
              onChange={(e) =>
                setData({
                  ...data,
                  assetID: e.target.value,
                })
              }
            />}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div>
            {data.assetFeed === "app" ? <>
              <label className="text-gray-300 block text-md font-medium font-mono">Device Type</label>
              <span className={"pt-2 text-white"}>Mobile Phone</span>
            </> : <Select
              name="type"
              label={"Device Type"}
              value={data.type}
              disabled={isSubmitting}
              onChange={(e) =>
                setData({
                  ...data,
                  type: e.target.value,
                })
              }
              options={[
                {key: "Vehicle", value: "Vehicle", text: "Vehicle"},
                {key: "Handset", value: "Handset", text: "Handset"},
                {key: "Device", value: "Device", text: "Device"},
                {key: "Other", value: "Other", text: "Other"},
              ]}
            />}
          </div>
          <div>
            {data.assetFeed === "app" ? <>
              <label className="text-gray-300 block text-md font-medium font-mono">Asset Feed</label>
              <span className={"pt-2 text-white"}>SarStuff Tracker App</span>
            </> : <Select
              name="assetFeed"
              label={"Asset Feed"}
              value={data.assetFeed}
              disabled={isSubmitting}
              onChange={(e) =>
                setData({
                  ...data,
                  assetFeed: e.target.value,
                })
              }
              options={[
                {
                  key: "smc_gateway",
                  value: "smc_gateway",
                  text: "Radio Network",
                },
                {
                  key: "broadnet",
                  value: "broadnet",
                  text: "BroadNet",
                },
                {
                  key: "zello",
                  value: "zello",
                  text: "Zello",
                },
                {
                  key: "quartix",
                  value: "quartix",
                  text: "Quartix",
                }
              ]}
            />}
          </div>
          <div>
            <Select
              label={"Assigned To"}
              name="sarstuffCentral_id"
              value={data.sarstuffCentral_id}
              disabled={isSubmitting}
              onChange={(e) =>
                setData({
                  ...data,
                  sarstuffCentral_id: e.target.value,
                })
              }
              options={unit_members}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
        <button
          className="px-4 py-2 bg-blue-600 tracking-wider hover:bg-blue-800 border border-blue-500 text-white text-2xl rounded-lg"
          onClick={onClose}
        >
          Close
        </button>
          {data._id !== undefined && onDelete && <button
            disabled={isSubmitting}
            onClick={() => onDelete()}
            className={
              "p-2 bg-red-600 hover:bg-red-800 border border-red-400 rounded text-white text-2xl"
            }
          >
            Delete
          </button>}
          <button
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
            className={
              "p-2 bg-green-600 tracking-wider hover:bg-green-800 border border-green-400 rounded text-white text-2xl"
            }
          >
            Save
          </button>
      </div>
    </div>
  );
}
export default AssetForm;