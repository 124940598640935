import React from "react";
import {Route, Switch} from "react-router-dom";
import {Organisation_Receiver} from "../../../context/organisation";
import Assets from "./assets";
import Asset from "./asset";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import {VideoCameraIcon} from "@heroicons/react/outline";

const VideoFeedsIndex = ({organisation}) => {

    return (
        <div>
            <OrganisationPageHeader title={"Video Feeds"} subTitle={"Manage your organisations video feeds from devices like drones that are viewable within SarSYS."}
                                    subIcon={<VideoCameraIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>}/>
            <Switch>
                <Route exact path={`${organisation._urlPrefix}/video_feeds/:assetId`} component={Asset}/>
                <Route exact path={`${organisation._urlPrefix}/video_feeds`} component={Assets}/>
            </Switch>
        </div>
    );
}
export default Organisation_Receiver(VideoFeedsIndex, (organisation) => {
    return {organisation};
});