import {Route, Switch} from "react-router-dom";
import GroupPage from "./group";
import ListGroups from "./groups";
import {Organisation_Receiver} from "../../../../context/organisation";

const SecurityGroups_Index = ({organisation}) => {

  return (
    <Switch>
      <Route exact path={`${organisation._urlPrefix}/team/security_groups/:groupid`} component={GroupPage} />
      <Route exact path={`${organisation._urlPrefix}/team/security_groups`} component={ListGroups} />
    </Switch>
  );
}
export default Organisation_Receiver(SecurityGroups_Index, (organisation) => {
  return {organisation};
});