import React from "react";
import {NavLink} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/outline";

const ReturnHeadingBar = ({returnTitle = "Return", title, linkTo}) => {
    return (
        <div className="bg-gray-700 border-b pb-1 border-gray-500 shadow">
            <div className="px-1 pr-3 sm:px-6 w-full lg:mx-auto lg:px-1 lg:pr-3">
                <div className="py-1 md:flex md:items-center md:justify-between">
                    <NavLink
                        to={linkTo}
                        exact
                        activeClassName="bg-gray-800 text-gray-300 rounded-lg"
                        className={"text-amber-500 hover:bg-gray-700  group flex items-center px-2 py-2 text-sm font-medium rounded-md"}
                    >
                        <ChevronLeftIcon className={"text-amber-500 group-hover:text-gray-500 mr-3 h-6 w-6"} aria-hidden="true"/>
                        {returnTitle}
                    </NavLink>
                    <h1 className="tracking-widest ml-3 text-2xl font-bold leading-7 text-gray-200 sm:leading-9 sm:truncate">
                        {title}
                    </h1>
                </div>
            </div>
        </div>
    );
}
export default ReturnHeadingBar;