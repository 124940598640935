import React, {useState, useEffect} from "react";
import {Dialog, Transition} from "@headlessui/react";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {Organisation_Receiver} from "../../../../context/organisation";
import ReturnHeadingBar from "../../../../shared/components/ReturnHeadingBar";
import API from "../../../../shared/API";

function GroupPage({organisation, history}){
    const {groupid}                         = useParams();
    const [group, setGroup]                 = useState([]);
    const [removeModal, setRemoveModal]     = useState(false);
    const [addUser, setAddUser]             = useState(false);
    const [groupUsers, setGroupUsers]       = useState([]);
    const [users, setUsers]                 = useState([]);
    const [removeUser, setRemoveUser]       = useState({});
    const [addUserSearch, setAddUserSearch] = useState("");
    const [errorMessage, setErrorMessage]   = useState("");
    const [loading, setLoading]             = useState(true);
    const org                               = organisation.data;

    useEffect(() => {
        getData();
    }, []);

    const getData             = () => {
        Promise.all([
            API.org.users.all(org._id),
            API.org.groups.show(org._id, groupid),
            API.org.groups.users.all(org._id, groupid),
        ])
            .then((res) => {
                setUsers(
                    res[0].filter((item) => {
                        return item.isDisabled === false;
                    })
                );
                setGroup(res[1]);
                setGroupUsers(res[2]);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const addUserToGroup      = (user) => {
        API.org.groups.users
            .add(org._id, groupid, user._id)
            .then((res) => {
                toast.success("Successfully added user to the group.", {
                    duration: 2000,
                });
                getData();
            })
            .catch((err) => {
                let errorMessage =
                        "There was an unknown network error while getting data from the server";
                if (err.response !== undefined){
                    if (err.response.data.message !== undefined){
                        errorMessage = err.response.data.message;
                    }
                }
                setErrorMessage(errorMessage);
            });
    };
    const closeAdd            = () => {
        setAddUser(false);
        setAddUserSearch("");
    };
    const closeRemove         = () => {
        setRemoveModal(false);
        setRemoveUser({});
    };
    const openRemove          = (user) => {
        setRemoveModal(true);
        setRemoveUser(user);
    };
    const removeUserFromGroup = () => {
        API.org.groups.users
            .remove(org._id, groupid, removeUser._id)
            .then(() => {
                getData();
                toast.success("Successfully removed user from the group.", {
                    duration: 2000,
                });
            })
            .finally(() => {
                setRemoveModal(false);
                setRemoveUser({});
            });
    };
    const CloseDialog         = () => {
        return (
            <Transition
                show={removeModal}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Dialog
                    open={removeModal}
                    onClose={() => setRemoveModal(false)}
                    className="fixed z-10 inset-0 overflow-y-auto "
                >
                    <div className="text-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-900 shadow-xl rounded-2xl">
                            <div className=" text-white text-2xl">
                                <Dialog.Title>
                                    Remove{" "}
                                    <span className="italic text-amber-500">
                    {removeUser.firstName}
                  </span>{" "}
                                    from group.
                                </Dialog.Title>
                            </div>

                            <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                                <button
                                    className="px-4 py-2 bg-blue-400 rounded-lg"
                                    onClick={closeRemove}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-4 py-2 bg-red-500 text-white rounded-lg disabled:bg-red-500"
                                    onClick={removeUserFromGroup}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        );
    };
    const AddDialog           = () => {
        if (users.length){
            let filteredUsers = users.filter(
                (item) =>
                    item.firstName.toLowerCase().indexOf(addUserSearch.toLowerCase()) !==
                    -1 ||
                    item.surname.toLowerCase().indexOf(addUserSearch.toLowerCase()) !== -1
            );
            return (
                <Transition
                    show={addUser}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Dialog
                        open={addUser}
                        onClose={() => setAddUser(false)}
                        className="fixed z-10 inset-0 overflow-y-auto "
                    >
                        <div className="text-center min-h-screen">
                            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80"/>
                            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                            <div className="border-4 border-gray-700 inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-900 shadow-xl rounded-2xl">
                                <div className=" text-white text-2xl">
                                    <Dialog.Title>Assign users to security group</Dialog.Title>
                                </div>
                                <div className="p-2">
                                    <input
                                        className="bg-gray-800 p-2 text-white w-full rounded-xl border border-gray-700 m-2"
                                        placeholder="search by name..."
                                        autoComplete="off"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setAddUserSearch(e.target.value);
                                        }}
                                    />
                                    <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border border-gray-700">
                                        <thead className="">
                                        <tr className="rounded-md bg-gray-900">
                                            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>
                                            <th className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                                                Callsign
                                            </th>
                                            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Email & phone
                                            </th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-black divide-y divide-gray-700">
                                        {filteredUsers.map((o) => {
                                            let disabled = false;
                                            disabled     =
                                                groupUsers.filter((i) => i._id === o._id).length >= 1;

                                            return (
                                                <tr
                                                    key={o._id}
                                                    // onClick={() => goTo(o._id)}
                                                    className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
                                                >
                                                    <td className="px-2 py-2 text-md text-gray-200">
                                                        {o.fullName}
                                                    </td>
                                                    <td className="px-2 py-2 text-md text-center text-gray-200">
                                                        {o.teamId}
                                                    </td>
                                                    <td className="px-2 py-2 text-md text-gray-200 items-center ">
                                                        <div className="flex flex-wrap">
                                                            {o.email}
                                                            <br/>
                                                            {o.phone}
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-2 text-md text-center text-gray-200">
                                                        <button
                                                            onClick={() => addUserToGroup(o)}
                                                            disabled={disabled}
                                                            className={`${
                                                                disabled
                                                                    ? "bg-red-900 opacity-20"
                                                                    : "bg-green-900"
                                                            }  rounded-xl p-2 border border-gray-700`}
                                                        >
                                                            Add
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                                    <button
                                        className="px-4 py-2 bg-blue-400 rounded-lg"
                                        onClick={closeAdd}
                                    >Close</button>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            );
        }
    };

    return (
        <div>
            {AddDialog()}
            {CloseDialog()}

            <ReturnHeadingBar title={group.name} linkTo={`${organisation._urlPrefix}/team/security_groups`}/>

            <div className="p-4">
                <div className="bg-gray-800 rounded-xl p-2 border border-gray-700 my-4">
                    {group.description}
                </div>
            </div>
            <div className="pb-4 mb-2">
                <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
                    <thead>
                        <tr className="rounded-md bg-gray-900">
                            <th className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                            <th className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase w-24">
                                <button onClick={() => setAddUser(true)} className="bg-green-700 rounded-xl p-2 border border-green-400 text-white">Assign</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-black divide-y divide-gray-700">
                        {groupUsers.length === 0 && (<tr>
                            <td colSpan={2} className={"p-4"}>There are no users assigned to this security group.</td>
                        </tr>)}
                        {groupUsers.map((u) => {
                            return (<tr key={u._id} className="items-center divide-x-2 divide-gray-700 ">
                                <td className="px-2 py-2 text-md text-gray-200">{u.firstName} {u.surname}</td>
                                <td className="px-2 py-2 text-md text-gray-200">
                                    <button onClick={() => openRemove(u)} className="bg-red-900 rounded-xl p-2 border border-red-400">Remove</button>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Organisation_Receiver(GroupPage, (organisation) => {
    return {organisation};
});