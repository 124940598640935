import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { withRouter } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import Logo from "../../assets/images/central.png";
import Organisation_Menu from "../../_app/organisation/_SideMenuItems";
import SarStuff_Menu from "../../_app/sarstuff/_SideMenuItems";

function SideBar({
  sidebarOpen,
  setSidebarOpen,
  history,
  menuType,
  isSarStuff = false,
  urlPrefix = "",
}) {
  const [user, setUser] = useState({});
  const getData = () => {
    const user = Authentication.userData;
    setUser(user);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/** Mobile Menu **/}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800 focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div
                  onClick={() => history.push("/")}
                  className="tracking-widest text-gray-500 flex-shrink-0 flex items-center px-4 cursor-pointer"
                >
                  <img src={Logo} className="w-40" />
                </div>
                <nav aria-label="Sidebar" className="mt-5">
                  <div className="px-2 space-y-1">
                    {menuType === "organisation" && (
                      <Organisation_Menu
                        isSarStuff={isSarStuff}
                        urlPrefix={urlPrefix}
                      />
                    )}
                    {menuType === "sarstuff" && <SarStuff_Menu />}
                  </div>
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <div className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-400 group-hover:text-gray-900">
                        {user.name}
                      </p>
                      <div
                        onClick={() => Authentication.logout()}
                        className="text-sm font-medium text-red-500 group-hover:text-gray-700"
                      >
                        Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/** Full Size Menu **/}
      <div className="hidden lg:flex lg:flex-shrink-0 h-full">
        <div className="flex flex-col w-52">
          <div className=" flex-1 flex flex-col min-h-0 border-r bg-gray-900 text-gray-200 border-gray-700">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div
                onClick={() => history.push("/")}
                className="text-lg cursor-pointer flex items-center flex-shrink-0 px-2 tracking-widest text-gray-500 text-center mx-auto border-b border-gray-500"
              >
                <img src={Logo} className="mb-4 p-2" />
              </div>
              <nav className="mt-5 flex-1" aria-label="Sidebar">
                <div className="px-2 space-y-4">
                  {menuType === "organisation" && (
                    <Organisation_Menu
                      isSarStuff={isSarStuff}
                      urlPrefix={urlPrefix}
                    />
                  )}
                  {menuType === "sarstuff" && <SarStuff_Menu />}
                </div>
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-500 p-2">
              <div className="flex-shrink-0 w-full group block">
                <div className="flex items-center justify-center">
                  <div className=" px-4 py-2">
                    <p className="text-sm font-medium text-gray-300 my-2">
                      {user.name}
                    </p>
                    <div
                      onClick={() => Authentication.logout()}
                      className="rounded-lg bg-gray-800 px-4 py-2 cursor-pointer text-center text-md font-medium text-red-500 hover:bg-gray-700"
                    >
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(SideBar);
