import React from "react";
import PhoneInput from 'react-phone-number-input/mobile'
import 'react-phone-number-input/style.css'

const Field_PhoneNumber = ({ title, name, error, value, isDisabled, onChange, defaultCountry = "GB", labelClassName = "", hideInfo = false }) => {

  return (
    <>
      <label htmlFor={name} className={labelClassName}>{title}</label>
      {!hideInfo && <div className={"border border-white p-2 my-2 bg-blue-800 text-white"}>Select the flag to change country and enter the phone number without leading zeros OR enter the international phone number starting with a + followed by country code and the phone number i.e: +447123456789, +9112345678901.</div>}
      <PhoneInput
        placeholder="+447123456789"
        defaultCountry={defaultCountry}
        value={value}
        autoComplete={"off"}
        disabled={isDisabled}
        className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
        onChange={val => onChange(val)}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
}
export default Field_PhoneNumber;