import React, {useState, useEffect} from "react";
import StatBox from "../../../shared/components/StatBox";
import API from "../../../shared/API";
import AlertMessage from "../../../shared/components/AlertMessage";

const SarSYS_Stats = ({organisation}) => {
  const [hideStats, setHideStats]       = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading]           = useState(true);
  const [stats, setStats]               = useState({});

  useEffect(() => {
    API.org.dashboard.sarsys_stats(organisation.data._id).then(res => {
      if (res === false){
        setHideStats(true);
        return;
      }
      setStats(res);
      setLoading(false);
    }).catch(err => {
      setLoadingError(true);
    })
  }, [organisation]);

  return (
    <div className={"mt-2"}>
      <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
        SarSYS Stats
        {/*<button onClick={() => {*/}
        {/*  alert("Coming soon...");*/}
        {/*}} className="text-sm bg-gray-800 py-1 px-4 rounded-xl border-gray-700 border hover:bg-gray-900">*/}
        {/*  View Reporting*/}
        {/*</button>*/}
      </div>

      <div className="px-2 pb-2 pt-1 w-full border border-gray-700 bg-gray-900 rounded-b-xl justify-around flex flex-col">
        {loadingError && <AlertMessage.Error>Unable to query SarSYS at this time, please try again later.</AlertMessage.Error>}
        {hideStats && <AlertMessage.Info>Your organisation does not have SarSYS or runs a older version, no stats can be displayed.</AlertMessage.Info>}
        {!hideStats && !loadingError && <div className="pt-2 grid grid-cols-4 text-center gap-2">
          <StatBox label="Total" value={stats.total} loading={loading}/>
          <StatBox label="Last Year" value={stats.lastYear} loading={loading}/>
          <StatBox label="This Year" value={stats.thisYear} loading={loading}/>
          <StatBox label="This Month" value={stats.thisMonth} loading={loading}/>
        </div>}
      </div>
    </div>
  );
}
export default SarSYS_Stats;