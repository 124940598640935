import React, { Component } from "react";

export default class ErrorPage extends Component {
  render() {
    return (
      <div>
        <div className="p-2 text-center">
          <div className="text-xl p-2 text-red-800">Page Not Found</div>
          Opps, it looks like you have found a page that does not exist.
        </div>
      </div>
    );
  }
}
