import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import SarStuffErrorPage from "./pages/NotFound";
import OrganisationsIndex from "./pages/organisations";
import SMS from "./pages/sms";
import Telephone from "./pages/telephone"
import Applications from "./pages/applications"
import MenuHolder from "../../shared/components/MenuHolder";
import ManageOrganisation from "./pages/organisations/manage_organisation";

const SarStuffManagementRoutes = () => {
  return (
    <MenuHolder type={"sarstuff"}>
      <Switch>
        {/* <Route path="/systems/:sysid" component={SystemPage} />
         <Route path="/systems" component={Systems} />
         <Route path="/settings" component={SettingsPage} />
         <Route exact path="/applications/:appid" component={ApplicationPage} /> */}
        <Route path="/applications" component={Applications} />
        <Route path="/organisations" component={OrganisationsIndex}/>
        <Route path="/sms" component={SMS}/>
        <Route path="/telephone" component={Telephone}/>
        <Route exact path="/" component={Home}/>
      </Switch>
    </MenuHolder>
  );
}

const SarStuff = () => {
  return (
    <Switch>
      <Route path="/organisations/manage/:organisation_id" component={ManageOrganisation}/>
      <Route path="/" component={SarStuffManagementRoutes}/>
      <Route path="*" component={SarStuffErrorPage}/>
    </Switch>

  );
};
export default SarStuff;
