import React, {useEffect, useState} from "react";
import {FaWindowClose} from "react-icons/fa";
import Loader from "../../../../shared/components/Loader";
import Select from "../../../../shared/components/Select";
import API from "../../../../shared/API";
import AlertMessage from "../../../../shared/components/AlertMessage";

const OrganisationList = ({ organisation }) => {
  const [pickableOrganisations, setPickableOrganisations] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMyOrganisations();
  }, [organisation]);

  const getMyOrganisations = () => {
    Promise.all([
      API.org.sarsys_request.allowJobsFrom.pickable(organisation.data._id),
      API.org.sarsys_request.allowJobsFrom.pickedOrganisations(organisation.data._id)
    ]).then(res => {
      setPickableOrganisations(res[0]);
      setData(res[1]);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };
  const handleRemoveOrg = (id) => {
    API.org.sarsys_request.allowJobsFrom.removeOrganisation(organisation.data._id, id).then(() => {
      getMyOrganisations();
    });
  };
  const handleAddOrg = (id) => {
    API.org.sarsys_request.allowJobsFrom.addOrganisation(organisation.data._id, id).then(() => {
      getMyOrganisations();
    });
  };

  if(loading){
    return (
      <div className="w-full">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Select
        label=""
        field="org"
        onChange={e => handleAddOrg(e.target.value)}
        value={""}
        options={pickableOrganisations.filter(org => {
          if(org._id === organisation.data._id) return false;
          const bob = data.filter(o => org._id === o);
          return bob[0] === undefined;
        }).map(o => {
          return { key: o._id, text: o.name, value: o._id };
        })}
      />
      <div className="mt-2 border-t border-gray-700 py-2">
        {data.map((d, i) => {
          let bob = pickableOrganisations.filter((o) => o._id === d);
          if (bob[0] === undefined) {
            return null;
          }
          return (
            <div className="w-full bg-gray-900 p-2 border-b border-gray-700 flex gap-4  text-xl" key={i}>
              {bob[0].name}
              <button onClick={() => handleRemoveOrg(bob[0]._id)} className="text-red-500 text-xl">
                <FaWindowClose />
              </button>
            </div>
          );
        }).filter((a) => a !== null)}
        {data.length === 0 && <AlertMessage.Info>Please select organisations that you will accept SarSYS Request from.</AlertMessage.Info>}
      </div>
    </>
  );
}
export default OrganisationList;