import React, {useCallback, useEffect, useState} from "react";
import {SupportIcon} from "@heroicons/react/outline";
import {Organisation_Receiver} from "../../../context/organisation";
import NotifyEmails from "../_components/notify_emails";
import NotifySMS from "../_components/notify_sms";
import OrganisationPageHeader from "../_components/organisationPageHeader";
import OrganisationList from "./_components/organisation_list";
import JobTemplates from "./_components/job_templates";
import AlertMessage from "../../../shared/components/AlertMessage";
import API from "../../../shared/API";

function SarSYSRequest({organisation}) {
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [pickableRecipients, setPickableRecipients] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const getEmailList = useCallback(() => {
    API.org.sarsys_request.notify_email.getAll(organisation.data._id).then((res) => {
      setEmailRecipients(res);
    }).catch((err) => {
      //TODO
    });
  }, [organisation]);
  const getSmsList = useCallback(() => {
    API.org.sarsys_request.notify_sms.getAll(organisation.data._id).then(res => setRecipients(res)).catch((err) => {
      //TODO
    });
    API.org.sarsys_request.notify_sms.pickable(organisation.data._id).then(res => setPickableRecipients(res)).catch((err) => {
      //TODO
    });
  }, [organisation])

  useEffect(() => {
    organisation._refresh();
  }, []);
  useEffect(() => {
    getEmailList();
    getSmsList();
  }, [getEmailList, getSmsList]);

  const handleEmailCreate = data => {
    return API.org.sarsys_request.notify_email.create(organisation.data._id, data).finally(() => {
      getEmailList();
    })
  };
  const handleEmailUpdate = (id, data) => {
    return API.org.sarsys_request.notify_email.update(organisation.data._id, id, data).finally(() => {
      getEmailList();
    });
  };
  const handleEmailDelete = id => {
    return API.org.sarsys_request.notify_email.remove(organisation.data._id, id).finally(() => {
      getEmailList();
    });
  };

  const handleSmsCreate = data => {
    return API.org.sarsys_request.notify_sms.create(organisation.data._id, data).finally(() => {
      getSmsList();
    })
  };
  const handleSmsUpdate = (id, data) => {
    return API.org.sarsys_request.notify_sms.update(organisation.data._id, id, data).finally(() => {
      getSmsList();
    })
  };
  const handleSmsDelete = id => {
    return API.org.sarsys_request.notify_sms.remove(organisation.data._id, id).finally(() => {
      getSmsList();
    });
  };


  return (
    <div className="w-full bg-black">
      <OrganisationPageHeader
        title={"SarSYS Request"}
        subTitle={"Settings for SarSYS Request."}
        subIcon={<SupportIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>}
      />
      {!organisation.data._systems.sarsys ? <div className="p-2">
        <AlertMessage.Info>
          Your organisation currently does not have SarSYS activated. Please contact the SarStuff sales team to begin your trial.
        </AlertMessage.Info>
      </div> : <div className="p-2">
        <div className="flex flex-col sm:flex-row gap-4 mt-4 h-full">
          <div className={"w-full"}>
            <div className=" p-2 border border-gray-700 rounded-lg">
              <div className=" text-center text-xl  p-4">Job Types</div>
              <JobTemplates organisation={organisation}/>
            </div>
            <div className="mt-2 p-2 border border-gray-700 rounded-lg">
              <div className="text-center text-xl p-4">Organisation Whitelist</div>
              <OrganisationList organisation={organisation}/>
            </div>
          </div>
          <div className="w-full">
            <div className="mt-2 p-2 border border-gray-700 rounded-lg">
              <div className="text-center text-xl p-4">Notification Recipients [Email]</div>
              <NotifyEmails recipients={emailRecipients} onCreate={handleEmailCreate} onUpdate={handleEmailUpdate} onDelete={handleEmailDelete}/>
            </div>
            <div className="mt-2 p-2 border border-gray-700 rounded-lg">
              <div className="text-center text-xl p-4">Notification Recipients [SMS]</div>
              <NotifySMS recipients={recipients} pickableRecipients={pickableRecipients} onCreate={handleSmsCreate} onUpdate={handleSmsUpdate} onDelete={handleSmsDelete} />
            </div>
          </div>
        </div>
      </div>}

    </div>
  );
}
export default Organisation_Receiver(SarSYSRequest, (organisation) => {
  return {organisation};
});