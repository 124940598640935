import React from "react";
import {
  BellIcon,
  SupportIcon,
  CameraIcon,
  ChatIcon,
  ChevronLeftIcon,
  CogIcon,
  HomeIcon,
  IdentificationIcon,
  LocationMarkerIcon,
  UsersIcon,
  VideoCameraIcon,
  PhoneIcon, DocumentSearchIcon
} from "@heroicons/react/outline";
import {NavLink} from "react-router-dom";
import {Organisation_Receiver} from "../../context/organisation";

const Organisation_Menu = ({ urlPrefix, isSarStuff, organisation }) => {

  const menuItems = [
    {
      title: "Dashboard",
      path: `${urlPrefix}/`,
      icon: HomeIcon,
    },
    {
      title: "Team",
      path: `${urlPrefix}/team`,
      icon: UsersIcon,
    },
    {
      title: "SarSYS Request",
      path: `${urlPrefix}/sarsys_request`,
      icon: SupportIcon,
      hide: !organisation.data._systems.sarsys
    },
    {
      title: "SarSYS Review",
      path: `${urlPrefix}/sarsys_review`,
      icon: DocumentSearchIcon,
      isSarStuff: true,
    },
    {
      title: "Contacts [HIDDEN]",
      path: `${urlPrefix}/contacts`,
      icon: IdentificationIcon,
      isSarStuff: true,
    },
    {
      title: "Push Noti... [HIDDEN]",
      path: `${urlPrefix}/push_notifications`,
      icon: BellIcon,
      isSarStuff: true,
    },
    {
      title: "Telephone",
      path: `${urlPrefix}/telephone`,
      icon: PhoneIcon,
    },
    {
      title: "SMS",
      path: `${urlPrefix}/sms`,
      icon: ChatIcon,
    },
    {
      title: "Video Feeds",
      path: `${urlPrefix}/video_feeds`,
      icon: VideoCameraIcon,
    },
    {
      title: "SarMAP",
      path: `${urlPrefix}/sarmap`,
      icon: LocationMarkerIcon,
    },
    {
      title: "CCTV",
      path: `${urlPrefix}/cctv`,
      icon: CameraIcon,
    },
    {
      title: "Settings",
      path: `${urlPrefix}/settings`,
      icon: CogIcon,
    },
  ];

  return (
    <>
      {isSarStuff && (
        <NavLink
          to={`/organisations`}
          exact
          activeClassName="bg-gray-800 text-gray-300 rounded-lg"
          className={"text-amber-500 hover:bg-gray-700  group flex items-center px-2 py-2 text-sm font-medium rounded-md"}
        >
          <ChevronLeftIcon
            className={
              "text-amber-500 group-hover:text-gray-500   mr-3 h-6 w-6"
            }
            aria-hidden="true"
          />
          Return
        </NavLink>
      )}
      {menuItems
        .filter((a) => {
          if (isSarStuff) return true;
          if (a.isSarStuff !== undefined) {
            if (a.isSarStuff === false) return true;
          } else {
            return true;
          }
          return false;
        })
        .filter((a) => {
          if (a.hide !== undefined) {
            return !a.hide
          }
          return true;
        })
        .map((item, index) => {
          const Icon = item.icon;
          return (
            <NavLink
              key={index}
              exact
              to={item.path}
              activeClassName={"bg-gray-800 text-gray-300 rounded-lg"}
              className={`${item.isSarStuff !== undefined && item.isSarStuff ? "text-blue-500" : "text-gray-300"} hover:bg-gray-700  group flex items-center px-2 py-2 text-sm font-medium rounded-md`}
            >
              <Icon
                className={`${item.isSarStuff !== undefined && item.isSarStuff ? "text-blue-500" : "text-gray-500"} group-hover:text-gray-500 mr-3 h-6 w-6`}
                aria-hidden="true"
              />{" "}{item.title}</NavLink>
          );
        })}
    </>
  );
};
export default Organisation_Receiver(Organisation_Menu, (organisation) => {
  return { organisation };
});