import React, { useEffect, useState } from "react";
import Blank from "../../../assets/images/blank.png";
import AppConfig from "../../../_appConfig";
import UpdatePhotoModal from "./UpdatePhotoModal";

const DisplayAndUpdatePhoto = ({
   onGetPhoto,
   onUpload,
   onGetUploadSpec,
   blankImage = Blank,
   canUpdate,
   className,
   popoverText = "Change Profile Photo",
 }) => {
  const [showUpdateHover, setShowUpdateHover] = useState(false);
  const [showUpdateImageForm, setShowUpdateImageForm] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("no_photo");

  useEffect(() => {
    loadPhoto();
  }, [onGetPhoto]);

  const loadPhoto = () => {
    onGetPhoto()
    .then((res) => {
      setPhotoUrl(res.link);
    })
    .catch((e) => {});
  };

  return (
    <>
      {showUpdateImageForm && (
        <UpdatePhotoModal
          onGetUploadSpec={onGetUploadSpec}
          onUpload={(d) =>
            onUpload(d).then((a) => {
              loadPhoto();
              return a;
            })
          }
          onClose={() => setShowUpdateImageForm(false)}
        />
      )}
      <div
        onMouseOver={() => setShowUpdateHover(true)}
        onMouseLeave={() => setShowUpdateHover(false)}
        className={`no-pad no-marg ${showUpdateHover && "cursor-pointer"}`}
        onClick={() => {
          if (canUpdate) {
            setShowUpdateImageForm(true);
          }
        }}
      >
        {showUpdateHover && canUpdate && (
          <div
            className="z-99 absolute"
            onClick={() => setShowUpdateImageForm(true)}
          >
            <div className={"bg-gray-600"}>
              <h2 className={"p-4 text-lg text-white"}>{popoverText}</h2>
            </div>
          </div>
        )}
        {photoUrl !== "no_photo" ? (
          <img
            src={photoUrl.indexOf("base64") === -1 ? AppConfig.api_server + "/.." + photoUrl : photoUrl}
            className={`${className} `}
          />
        ) : (
          <img src={blankImage} className={`${className} `} />
        )}
      </div>
    </>
  );
};
export default DisplayAndUpdatePhoto;
