import React, {useState} from "react";
import {Organisation_Receiver} from "../../../../../context/organisation";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";

const KstatConfiguration = ({organisation}) => {
    const [config, setConfig] = useState({
        isEnabled: false
    });

    // console.log("config", config)

    return (
        <div className={"border border-yellow-800 rounded-xl p-2 mt-3"}>
            <h1 className={"text-xl"}>KSTAT</h1>
            <CheckboxSlider title={"Enabled"} onChange={a => setConfig({...config, isEnabled: a})} isChecked={config.isEnabled} />

        </div>
    );
}
export default Organisation_Receiver(KstatConfiguration, (organisation) => {
    return { organisation };
});