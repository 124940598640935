import React, { useState } from "react";
import {MenuIcon} from "@heroicons/react/outline";
import Logo from "../../assets/images/central.png";
import SideBar from "./SideBar";

export default function MenuHolder({children, history, type, isSarStuff = false, urlPrefix = "",}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className={`h-screen flex bg-gray-800" text-gray-200`}>
      <SideBar
        menuType={type}
        isSarStuff={isSarStuff}
        urlPrefix={urlPrefix}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={() => setSidebarOpen(false)}
      />
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-900   border-b border-gray-200 px-4 py-1.5">
            <div
              className="tracking-widest text-gray-500 "
              onClick={() => history.push("/")}
            >
              <img src={Logo} className="mb-4 w-40" />
            </div>
            <div>
              <button
                type="button"
                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="bg-black flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            {/* Start main area*/}
            <div className="absolute inset-0 ">
              <div className="h-full ">
                <div className="flex flex-col h-screen ">{children}</div>
              </div>
            </div>
            {/* End main area */}
          </main>
        </div>
      </div>
    </div>
  );
}