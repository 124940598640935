import React from "react";
import {Route, Switch} from "react-router-dom";
import ListGateways from "./listGateways";
import CreateGateway from "./createGateway";
import SingleGateway from "./singleGateway";

const GatewaysIndex = () => {
  return (
    <Switch>
      <Route exact path="/sms/gateways" component={ListGateways} />
      <Route exact path="/sms/gateways/create" component={CreateGateway} />
      <Route exact path="/sms/gateways/:gateway_id" component={SingleGateway} />
    </Switch>
  );
}
export default GatewaysIndex;