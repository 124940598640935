import React, { useState, useEffect } from "react";
import API from "../../../shared/API";
import toast from "react-hot-toast";
import Box from "../../../shared/components/Box";
import CheckboxSlider from "../../../shared/components/CheckboxSlider";
import kstatConfiguration from "./systems/_components/kstatConfiguration";

export default function SettingsPage({ organisation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [config, setConfig] = useState({
    is_sar_related: false,
    is_lowland_rescue: false,
    hide_account: false,
    is_account_disabled: false,
  });

  useEffect(() => {
    setConfig({
      ...config,
      is_sar_related: organisation.data.system_settings.is_sar_related,
      is_lowland_rescue: organisation.data.system_settings.is_lowland_rescue,
      hide_account: organisation.data.system_settings.hide_account,
      is_account_disabled:
        organisation.data.system_settings.is_account_disabled,
    });
  }, [organisation]);

  const handleSubmit = () => {
    setIsSubmitting(true);

    API.org
      .updateSettings(organisation.data._id, config)
      .then(() => {
        setNeedsUpdate(false);
        toast.success("Settings updated");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        // setSubmitError(err.response.data.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <div className="grid grid-cols-3 gap-2 p-2">
      <Box title="Org Settings">
        <div className="p-2">
          <CheckboxSlider
            disabled={isSubmitting}
            title={"Is a SAR related organisation"}
            onChange={(a) => {
              setConfig({ ...config, is_sar_related: a });
              setNeedsUpdate(true);
            }}
            isChecked={config.is_sar_related}
          />
          <CheckboxSlider
            disabled={isSubmitting}
            title={"Is a Lowland Rescue organisation"}
            onChange={(a) => {
              setConfig({ ...config, is_lowland_rescue: a });
              setNeedsUpdate(true);
            }}
            isChecked={config.is_lowland_rescue}
          />
          <CheckboxSlider
            disabled={isSubmitting}
            title={"Hide the organisation"}
            onChange={(a) => {
              setConfig({ ...config, hide_account: a });
              setNeedsUpdate(true);
            }}
            isChecked={config.hide_account}
          />
          <CheckboxSlider
            disabled={isSubmitting}
            title={"Disable the organisation"}
            onChange={(a) => {
              setConfig({ ...config, is_account_disabled: a });
              setNeedsUpdate(true);
            }}
            isChecked={config.is_account_disabled}
          />

          {needsUpdate && (
            <div className="p-2 border-t border-gray-700 text-center">
              <button
                className="p-2 bg-green-500 rounded text-white  "
                onClick={() => handleSubmit()}
              >
                Save changes
              </button>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
}
