import React from "react";
import ApplicationForm from "./_components/applicationForm";
import API from "../../../../shared/API";
import toast from "react-hot-toast";

const ApplicationCreate = ({ history }) => {

  const handleCreateApplication = data => {
    return API.applications.add(data).then(res => {
      toast.success("Successfully created the new Application", {
        duration: 5000,
      });
      setTimeout(() => {
        history.push("/applications/manage/" + res._id)
      }, 1000);
    });
  }

  return (
    <div>
      <div className={"p-2"}>
        <div className="text-white tracking-wider bg-gray-900 text-xl px-4 py-2 border border-gray-700 rounded-t-xl flex justify-between items-center">
          Create Application
        </div>
        <div className="px-4 pb-2 pt-2 w-full border border-gray-700 rounded-b-xl flex flex-col">
          <ApplicationForm onSubmit={handleCreateApplication} onCancel={() => history.push("/applications")} />
        </div>
      </div>

    </div>
  );
}
export default ApplicationCreate;