import React, { useState, useEffect } from "react";
import { Organisation_Receiver } from "../../../context/organisation";
import API from "../../../shared/API";
import {
  CheckIcon,
  XIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import Loader from "../../../shared/components/Loader";
import UserEditPopup from "./_components/userEditPopup";

function TeamPage({ organisation, history }) {
  const org = organisation.data;
  const [filterType, setFilterType] = useState("active");
  const [sortType, setSortType] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);

  const getData = () => {
    Promise.all([API.org.users.all(org._id)])
      .then((res) => {
        setUsers(res[0]);
        setFiltered(
          res[0].filter((item) => {
            return item.isDisabled === false;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const goTo = (id) => {
    history.push(`${organisation._urlPrefix}/team/${id}`);
  };

  const filterList = (e) => {
    if (sortDirection || sortType) {
      setSortDirection("");
      setSortType("");
    }

    let updatedList = users;
    updatedList = updatedList.filter((item) => {
      return (
        item.fullName.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    setFiltered(updatedList);
  };

  const filterActive = () => {
    if (sortDirection || sortType) {
      setSortDirection("");
      setSortType("");
    }

    setFilterType("active");
    let updatedList = users;
    updatedList = updatedList.filter((item) => {
      return item.isDisabled === false;
    });
    setFiltered(updatedList);
  };

  const filterDisabled = () => {
    if (sortDirection || sortType) {
      setSortDirection("");
      setSortType("");
    }

    setFilterType("disabled");
    let updatedList = users;
    updatedList = updatedList.filter((item) => {
      return item.isDisabled === true;
    });
    setFiltered(updatedList);
  };

  const filterLockout = () => {
    if (sortDirection || sortType) {
      setSortDirection("");
      setSortType("");
    }

    setFilterType("lockout");
    let updatedList = users;
    updatedList = updatedList.filter((item) => {
      return item.isLockedOut === true;
    });
    setFiltered(updatedList);
  };

  const handleSort = (field, type) => {
    let data = [...filtered];
    const currentField = sortType;
    const sortDir =
      currentField === field
        ? sortDirection !== "DSC"
          ? "DSC"
          : "ASC"
        : "DSC";

    setSortType(field);
    setSortDirection(sortDir);

    data = data.sort((a, b) =>
      sortDir === "ASC"
        ? type === "str"
          ? a[field].toLowerCase() < b[field].toLowerCase()
            ? 1
            : a[field].toLowerCase() > b[field].toLowerCase()
            ? -1
            : 0
          : type === "bool"
          ? a[field] > b[field]
            ? 1
            : a[field] < b[field]
            ? -1
            : 0
          : a[field] < b[field]
          ? 1
          : a[field] > b[field]
          ? -1
          : 0
        : type === "str"
        ? a[field].toLowerCase() > b[field].toLowerCase()
          ? 1
          : a[field].toLowerCase() < b[field].toLowerCase()
          ? -1
          : 0
        : type === "bool"
        ? a[field] < b[field]
          ? 1
          : a[field] > b[field]
          ? -1
          : 0
        : a[field] > b[field]
        ? 1
        : a[field] < b[field]
        ? -1
        : 0
    );

    setFiltered(data);
  };

  return (
    <div>
      {addModal && (
        <UserEditPopup
          organisation={organisation}
          onClose={() => {
            setAddModal(false);
            setSortDirection("");
            setSortType("");
            getData();
          }}
        />
      )}
      <div className="p-0">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="m-2 p-4 flex gap-4 justify-between border-0 border-gray-700  my-2">
              <div className="w-full flex flex-col sm:flex-row gap-4">
                <button
                  onClick={filterActive}
                  className={`${
                    filterType === "active" ? "bg-green-600" : "bg-blue-600"
                  } p-2 rounded-md `}
                >
                  Active ({users.filter((item) => !item.isDisabled).length})
                </button>

                <button
                  onClick={filterLockout}
                  className={`${
                    filterType === "lockout" ? "bg-green-600" : "bg-blue-600"
                  } p-2 rounded-md `}
                >
                  Locked Out ({users.filter((item) => item.isLockedOut).length})
                </button>
                <button
                  onClick={filterDisabled}
                  className={`${
                    filterType === "disabled" ? "bg-green-600" : "bg-blue-600"
                  } p-2 rounded-md `}
                >
                  Archived ({users.filter((item) => item.isDisabled).length})
                </button>
              </div>
              <div className="w-full flex flex-col sm:flex-row sm:justify-end gap-4">
                {/*<button*/}
                {/*    onClick={() => history.push(`${organisation._urlPrefix}/team/import`)}*/}
                {/*    className={`bg-orange-700 p-2 rounded-md `}*/}
                {/*>*/}
                {/*    Bulk Import Users*/}
                {/*</button>*/}
                <button
                  onClick={() => setAddModal(true)}
                  className={`bg-green-600 p-2 rounded-md `}
                >
                  Add User
                </button>
                <input
                  autoComplete="off"
                  className="bg-gray-800 rounded-md p-2 max-w-full"
                  name="filter"
                  placeholder="search all users..."
                  onChange={filterList}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700 ">
                <thead className="hover:cursor-pointer">
                  <tr className="rounded-md bg-gray-900">
                    <th
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      onClick={() => handleSort("fullName", "str")}
                    >
                      Name
                      {sortType === "fullName" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-28"
                      onClick={() => handleSort("teamId", "str")}
                    >
                      Callsign
                      {sortType === "teamId" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      className="px-2 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      onClick={() => handleSort("email", "str")}
                    >
                      Email & phone
                      {sortType === "email" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase w-30"
                      onClick={() => handleSort("operational", "bool")}
                    >
                      Operational
                      {sortType === "operational" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase w-28"
                      onClick={() => handleSort("onCall", "bool")}
                    >
                      On-Call
                      {sortType === "onCall" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase w-28"
                      onClick={() => handleSort("isLockedOut", "bool")}
                    >
                      Lockout
                      {sortType === "isLockedOut" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      className="px-2 py-3  text-center text-xs font-medium text-gray-500 uppercase w-28"
                      onClick={() => handleSort("isDisabled", "bool")}
                    >
                      Disabled
                      {sortType === "isDisabled" ? (
                        sortDirection === "DSC" ? (
                          <ChevronDownIcon className="inline ml-2 h-4 w-4" />
                        ) : (
                          <ChevronUpIcon className="inline ml-2 h-4 w-4" />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-black divide-y divide-gray-700">
                  {filtered.map((o) => {
                    return (
                      <tr
                        key={o._id}
                        onClick={() => goTo(o._id)}
                        className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
                      >
                        <td className="px-2 py-2 text-md text-gray-200">
                          {o.fullName}
                        </td>
                        <td className="px-2 py-2 text-md text-center text-gray-200">
                          {o.teamId}
                        </td>
                        <td className="px-2 py-2 text-md text-gray-200 items-center ">
                          <div className="flex flex-wrap">
                            {o.email}
                            <br />
                            {o.phone}
                          </div>
                        </td>
                        <td className="px-2 py-2 text-sm text-gray-200">
                          <div className="">
                            {o.operational ? (
                              <CheckIcon className="h-8 text-green-700 mx-auto" />
                            ) : (
                              <XIcon className="h-8 text-red-700 mx-auto" />
                            )}
                          </div>
                        </td>
                        <td className="px-2 py-2 text-sm text-gray-200">
                          <div className="">
                            {o.onCall ? (
                              <ThumbUpIcon className="h-8 text-green-700 mx-auto" />
                            ) : (
                              <ThumbDownIcon className="h-8 text-red-700 mx-auto" />
                            )}
                          </div>
                        </td>
                        <td className="px-2 py-2 text-sm text-gray-200">
                          <div className="">
                            {o.isLockedOut ? (
                              <CheckIcon className="h-8 text-green-700 mx-auto" />
                            ) : (
                              <XIcon className="h-8 text-red-700 mx-auto" />
                            )}
                          </div>
                        </td>
                        <td className="px-2 py-2 text-sm text-gray-200">
                          <div className="">
                            {o.isDisabled ? (
                              <CheckIcon className="h-8 text-green-700 mx-auto" />
                            ) : (
                              <XIcon className="h-8 text-red-700 mx-auto" />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default Organisation_Receiver(TeamPage, (organisation) => {
  return { organisation };
});
