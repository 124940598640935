import React, { useEffect, useState } from "react";
import { Organisation_Receiver } from "../../../../../context/organisation";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";
import Select from "../../../../../shared/components/Select";
import Loader from "../../../../../shared/components/Loader";
import API from "../../../../../shared/API";
import FieldSSOApplications from "./_fieldSSOApplication";

const sarSysFlavors = [
  {
    title: "SarSYS - CAD",
    flavour: "cad",
    applicationFilter: "SARSYS-CAD",
    modules: [],
  },
  {
    title: "SarSYS",
    flavour: "lite",
    applicationFilter: "SARSYS-LITE",
    modules: [
      "planner",
      "teamTasking",
      "airOps",
      "sonar",
      "videoFeeds",
      "needAssessment",
      "ciem",
      "lrf_review"
    ],
  },
];

const Configuration = ({ title, value, onChange, disabled }) => {
  useEffect(() => {
    if (value.flavour !== "lite") {
      onChange({
        ...value,
        modules: {},
      });
    }
  }, [value.flavour]);

  const onChangeHandler = (e) => {
    onChange({ ...value, [e.target.name]: e.target.value });
  };
  const renderModules = () => {
    if (value.flavour === "lite") {
      const availableModules = sarSysFlavors[1].modules;
      return (
        <div className={"grid grid-cols-4 w-full"}>
          {availableModules.map((module, index) => {
            let isChecked = false;
            if (value.modules[module] !== undefined) {
              isChecked = value.modules[module];
            }
            return (
              <div key={index} className={"text-center"}>
                <CheckboxSlider
                  disabled={disabled}
                  title={module}
                  onChange={(a) => {
                    let newModules = {};
                    availableModules.forEach(
                      (b) =>
                        (newModules[b] =
                          value.modules[b] !== undefined
                            ? value.modules[b]
                            : false)
                    );
                    onChange({
                      ...value,
                      modules: {
                        ...newModules,
                        [module]: a,
                      },
                    });
                  }}
                  isChecked={isChecked}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return <p>[No modules available]</p>;
  };

  const ssoFilter = sarSysFlavors.filter((a) => a.flavour === value.flavour);

  return (
    <div className={"my-2 p-2 border"}>
      <h2 className={"text-center"}>{title}</h2>
      <Select
        label={"Flavour"}
        field={"flavour"}
        value={value.flavour}
        onChange={onChangeHandler}
        disabled={disabled}
        options={sarSysFlavors.map((a) => {
          return {
            text: a.title,
            value: a.flavour,
            key: a.flavour,
          };
        })}
      />
      <FieldSSOApplications
        systemType={"sarsys"}
        flavour={
          ssoFilter[0] !== undefined ? ssoFilter[0].applicationFilter : ""
        }
        disabled={disabled}
        application={value.application}
        endpoint={value.endpoint}
        onChange={(data) =>
          onChange({
            ...value,
            application: data.application,
            endpoint: data.endpoint,
          })
        }
      />

      <label>Modules</label>
      {renderModules()}
    </div>
  );
};

const SarSysConfiguration = ({ organisation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [orgConfig, setOrgConfig] = useState("");
  const [config, setConfig] = useState({
    isEnabled: false,
    primary: {
      flavour: "", // base, cad, lite
      application: "",
      endpoint: "",
      modules: {},
    },
    backup: {
      flavour: "", // base, cad, lite
      application: "",
      endpoint: "",
      modules: {},
    },
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSubmitError("");
    API.org.settings.systems
      .setConfig(organisation.data._id, "sarsys", config)
      .then(() => {
        getData();
      })
      .catch((err) => {
        setSubmitError(err.response.data.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const getData = () => {
    API.org.settings.systems
      .getConfig(organisation.data._id, "sarsys")
      .then((res) => {
        setConfig((a) => {
          const b = {
            ...a,
            ...res,
            primary: {
              ...a.primary,
              ...res.primary,
            },
            backup: {
              ...a.backup,
              ...res.backup,
            },
          };
          setOrgConfig(JSON.stringify(b));
          return b;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (JSON.stringify(config) !== orgConfig) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [config]);

  if (isLoading) {
    return <Loader>Getting SarSYS Config...</Loader>;
  }

  return (
    <div
      className={`border ${
        isModified ? "border-yellow-800" : "border-green-800"
      } rounded-xl p-2`}
    >
      <h1 className={"text-xl"}>SarSYS</h1>
      {isSubmitting && (
        <div className={"p-2 bg-blue-600"}>Saving changes...</div>
      )}
      {submitError && <div className={"p-2 bg-red-600"}>{submitError}</div>}
      <CheckboxSlider
        disabled={isSubmitting}
        title={"Enabled"}
        onChange={(a) => setConfig({ ...config, isEnabled: a })}
        isChecked={config.isEnabled}
      />

      {config.isEnabled && (
        <Configuration
          disabled={isSubmitting}
          organisation={organisation}
          title={"Primary"}
          value={config.primary}
          onChange={(a) => setConfig({ ...config, primary: a })}
        />
      )}
      {config.isEnabled && (
        <Configuration
          disabled={isSubmitting}
          organisation={organisation}
          title={"Backup"}
          value={config.backup}
          onChange={(a) => setConfig({ ...config, backup: a })}
        />
      )}

      {isModified && (
        <button
          disabled={isSubmitting}
          className="bg-green-600 text-white rounded-xl p-2 mt-4 float-right"
          onClick={() => handleSubmit()}
        >
          Save Changes
        </button>
      )}
      <div className={"clear-both"}>{""}</div>
    </div>
  );
};
export default Organisation_Receiver(SarSysConfiguration, (organisation) => {
  return { organisation };
});