import React, {useEffect, useState, useMemo} from "react";
import Select from "../../../../../shared/components/Select";
import API from "../../../../../shared/API";
import toast from "react-hot-toast";

const initialFormValues = {
  name: "",
  system: "",
};

const ApplicationForm = ({ application = {}, onSubmit, onCancel }) => {
  const [data, setData] = useState({ ...initialFormValues });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [systems, setSystems] = useState([]);
  const errors = useMemo(() => {
    const errors = {};

    if (!data.name) errors.name = "Can't be empty";
    if (!data.system) errors.system = "System must be selected";

    return errors;
  }, [data]);

  useEffect(() => {
    if(application._id !== undefined){
      setData({
        ...initialFormValues,
        ...application,
        system: application.system?._id !== undefined ? application.system._id : application.system,
      });
    }
  }, [application]);
  useEffect(() => {
    API.systems.all().then(res => {
      setSystems(res.map(system => {
        return { value: system._id, text: system.name };
      }));
    })
  }, []);

  const handleSubmit = () => {
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      onSubmit(data).catch(error => {
        setIsSubmitting(false);

        let errorMessage = "There was an unexpected error: " + error.response.data.message;
        toast.error(errorMessage, {
          duration: 5000,
        });
      })
    }
  }

  return (
    <>

      <div className={"flex gap-4"}>
        <div className="flex flex-col gap-2 w-full">
          <label htmlFor="name">Name</label>
          <input
            name="name"
            id="name"
            placeholder="Application Name"
            value={data.name}
            onChange={(e) => setData({...data, name: e.target.value})}
            className={`p-2 bg-gray-900 rounded-xl text-white w-full border-gray-700 border`}
            autoComplete="off"
            disabled={isSubmitting}
          />
          {errors.name && <p style={{color: "red"}}>{errors.name}</p>}
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label htmlFor="system">System</label>
          <Select
            placeholder={"Pick One"}
            name={"system"}
            options={systems}
            value={data.system}
            onChange={(e) => setData({...data, system: e.target.value})}
            disabled={isSubmitting}
          />
          {errors.system && <p style={{color: "red"}}>{errors.system}</p>}
        </div>
      </div>
      <div className="flex w-full border-t border-gray-700 mt-8 p-4 gap-4">
        <button onClick={onCancel} className={`bg-blue-900 hover:bg-opacity-80 border-blue-600 text-white rounded-xl border p-2 w-1/4`}>Cancel</button>
        <button
          onClick={handleSubmit}
          disabled={Object.keys(errors).length > 0}
          className={`${Object.keys(errors).length > 0
            ? "cursor-not-allowed bg-green-900 bg-opacity-40 text-gray-700 border-green-800 "
            : "bg-green-700 hover:bg-opacity-80 border-green-300 text-white "
          } rounded-xl border p-2 w-full`}
        >{application._id !== undefined ? "Update" : "Add"}</button>
      </div>

    </>
  );
}
export default ApplicationForm;